.dimension {
  margin: 10px 0;

  .topRow {
    display: flex;

    & > *:not(:first-child) {
      margin-left: 10px;
    }
  }
}

.verticalAlignmentWrapper {
  height: 34px;
  display: flex;
  align-items: center;
}
