@import "scssShared/variables.scss";
@import "scssShared/animations.scss";

.container {
  display: flex;
  margin-top: 10px;

  &:not(.readOnly) {
    margin-right: 34px;
  }
}

.inputContainer {
  position: relative;
  width: 100%;

  &:not(:first-child) {
    margin-left: 10px;
  }

  :global(.condition-input) {
    box-sizing: border-box;
    height: 34px;
  }

  input {
    width: 100%;
    padding: 7px 10px;

    &:disabled {
      background-color: $color_disabled_field_bg;
      color: $color_disabled_field;
    }
  }

  .dateIcon {
    position: absolute;
    z-index: 1;
    right: 7px;
    top: 7px;
    pointer-events: none;
  }

  .error:global(.condition-input),
  .error.whisperer input,
  .error.datePicker input {
    border-color: $color_warning_red;
    animation: animation-shake 1s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  }
}

.multiInputWrapper {
  display: flex;
  gap: 10px;
}

.multiInput {
  flex: 1;

  & :global(.react-select-redux-field__menu) {
    z-index: 2;
  }

  &.readOnly {
    & :global(.select-input) > div {
      background-color: $color_disabled_field_bg;
    }

    & :global(.react-select-redux-field__multi-value__label) {
      color: $color_disabled_field;
    }
  }
}
