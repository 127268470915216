// colors
$color_primary_lighter: #fea172;
$color_primary: #fe7f66;
$color_primary_darker: #eb6c52;
$color_black: #222222;
$color_black_lighter: #555555;
$color_grey_lighter: #777777;
$color_grey: #999999;
$color_grey_darker: #494a4a;
$color_warning_red: #ed382a;
$color_warning_orage: #fbab62;
$color_bg_customer_avatar: #feefec;
$color_white: #ffffff;
$color_green: #3aa545;
$color_red: #cc4545;
$color_blue_running: #229ace;
$color_yellow_waiting: #fabe53;
$color_blue: #01579b;
$color_light-blue: #4285f4;
$color_yellow: #fbb962;
$color_purple: #7c2b73;
$color_lighting-yellow: #f8ac33;
$color_link_water: #cbccd0;
$color_grey_95: #959595;
$color_grey_7e: #7e7e7e;
$color_grey_fa: #fafafa;
$color_grey_ae: #aeaeae;

$color_wasabi: #7f9d43;
$color_olivine: #8cba5e;
$color_sprout: #99cc66;
$color_fun-blue: #2d4b86;
$color_persian-blue: #2258c3;
$color_jordy-blue: #6c92e0;
$color_cerulean-blue: #2929a3;
$color_rich-blue: #5959a6;
$color_ship-cove: #8484ad;
$color_cabaret: #bf4040;
$color_charm: #cc6677;
$color_can-can: #d27979;
$color_california: #e88c30;
$color_bronze: #cc8033;
$color_whiskey: #cc9966;
$color_apple: #5c8547;
$color_fuego: #6dc144;
$color_granny-smith-apple: #85d65c;
$color_atoll: #2d7e86;
$color_light-sea-green: #22b6c3;
$color_turquoise-blue: #6cd6e0;
$color_royal-blue: #556cdb;
$color_portage: #8191e4;
$color_nepal: #abb6ed;
$color_fire-engine-red: #e05252;
$color_old-rose: #f07575;
$color_contessa: #f5a3a3;
$color_broom: #ccac00;
$color_macaroni-and-cheese: #e6c100;
$color_golden-glow: #f5d73d;
$color_sea-green: #17823b;
$color_mountain-meadow: #1ab34d;
$color_silver-tree: #5cd685;
$color_gossamer: #45a16b;
$color_medium-aquamarine: #57c785;
$color_magic-mint: #67e49b;
$color_medium-purple: #8855db;
$color_dull-lavender: #a281e4;
$color_lavender: #e0d5f6;
$color_perano: #c1abed;
$color_free-speech-magenta: #e052b1;
$color_persian-pink: #f075c7;
$color_cupid: #f5a3da;
$color_gumbo: #aaaaaa;
$color_very-light-grey: #cccccc;
$color_catskill-white: #f7f9fb;
$color_purple-heart: #8d26de;
$color_heliotrope: #af5def;
$color_perfume: #d9b2f8;

$colors: (
  "primary": $color_primary,
  "primary-lighter": $color_primary_lighter,
  "blue": $color_blue,
  "green": $color_green,
  "yellow": $color_yellow,
  "red": $color_red,
  "old-rose": $color_old-rose,
  "charm": $color_charm,
  "can-can": $color_can-can,
  "light-blue": $color_light-blue,
  "purple": $color_purple,
  "lighting-yellow": $color_lighting-yellow,
  "wasabi": $color_wasabi,
  "olivine": $color_olivine,
  "sprout": $color_sprout,
  "medium-aquamarine": $color_medium-aquamarine,
  "persian-blue": $color_persian-blue,
  "cerulean-blue": $color_cerulean-blue,
  "rich-blue": $color_rich-blue,
  "ship-cove": $color_ship-cove,
  "medium-purple": $color_medium-purple,
  "contessa": $color_contessa,
  "fire-engine-red": $color_fire-engine-red,
  "cabaret": $color_cabaret,
  "macaroni-and-cheese": $color_macaroni-and-cheese,
  "california": $color_california,
  "bronze": $color_bronze,
  "whiskey": $color_whiskey,
  "broom": $color_broom,
  "fuego": $color_fuego,
  "gossamer": $color_gossamer,
  "silver-tree": $color_silver-tree,
  "atoll": $color_atoll,
  "light-sea-green": $color_light-sea-green,
  "turquoise-blue": $color_turquoise-blue,
  "nepal": $color_nepal,
  "gumbo": $color_gumbo,
  "fun-blue": $color_fun-blue,
  "jordy-blue": $color_jordy-blue,
  "apple": $color_apple,
  "granny-smith-apple": $color_granny-smith-apple,
  "royal-blue": $color_royal-blue,
  "portage": $color_portage,
  "golden-glow": $color_golden-glow,
  "sea-green": $color_sea-green,
  "mountain-meadow": $color_mountain-meadow,
  "magic-mint": $color_magic-mint,
  "dull-lavender": $color_dull-lavender,
  "perano": $color_perano,
  "free-speech-magenta": $color_free-speech-magenta,
  "persian-pink": $color_persian-pink,
  "cupid": $color_cupid,
  "grey-lighter": $color_grey_lighter,
  "very-light-grey": $color_very-light-grey,
  "lavender": $color_lavender,
  "purple-heart": $color_purple-heart,
  "heliotrope": $color_heliotrope,
  "perfume": $color_perfume,
  "black-lighter": $color_black_lighter,
);

$color_link_header: #fec5ba;
$color_border: #c4c4c4;
$color_disabled_field_bg: #eeeeee;
$color_disabled_field: #555555;

// grey backgrounds
$color_grey_secondary_menu: #f7f8f9;
$color_grey_site_background: #eaeef1;

// fonts
$font_family_neue: "Neue Hans Kendrick", sans-serif;
$font_family_inter: "Inter UI", sans-serif;
$font_base_size: 16;

// viewport sizes breakpoints
$viewport_medium: 1280px;

// border radius
$br_small: 4px;
$br_medium: 6px;
$br_large: 8px;

// border types
$border_grey_white_bg: 1px solid #e6e6e6;
$border_grey_light_thin: 1px solid $color_border;
$border_grey_light_thick: 2px solid $color_border;
$border_grey: 1px solid $color_grey;
$border_primary_thin: 1px solid $color_primary;
$border_primary_thick: 2px solid $color_primary;
$border_warning_red: 1px solid $color_warning_red;

// tables
$table_row_hover: rgba(254, 127, 102, 0.05);
$table_row_link_hover: rgba(254, 127, 102, 0.1);

// dropped box shadows
$bs_smallest: 0px 3px 6px rgba(0, 0, 0, 0.05);
$bs_small: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
$bs_large: 0px 3px 10px 0px rgba(0, 0, 0, 0.1);
$button_primary_focus_box_shadow: 0 0 4px 1.5px rgba($color_primary, 0.4);
$box_shadow_toggle_active: 0px 2px 4px rgba(0, 0, 0, 0.15);
$button_white_focus_box_shadow: 0 0 4px 1.5px rgba($color_grey, 0.4);

// inset box shadows
$toggle_inset_box_shadow: inset 0px 2px 6px rgba(0, 0, 0, 0.15);

// transition
$transition_short: all 0.1s ease;
$transition_default: all 0.15s ease-out;
$transition_long: all 0.5s ease;
