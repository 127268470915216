@import "scssShared/variables.scss";

.detail-attribute {
  .detail-attribute-header {
    justify-content: space-between;

    .header-buttons {
      .button {
        margin-left: 10px;
      }
    }
  }

  .detail-attribute-content {
    padding: 0;
  }

  .loading-indicator {
    margin-top: 20px;
  }
}
