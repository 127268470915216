@import "scssShared/variables.scss";

.smart-segment-attributes {
  background: $color_white;
  border: 1px solid $color_border;
  border-radius: $br_medium;
  box-sizing: border-box;
  display: flex;
  box-shadow: $bs_smallest;

  .no-margin-bottom {
    margin-bottom: 0;
  }

  .smart-segment-form {
    width: 50%;
    flex-shrink: 0;
    box-sizing: border-box;
    padding: 15px;

    label {
      font-weight: bold;
      font-size: 11px;
      line-height: 13px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: $color_grey;
      margin-bottom: 10px;
      display: block;
    }

    .segment-group-field {
      width: 270px;
      flex: 0 0 270px;
    }

    .position-field {
      // width: 100px;
      margin-left: 5px;

      .position-input {
        box-sizing: border-box;
        width: 100%;
      }
    }

    .flex {
      display: flex;
    }
  }

  .name-form {
    width: 100%;

    .name {
      background-color: $color_white;
      font-weight: normal;
    }

    input {
      font-weight: normal;
    }
  }

  .pictures {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: -10px;

    .icon-wrapper {
      height: 90px;
      border: 1px solid $color_border;
      border-radius: $br_small;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      opacity: 0.5;
      transition: $transition_default;
      position: relative;
      margin-bottom: 10px;

      img {
        height: 70px;
        width: auto;
      }

      &:hover,
      &.selected {
        opacity: 1;
      }

      .checkmark {
        width: 24px;
        height: 24px;
        background: $color_primary;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: -4px;
        right: -4px;
        color: $color_white;
        font-size: 14px;
      }
    }
  }

  .smart-segment-preview {
    width: 50%;
    flex-shrink: 0;
    background: $color_grey_secondary_menu;
    border-top-right-radius: $br_medium;
    border-bottom-right-radius: $br_medium;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-left: 1px solid $color_border;
    box-sizing: border-box;
    align-items: center;

    .preview-tile {
      width: 393px;
      margin-top: 5px;
      margin-bottom: 25px;
    }

    .label {
      font-size: 11px;
      line-height: 13px;
      text-align: center;
      text-transform: uppercase;
      color: $color_grey;
      letter-spacing: 0.1em;
      font-weight: bold;
      margin-top: 18px;
    }

    .segment-group {
      text-align: center;
      font-family: $font_family_neue;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.2em;
      text-transform: uppercase;
      color: $color_border;
      margin-top: 10px;

      @each $name, $color in $colors {
        &.#{$name} {
          color: $color;
        }
      }
    }
  }
}
