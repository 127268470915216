@import "scssShared/variables.scss";

.container {
  width: 1200px;
  margin: 0 auto;
  border-radius: $br_large;
  border: $border_grey_light_thin;
  display: flex;
  box-shadow: $bs_smallest;

  .mainImg {
    flex: 0 0 740px;
    height: 520px;
    border-top-left-radius: $br_large;
    border-bottom-left-radius: $br_large;
  }

  .contentPanel {
    background-color: $color_white;
    border-top-right-radius: $br_large;
    border-bottom-right-radius: $br_large;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-left: $border_grey_light_thin;
    color: $color_black_lighter;

    h1 {
      color: $color_primary;
      font-size: 28px;
    }

    strong {
      color: $color_primary;
    }

    a {
      text-transform: inherit;
      font-size: inherit;
      color: $color_primary;
    }
  }
}
