@import "scssShared/variables.scss";
@import "scssShared/mixins.scss";
@import "scssShared/animations.scss";

.textField {
  box-sizing: border-box;
  position: relative;

  input,
  textarea {
    box-sizing: border-box;
    @include input-styles;
  }

  textarea {
    resize: none;
  }

  &.hasError {
    input,
    textarea {
      border: $border_warning_red;
      box-shadow: 0 1px 3px 0px rgba(237, 56, 42, 0.25);
      animation: animation-shake 1s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
      transform: translate3d(0, 0, 0);
      backface-visibility: hidden;
      perspective: 1000px;
    }
  }

  &.fullWidth {
    input,
    textarea {
      width: 100%;
    }
  }
}

.label {
  @include input-label-styles;
}

.errorMessage {
  @include input-error-message-styles;
}
