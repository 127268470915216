@import "variables.scss";
/*
 * ---------------------
 * Shake animation start
 * ---------------------
 */
@keyframes animation-shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-3px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(3px, 0, 0);
  }
}
/*
 * -------------------
 * Shake animation end
 * -------------------
 */
@keyframes glow {
  from {
    box-shadow: 0 0 0 0px rgba($color_warning_red, 0.5), 0 0 0 0px rgba($color_warning_red, 0.25);
  }
  to {
    box-shadow: 0 0 0 5px rgba($color_warning_red, 0.5), 0 0 0 10px transparent;
  }
}
/*
 * --------------------
 * Glow animation start
 * --------------------
 */

/*
 * ------------------
 * Glow animation end
 * ------------------
 */

/*
 * ----------------------
 * Rotate animation start
 * ----------------------
 */
@keyframes animation-rotate {
  100% {
    transform: rotate(360deg);
  }
}
/*
 * --------------------
 * Rotate animation end
 * --------------------
 */

// --- BELOW will be CSSTransitions specified for React transition-group library --- //

/*
 * FADE effect
 */

.fade-enter {
  opacity: 0.01;
}

.fade-enter-active {
  transition: opacity 0.2s ease-in;
  opacity: 1;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  transition: opacity 0.2s ease-out;
  opacity: 0.01;
}

.fade-exit-done {
  display: none;
}

/*
 * FADE fast effect
 */

.fastfade-enter {
  opacity: 0.01;
}

.fastfade-enter-active {
  transition: opacity 0.1s ease-in;
  opacity: 1;
}

.fastfade-exit {
  opacity: 1;
}

.fastfade-exit-active {
  transition: opacity 0.1s ease-out;
  opacity: 0.01;
}

.fastfade-exit-done {
  display: none;
}

/*
 * NEW VERSION BAR MARGIN-TOP effect
 */

.versionbaranimation-enter {
  margin-top: -50px;
}

.versionbaranimation-enter-active {
  transition: all 0.2s ease-in;
  margin-top: 0px;
}

.versionbaranimation-exit {
  margin-top: 0px;
}

.versionbaranimation-exit-active {
  transition: all 0.2s ease-in;
  margin-top: -50px;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes pulse {
  70% {
    box-shadow: 0 0 15px 5px rgba($color_primary, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba($color_primary, 0);
  }
}
@keyframes heartBeat {
  0% {
    transform: scale(1);
  }

  10% {
    transform: scale(1.15);
  }
  20% {
    transform: scale(1);
  }
  30% {
    transform: scale(1.15);
  }
  55% {
    transform: scale(1);
  }
}
