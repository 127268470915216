@import "variables.scss";
@import "mixins.scss";

.legend-area {
  .chart-legend-list {
    list-style-type: none;
    margin: 5px 0;
    padding: 0;
    text-align: center;

    li {
      position: relative;
      display: inline-block;
      line-height: 18px;
      font-size: 11px;
      font-family: $font_family_inter;
      text-transform: uppercase;
      cursor: pointer;
      font-weight: bold;
      letter-spacing: 0.5px;
      white-space: nowrap;
      padding-left: 20px;
      margin: 0 5px;

      &:before {
        content: "";
        width: 12px;
        height: 12px;
        border-radius: 50%;
        display: inline-block;
        position: absolute;
        left: 0;
        top: 0;
      }

      @each $name, $color in $colors {
        &.#{$name} {
          color: $color;

          &:before {
            border: 2px solid $color;
            @include background-opacity($color, 0.5);
          }
        }
      }

      &.hidden {
        opacity: 0.25;
      }
    }
  }

  .legend-toggle {
    text-decoration: underline;
    cursor: pointer;
    color: $color_grey;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 11px;
    display: table;
    letter-spacing: 0.5px;
    margin: 8px auto 0;

    &:hover {
      text-decoration: none;
    }

    .caret-icon {
      margin-left: 4px;
    }
  }
}
