@import "scssShared/variables.scss";

.event-form {
  .form-row {
    display: flex;
    padding: 30px 20px;
    margin: 0;

    &.grey {
      background: $color_catskill-white;
      border-top: 1px solid $color_border;
      border-bottom: 1px solid $color_border;
    }

    &.last {
      margin-bottom: 0;
      border-bottom: 0;

      &.grey {
        border-bottom-left-radius: $br_small;
        border-bottom-right-radius: $br_small;
      }
    }
  }

  .left-part {
    width: 220px;
    flex-shrink: 0;

    h2 {
      font-size: 21px;
      letter-spacing: -0.025em;
      margin: 0;
      line-height: 21px;
      margin-top: 29px;

      &.event-retention-title {
        margin-top: 12px;
      }
    }

    .docs {
      font-size: 12px;
      line-height: 15px;
      color: $color_grey_lighter;
      font-weight: normal;
      margin: 24px 0 0;

      a {
        text-transform: none;
        font-size: 12px;
        color: $color_primary;
      }
    }
  }

  label {
    display: block;
  }

  .right-part {
    display: flex;
    flex-grow: 1;
  }

  .event-name {
    input {
      width: 280px;
      box-sizing: border-box;
    }
  }

  .source-id {
    width: 240px;
    margin-left: 10px;
  }

  .event-type {
    width: 240px;
    margin-left: 10px;
  }

  .event-version {
    margin-left: 10px;

    input {
      width: 122px;
      box-sizing: border-box;
    }
  }

  .event-schema-title {
    margin-right: 10px;
    input {
      width: 280px;
      box-sizing: border-box;
    }
  }

  .schema {
    display: flex;
  }

  .event-form-schema-item-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 5px;

    &.last {
      margin-bottom: 10px;
    }
  }

  .add-schema-button {
    transition: $transition_default, margin-top 0s;
    margin-left: 10px;
    &.mt {
      margin-top: 18px;
    }
  }

  .event-form-schema-box {
    background: $color_white;
    border: 1px solid $color_border;
    border-radius: $br_small;
    padding: 10px;
    margin-right: 5px;

    .row {
      display: flex;
    }

    .event-schema-name {
      input {
        width: 235px;
        box-sizing: border-box;
      }
    }

    .event-schema-type {
      margin-left: 10px;
      width: 200px;
    }

    .event-schema-format {
      margin-left: 10px;
      width: 140px;
    }

    .event-schema-path {
      margin-top: 15px;
      input {
        width: 595px;
        box-sizing: border-box;
      }
    }
  }

  .event-retention-field-wrapper {
    display: flex;
    align-items: center;

    > span {
      display: block;
      color: $color_black;
      font-size: 16px;
      letter-spacing: -0.025em;
    }

    .event-retention-field {
      margin: 0 10px;

      input {
        width: 84px;
        box-sizing: border-box;
      }

      .error-message {
        position: absolute;
        margin-left: 0;
      }
    }
  }
}
