@import "scssShared/variables.scss";

.underlined-text-field {
  position: relative;

  label {
    margin-bottom: 8px;
    color: $color_grey;
    letter-spacing: 1px;
  }

  input {
    border: none;
    border-bottom: 2px solid $color_grey;
    background: transparent;
    border-radius: 0;
    font-weight: bold;
    font-family: $font_family_neue;
    padding: 0 10px 4px 4px;

    &:hover {
      border: none;
      border-bottom: 2px solid $color_grey;
    }

    &:focus {
      border: none;
      border-bottom: 2px solid $color_black;
      box-shadow: none;
    }

    &:disabled {
      border: none;
      color: $color_black;
      border-bottom: 2px solid $color_grey;
      background: #e6e6e6;
    }
  }
}

.big,
.medium {
  .underlined-text-field {
    label {
      font-size: 13px;
    }

    input {
      font-size: 24px;
      line-height: 28px;
    }
  }
}

.small {
  .underlined-text-field {
    label {
      font-size: 11px;
    }

    input {
      font-size: 20px;
      line-height: 24px;
    }
  }
}

.redux-password-field {
  .password-wrapper {
    position: relative;
  }

  .password {
    padding-right: 35px;
  }

  .password-visible-icon {
    padding: 4px;
    position: absolute;
    right: 5px;
    bottom: 8px;
    cursor: pointer;

    .icon {
      color: $color_border;
      transition: $transition_default;
    }

    &:hover {
      .icon {
        color: $color_primary;
      }
    }
  }
}

.text-field {
  &.small {
    input {
      font-size: 15px;
      line-height: 18px;
      letter-spacing: 0.05;
      padding: 7px 10px;
    }
  }
}
