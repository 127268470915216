@import "scssShared/variables.scss";

.featuredSegments {
  .header {
    justify-content: space-between;
    align-items: center;
  }

  .searchBar {
    width: 280px;
  }

  .tags {
    width: 270px;
    padding: 20px 15px 14px;
    .tag {
      margin-right: 5px;
      margin-bottom: 5px;
      cursor: pointer;
    }
  }

  .actionColumn {
    padding: 18px 0;
    width: 40px;
  }

  .segmentName {
    .segmentNameContentWrapper {
      display: flex;
      align-items: center;

      .schedulingIcon {
        width: 22px;
        height: 20px;
        color: $color_grey;
        border-radius: $br_small;
        border: 1px solid $color_border;
        box-sizing: border-box;
        background: #f9f9f9;
        margin-right: 12px;
        flex-shrink: 0;
        font-size: 11px;
        font-weight: normal;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        &:not(.active) {
          &:after {
            content: "";
            width: 16px;
            height: 3px;
            box-sizing: border-box;
            border-top: 1px solid #f9f9f9;
            border-bottom: 1px solid #f9f9f9;
            position: absolute;
            background-color: $color_grey;
            transform: rotate(-45deg);
          }
        }

        &.active {
          background: rgba(254, 127, 102, 0.1);
          border-color: $color_primary;
          color: $color_primary;
        }
      }
    }
  }

  .author {
    padding-right: 50px;
    width: 180px;
  }

  .date {
    width: 170px;
  }

  .exportDate {
    width: 135px;
  }

  .modifiedAt,
  .modifiedBy {
    display: block;
  }

  .modifiedBy {
    font-size: 11px;
  }

  .tagsRow {
    display: flex;
    align-items: center;
    padding: 10px 8px 14px;
    border-bottom: $border_grey_white_bg;

    .label {
      font-size: 11px;
      letter-spacing: 1px;
      margin-right: 10px;
      line-height: 24px;
      color: $color_grey;
      text-transform: uppercase;
      text-align: left;
      font-weight: bold;
      padding-top: 1px;
    }

    .tagsAndPicker {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;

      .tag {
        margin-right: 5px;
      }
    }
  }
}
