@import "scssShared/variables.scss";

.data-tags {
  .data-tags-header {
    justify-content: space-between;

    .search-form {
      width: 320px;
      position: absolute;
      left: 50%;
      margin-left: -160px;
    }
  }

  .info-message {
    margin: 20px 10px;
    text-align: center;
    font-size: 18px;
    font-weight: bold;

    a {
      text-transform: none;
      font-size: 18px;
      font-weight: bold;
      color: $color_primary;
    }
  }

  .data-tags-table {
    .segments-count {
      width: 170px;
    }

    .author {
      width: 190px;
    }

    .created {
      width: 140px;
    }

    .cta-header {
      width: 250px;
    }

    .tag-cell {
      padding-top: 18px;
      padding-bottom: 18px;
    }

    .tag {
      cursor: pointer;
    }

    .cta {
      text-align: right;

      > span {
        text-decoration: underline;
        font-family: $font_family_inter;
        font-size: 11px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: $color_grey_lighter;
        font-weight: 400;
        cursor: pointer;
        margin-right: 12px;

        &:hover {
          text-decoration: none;
        }
      }
    }

    .edit-button {
      margin-right: 5px;
    }
  }

  .tag-form-wrapper {
    padding: 30px 12px 30px 20px;
    box-sizing: border-box;

    .colors-wrapper {
      max-width: 750px;
    }

    .tag-form {
      display: flex;
      justify-content: space-between;

      .custom-text-field .text-field label,
      .label-like {
        display: block;
        color: $color_grey;
        font-family: $font_family_inter;
        font-size: 11px;
        letter-spacing: 1px;
        text-transform: uppercase;
        margin: 0 0 5px 0;
        font-weight: bold;
      }

      .custom-text-field .text-field label {
        margin-bottom: 13px;
      }

      .colors-buttons {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        margin-left: 25px;

        .colors-box {
          margin-right: 20px;
        }

        .buttons {
          display: flex;
          margin-bottom: 8px;
          margin-top: 25px;

          .icon-button {
            margin-left: 5px;
          }
        }
      }
    }
  }

  .buttons {
    .button {
      margin-left: 10px;
    }
  }

  .tags-info-message {
    padding-top: 20px;
  }
}
