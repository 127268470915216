@import "scssShared/variables.scss";

.sub-attribute-picker-wrapper {
  position: relative;
  flex: 1;

  .sub-attribute-dropdown-button {
    border: $border_grey_light_thin;
    background-color: $color_white;
    font-size: 15px;
    font-weight: 400;
    text-transform: none;
    letter-spacing: 0;
    box-shadow: none;
    padding: 0 10px;
    width: 100%;

    &:disabled {
      color: $color_disabled_field;
      background-color: $color_disabled_field_bg;
      opacity: 1;
    }

    & > span {
      display: flex;
      align-items: center;
    }

    &:hover:not(:disabled) {
      border: solid 1px $color_grey;
    }

    .text {
      flex: 1;
      text-align: left;
    }

    .placeholder {
      color: $color_grey_lighter;
    }

    .caret-wrapper {
      margin-left: 10px;
      padding-left: 7px;
      border-left: $border_grey_light_thin;
      color: $color_grey_lighter;
      font-size: 11px;
      height: 18px;
      display: flex;
      align-items: center;
    }

    &.error-shadow {
      border-color: $color_warning_red;

      &.is-visible {
        animation: animation-shake 1s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
      }
    }
  }

  .dropdown {
    background: $color_white;
    border-radius: $br_small;
    border: $border_grey_light_thin;
    position: absolute;
    z-index: 2;
    overflow: hidden;
    margin-top: 5px;
    min-width: 280px;
    width: auto;

    .empty-message {
      font-size: 14px;
      margin: 10px;
      color: $color_grey_lighter;
    }

    ul {
      display: block;
      margin: 0;
      padding: 15px;

      li {
        list-style-type: none;
        margin-bottom: 15px;
        cursor: pointer;
        transition: $transition_default;
        font-size: 11px;
        font-weight: bold;
        letter-spacing: 1px;
        line-height: 100%;
        font-family: $font_family_inter;
        text-transform: uppercase;
        color: $color_grey_lighter;

        &:last-child {
          margin-bottom: 0;
        }

        &:hover,
        &.active {
          color: $color_primary;
        }
      }
    }
  }
}
