@import "scssShared/variables.scss";

.form,
.oktaWrapper {
  width: 320px;
  padding: 24px 20px;
  box-sizing: border-box;
  background-color: $color_white;
  border-radius: $br_small;
  box-shadow: $bs_large;

  h2 {
    font-size: 28px;
    line-height: 33px;
    margin: 0 0 24px;
    text-align: center;
  }

  .recaptcha {
    iframe {
      transform: scale(0.925);
      transform-origin: 0 0;
    }
  }
}

.actions {
  background: $color_grey_secondary_menu;
  border-top: 1px solid $color_border;
  padding: 20px;
  margin: 0 -20px -24px;
  position: relative;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;

  &.withCheckbox {
    padding-bottom: 16px;
  }
}

.oktaLogin {
  height: 44px;
  width: 100%;
  display: block;
  background: #007dc1;
  color: $color_white;
  font-family: $font_family_inter;
  font-size: 13px;
  letter-spacing: 0.15em;
  text-decoration: none;
  font-weight: 500;
  text-transform: uppercase;
  border-radius: 4px;
  text-align: center;
  padding: 14px 0;
  box-sizing: border-box;
  transition: $transition_default;

  &.secondButton {
    margin-top: 15px;
  }

  img {
    height: 14px;
    width: auto;
    margin-bottom: -1px;
  }

  &.hideLogo {
    img {
      opacity: 0;
    }
  }

  &:hover {
    box-shadow: $bs_small;
  }

  &:disabled {
    cursor: not-allowed;
    background: #c4c4c4;
    border-color: #c4c4c4;
    color: #ffffff;
    box-shadow: none;
  }
}

.underformLink {
  display: table;
  text-align: center;
  margin: 15px auto 0;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.15em;
}

.generalTerms {
  margin-top: 15px;
  padding: 0 14px;
  margin-bottom: 0;

  a {
    text-transform: none;
    font-size: 12px;
    color: $color_primary;
  }

  .checkboxLabel {
    line-height: 15px;
    font-size: 12px;
    text-transform: none;
    letter-spacing: 0;
    font-weight: 400;
    margin-bottom: 0;

    > span {
      top: 7px;
    }
  }
}
