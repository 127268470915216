@import "scssShared/variables.scss";

.container {
  font-size: 15px;

  p {
    margin: 0 0 10px 0;
  }

  h3 {
    margin-bottom: 10px;
  }

  h3:first-child {
    margin-top: 5px;
  }

  a {
    color: inherit;
    font-size: inherit;
    text-transform: inherit;
  }

  strong {
    color: $color_primary;
  }

  .link {
    text-transform: none;
    font-size: 15px;
  }

  code {
    font-size: 14px;
  }
}

.buttonWrapper {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}
