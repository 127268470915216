@import "scssShared/variables.scss";

.container {
  display: flex;
  align-items: center;
  flex: 1;
}

.authorWrapper {
  display: flex;
  align-items: center;
  font-weight: 700;
  margin-right: 15px;
  flex: 1;

  .authorAvatar {
    width: 32px;
    height: 32px;
    margin-right: 5px;
    flex: 0 0 32px;
  }

  .authorName {
    font-size: 12px;
  }

  .authorLabel {
    font-size: 10px;
    color: $color_grey_lighter;
  }
}

.aclWrapper {
  display: flex;
  align-items: center;
  cursor: pointer;

  .aclEntryWrapper,
  .plusButton {
    margin-left: -7px;
    border: solid 1px $color_white;
  }

  .aclEntryWrapper {
    position: relative;
    background-color: $color_white;
    border-radius: 50%;

    .aclAvatar,
    .aclEntryOverlay {
      width: 32px;
      height: 32px;
    }

    .aclEntryOverlay {
      position: absolute;
      left: 0;
      top: 0;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 4;
      font-weight: 700;
      font-size: 12px;
      color: $color_white;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
    }
  }

  .plusButton {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color_primary;
    color: $color_white;
    font-size: 12px;
    border-radius: 50%;
    z-index: 5;
  }
}
