@import "variables.scss";

.paper {
  .tag-filter {
    padding: 10px 8px 14px 8px;
    border-bottom: $border_grey_white_bg;
    display: flex;
    flex-wrap: wrap;

    .selected-tags {
      font-size: 11px;
      letter-spacing: 1px;
      margin-right: 10px;
      line-height: 24px;
      color: $color_grey;
      text-transform: uppercase;
      text-align: left;
      font-weight: bold;
      padding-top: 1px;
    }

    .tag {
      margin-right: 5px;
    }
  }

  &.table-content {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.table {
  font-weight: bold;
  width: 100%;
  border-collapse: collapse;
  display: table;

  th {
    font-size: 11px;
    letter-spacing: 1px;
    color: $color_grey;
    text-transform: uppercase;
    text-align: left;
  }

  td {
    &.text-grey {
      color: $color_grey_lighter;
      font-weight: 400;
      font-size: 14px;
    }

    &.no-value {
      color: $color_border;
    }

    a {
      font-weight: bold;
      font-size: 16px;
      color: $color_black;
      text-decoration: none;
      text-transform: none;
    }

    .customer-attribute-value {
      display: block;
    }
  }

  th,
  td {
    padding: 10px 8px;

    &.align-right {
      text-align: right;
    }

    &.align-center {
      text-align: center;
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid #e6e6e6;

      &:last-child {
        border-bottom: 0;
      }
    }
  }
}
