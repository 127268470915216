@import "scssShared/variables.scss";

.scheduler-form {
  text-align: left;

  &.modal {
    width: 1080px;
    padding: 0px;

    header {
      padding: 20px 20px 0;

      h3 {
        margin-bottom: 20px;
      }

      .close-button {
        top: 13px;
        right: 16px;
      }
    }
  }

  .tabs-n-buttons {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;

    .tabs {
      > button {
        margin: 0 10px 5px 0px;

        &.error-schedule {
          border-color: $color_warning_red;
          background: $color_warning_red;
          color: $color_white;
        }
      }

      .add-schedule-button {
        width: 44px;
      }

      .remove-schedule-button {
        padding: 0 2px;
        margin-left: 5px;
      }
    }

    .buttons {
      width: 360px;
      flex-shrink: 0;
      text-align: right;

      > button {
        margin-left: 15px;
      }

      .undo-button {
        padding-left: 0;
        padding-right: 0;

        &:hover {
          box-shadow: none;
        }
      }
    }
  }

  .tiny-label {
    position: absolute;
    color: $color_grey_lighter;
    font-weight: normal;
    font-size: 12px;
  }

  .form-inputs {
    margin-top: 10px;
    padding: 21px 20px 9px;
    border-top: 1px solid $color_border;
    border-bottom: 1px solid $color_border;
    background: $color_catskill-white;
    display: flex;

    &.hidden {
      display: none;
    }

    .days {
      display: flex;
      padding: 5px 0;
      position: relative;

      .tiny-label {
        top: -12px;
      }

      .day-option {
        margin-right: 5px;

        label {
          box-sizing: border-box;
          height: 44px;
          width: 55px;
          color: $color_white;
          font-weight: 500;
          text-transform: uppercase;
          letter-spacing: 0.15em;
          font-size: 13px;
          display: flex;
          background: $color_white;
          color: $color_grey_lighter;
          justify-content: center;
          align-items: center;
          border-radius: $br_large;
          border: 1px solid $color_border;
          cursor: pointer;
          transition: $transition_default;

          &:hover {
            color: $color_black;
            border-color: $color_grey;
            box-shadow: $bs_large;
          }

          &:focus {
            outline: none;
            box-shadow: $button_white_focus_box_shadow;
          }
        }

        input {
          display: none;

          &:checked {
            ~ label {
              background-color: $color_primary;
              border-color: $color_primary;
              color: $color_white;

              &:hover {
                opacity: 0.7;
              }
            }
          }
        }

        .error {
          position: relative;

          label {
            border-color: $color_warning_red;
            box-shadow: 0 1px 3px 0px rgba(237, 56, 42, 0.25);
            animation: animation-shake 1s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
            transform: translate3d(0, 0, 0);
            backface-visibility: hidden;
            perspective: 1000px;
            color: $color_warning_red;
          }

          .error-message {
            position: absolute;
            margin: 1px 0 0 10px;
            font-size: 11px;
            line-height: 13px;
            font-weight: bold;
            color: $color_warning_red;
            width: 130px;
          }
        }
      }
    }

    .type-toggle {
      margin-left: 15px;
      padding: 10px 0 0 20px;
      border-left: 1px solid $color_very-light-grey;
    }

    .once-fields,
    .repeat-fields {
      display: flex;
      align-items: center;
      margin-left: 20px;
      .field-lbl {
        font-size: 15px;
        line-height: 18px;
        font-weight: normal;
        color: $color_grey_lighter;
        margin-right: 10px;
      }

      .text-field {
        position: relative;
        .error-message {
          position: absolute;
          margin-left: 0;
        }
      }
    }

    .once-fields {
      input {
        width: 35px;
      }

      .double-dot {
        margin: 0 3px;
      }

      .text-field {
        &.once-minute {
          .error-message {
            display: none;
          }
        }

        &.once-hour {
          .error-message {
            margin-left: 10px;
            width: 130px;
          }
        }
      }
    }

    .repeat-fields {
      font-weight: normal;
      position: relative;

      .tiny-label {
        top: -12px;

        &.time-label {
          width: 200px;
          left: 192px;
        }
      }

      .repeat-select {
        min-width: auto;
        width: 90px;
        position: relative;

        .error-message {
          position: absolute;
          width: 100px;
          margin-left: 13px;
        }
      }

      .field-lbl {
        &.from {
          margin-left: 10px;
        }

        &.to {
          margin-left: 5px;
          margin-right: 5px;
        }
      }

      .react-select-redux-field__indicator-separator {
        display: none;
      }
      .react-select-redux-field__value-container {
        padding-left: 9px;
      }

      .repeat-hrs {
        position: relative;

        input {
          width: 30px;
          padding: 7px 20px 7px 10px;
        }

        &:after {
          content: "hr";
          position: absolute;
          display: block;
          right: 12px;
          top: 11px;
          font-size: 12px;
          color: $color_grey_lighter;
        }

        .error-message {
          position: absolute;
          width: 150px;
          margin-left: 12px;
        }

        &.to {
          .error-message {
            display: none;
          }
        }
      }
    }

    .utc-info {
      display: flex;
      align-items: center;
      margin-left: 10px;
      color: $color_grey_lighter;
      font-size: 12px;

      .schedule-local {
        padding: 4px 6px;
        font-weight: normal;
        font-size: 10px;
        line-height: 125%;

        .value {
          font-weight: bold;
          margin-left: 20px;
        }

        p {
          margin: 2px 0;
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }

  .scheduler-natural-language-message {
    padding: 16px 20px;
    margin: 0;
    background: transparent;
    line-height: 18px;
    font-size: 15px;
    color: $color_grey_lighter;
    font-weight: 400;

    > span {
      color: $color_black;
      font-weight: bold;
    }

    &.hidden {
      display: none;
    }
  }
}
