@import "scssShared/variables.scss";

.bannerCard {
  font-size: 0;
  position: relative;
  iframe {
    border: 0;
  }
}

.imageBanner {
  img {
    width: 100%;
  }
}

.noContent {
  padding: 20px;
  border: 1px solid $color_border;
  border-radius: $br_large;
  background-color: $color_white;
  font-size: 14px;
}

.closeButton {
  width: 24px;
  height: 24px;
  background-color: $color_white;
  border-radius: 12px;
  position: absolute;
  color: $color_black;
  border: 1px solid $color_border;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: -12px;
  top: -12px;
  cursor: pointer;
  padding: 0;

  svg {
    transform: scale(0.75);
  }
}

.bannerInnerHtml {
  max-height: calc(100vh - 40px);
}

.paper {
  padding: 20px;
}

.paperInnerHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  h3 {
    margin: 0 0 15px;
  }

  .titleWithToggle {
    display: flex;
    align-items: center;

    h3 {
      margin: 0;
    }
  }

  .minimizedToggleWrapper {
    margin-left: 20px;
  }
}

.preview {
  margin-top: 5px;
  position: relative;
  padding: 20px;
  box-sizing: border-box;
  border-radius: $br_medium;
  background-image: url("./bgCover.png");
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  &.left,
  &.top_left,
  &.bottom_left {
    justify-content: flex-start;
  }

  &.right,
  &.top_right,
  &.bottom_right {
    justify-content: flex-end;
  }

  &.left,
  &.right,
  &.middle {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  &.top,
  &.top_left,
  &.top_right {
    padding-bottom: 100px;
  }

  &.bottom,
  &.bottom_left,
  &.bottom_right {
    padding-top: 100px;
  }

  .previewLabel {
    font-size: 10px;
    font-weight: bold;
    line-height: 12px;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    color: $color_black;
    margin-bottom: 10px;
  }
}

.bannerPreviewControls {
  display: flex;

  .playAnimationButton {
    svg {
      margin-right: 8px;
    }
  }

  .toggleBannerTypeButton {
    margin-left: 10px;
    p {
      margin: 0;
    }
  }
}
