@import "scssShared/variables.scss";

.detail-destination {
  .detail-destination-header {
    justify-content: space-between;
    position: sticky;
    top: 0px;
    z-index: 1;

    .button {
      margin-left: 10px;
    }
  }

  .detail-destination-content {
    padding: 0;
  }
}
