@import "scssShared/variables.scss";

.compound-attr-table {
  text-align: left;
  max-width: 100%;

  .compound-attr-table-body,
  .compound-attr-table-header {
    border: 1px solid $color_border;
    box-sizing: border-box;
    box-shadow: $bs_smallest;
    border-radius: $br_small;
  }

  .compound-attr-table-header {
    background: $color_white;
    margin-right: 5px;
    padding: 0 10px;
    white-space: pre;
    float: left;

    .sub-attr-name {
      font-size: 12px;
      line-height: 15px;
      font-weight: bold;
      color: $color_grey_lighter;
      border-bottom: $border_grey_white_bg;
      padding: 12px 0;

      &:last-child {
        border-bottom: 0;
      }
    }
  }

  .compound-attr-table-body {
    background: $color_grey_secondary_menu;
    display: flex;
    position: relative;

    .scrollable-area {
      overflow-x: auto;
      display: flex;
    }

    .compound-attr-table-col {
      padding: 0 11px 0 10px;
      position: relative;

      &:after {
        position: absolute;
        display: block;
        content: "";
        width: 1px;
        height: calc(100% - 20px);
        right: 0px;
        top: 10px;
        background: #e6e6e6;
      }

      &:last-child {
        padding-right: 10px;

        &:after {
          width: 0;
        }
      }

      .compound-attr-table-value {
        font-size: 14px;
        line-height: 17px;
        color: $color_grey_lighter;
        border-bottom: $border_grey_white_bg;
        padding: 11px 0;
        white-space: pre;

        &:last-child {
          border-bottom: 0;
        }
      }
    }

    .fade-overlay {
      position: absolute;
      right: 0;
      height: 100%;
      width: 120px;
      max-width: 50%;
      background: linear-gradient(270deg, #f7f9fb 0%, rgba(247, 249, 251, 0) 100%);
      border-radius: 0 $br_small $br_small 0;
    }
  }
  .compound-attr-table-pagination {
    width: 100%;
    text-align: center;
    font-size: 10px;
    color: $color_grey;
    font-weight: bold;
    margin-top: 8px;

    .nav-button-left,
    .nav-button-right {
      font-size: 10px;
      padding: 2px 4px;
      border: 0;
      background: transparent;
      transition: $transition_default;
      cursor: pointer;
      color: $color_grey;

      &:focus {
        box-shadow: none;
        outline: none;
      }

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }

      &:hover {
        color: $color_grey_lighter;
      }
    }

    .page-number {
      position: relative;
      height: 16px;
      width: 16px;
      text-align: center;
      display: inline-block;

      &.loading {
        position: relative;
        cursor: wait;
        color: $color_white;

        &:before {
          margin: -12px 0 0 -10px;
          width: 16px;
          height: 16px;
          position: absolute;
          left: 50%;
          top: 50%;
          content: "";
          border-radius: 13px;
          background-clip: padding-box;
          border-color: rgba($color_primary, 0.25);
          border-width: 2px;
          border-style: solid;
          border-top-color: $color_primary;
          animation: animation-rotate 975ms cubic-bezier(0.25, 0.35, 0.5, 0.95) infinite;
        }
      }
    }

    .nav-button-left {
      margin-right: 7px;
    }
    .nav-button-right {
      margin-left: 7px;
    }
  }
}
