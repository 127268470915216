@import "scssShared/variables.scss";

.container {
  border-radius: $br-large;
  border: $border_grey_light_thin;
  background-color: $color-white;
  padding: 20px;
  position: absolute;
  width: 320px;
  z-index: 3;
}

.title {
  font-family: $font_family_neue;
  font-size: 24px;
  font-weight: 700;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px -5px -5px -5px;
}

.moveButton {
  padding: 5px;
  outline: none;
  border: none;
  background-color: transparent;
  font-size: 16px;
  font-family: $font_family_inter;
  color: $color_black;
  text-decoration: underline;
  transition: $transition_short;

  &:hover {
    text-decoration-color: transparent;
  }
}

.dotsWrapper {
  display: flex;
}

.dot {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: #bbb;
  transition: $transition_short;
  margin: 0 3px;

  &.active {
    background-color: $color_black;
  }
}
