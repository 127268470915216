@import "scssShared/variables.scss";

.customer-detail {
  margin-top: 54px;

  .transparent {
    opacity: 0.4;
    transition: opacity 0.25s ease-in-out;
  }

  .switch-row {
    width: 100%;
    position: relative;
    text-align: center;
    margin-top: 14px;

    hr {
      width: 100%;
      height: 1px;
      background: $color_border;
      display: block;
      position: absolute;
      top: 33px;
      border: 0;
      margin: 0;
    }

    .switch-background {
      background-color: $color_grey_site_background;
      width: 288px;
      margin: 0 auto;
      position: relative;

      .toggle-switch-multiple {
        margin: 16px 0;
      }
    }
  }

  .customer-detail-content {
    margin-top: 14px;

    .identity-graph {
      padding: 0;
    }

    .timeline-content {
      display: flex;
      align-items: flex-start;
    }

    .left-panel {
      width: 285px;
      flex-shrink: 0;
      position: sticky;
      top: 0;
    }
    .right-panel {
      width: calc(100% - 285px);
    }

    .sources-picker {
      margin-bottom: 20px;
    }

    .customer-attributes-boxes {
      width: 100%;

      .two-cols {
        display: flex;
        flex-wrap: wrap;

        .left,
        .right {
          width: calc(50% - 10px);
          flex-shrink: 0;
        }

        .left {
          margin-right: 20px;
        }
      }

      .customer-attributes-box {
        padding: 0px;
        position: relative;
        margin-bottom: 20px;

        &.invisible {
          width: calc(50% - 10px);
          visibility: hidden;
          position: absolute;
        }

        .box-header {
          background: $color_catskill-white;
          min-height: 52px;
          padding: 15px 20px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          box-sizing: border-box;
          box-shadow: $bs_smallest;
          border-radius: $br_medium;

          .box-header-actions {
            display: flex;

            .caret {
              margin-left: 3px;
              margin-right: -12px;
              padding: 8px;
            }
          }

          h3 {
            font-family: $font_family_inter;
            font-weight: bold;
            font-size: 13px;
            line-height: 16px;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            color: $color_grey_lighter;
            margin: 0;
            padding-left: 14px;
            position: relative;
          }
        }

        .box-content {
          border-top: 1px solid $color_border;
          transition: all 0.2s ease-in-out;
          overflow-y: hidden;

          .customer-attributes-values-list {
            padding: 5px 15px 15px;
          }
        }

        .hide-empty-fields {
          font-size: 9px;
          font-weight: bold;
          letter-spacing: 1px;
          text-transform: uppercase;
          color: $color_border;
          display: flex;
          align-items: center;

          .label {
            margin-right: 5px;
            transition: color 0.15s ease-in;

            &.orange {
              color: $color_primary;
            }
          }
        }

        &:last-child {
          margin-bottom: 0;
        }

        @each $name, $color in $colors {
          &.#{$name} {
            border-top: 3px solid $color;

            .box-header {
              h3 {
                &:before {
                  content: "";
                  display: block;
                  width: 8px;
                  height: 8px;
                  border-radius: 50%;
                  background-color: $color;
                  position: absolute;
                  left: 0px;
                  top: 50%;
                  margin-top: -5px;
                }
              }
            }
          }
        }
      }
    }

    .no-results-found {
      p {
        font-weight: bold;
        margin: 5px 0;
        text-align: center;

        &.timeline-message {
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: $color_grey;
          margin: 10px 0;

          strong {
            font-weight: bold;
            color: $color_primary;
          }
        }
      }
    }

    .customer-timeline {
      margin-left: 54px;
      flex-grow: 1;

      .group-row {
        position: relative;
        padding-bottom: 10px;

        &:before {
          content: "";
          display: block;
          width: 2px;
          background-color: $color_border;
          height: 100%;
          position: absolute;
        }

        &:last-of-type {
          padding-bottom: 0;

          &:before {
            height: calc(100% - 42.5px);
            top: 0;
          }

          &:first-child {
            &:before {
              background-color: transparent;
            }
          }
        }

        &:first-child {
          &:before {
            height: calc(100% - 42.5px);
            bottom: 0;
          }
        }

        .timeline-text {
          color: $color_grey_lighter;
          font-size: 11px;
          line-height: 13px;
          font-weight: bold;
          letter-spacing: 0.05em;
          text-transform: uppercase;
          position: absolute;
          background-color: $color_grey_site_background;
          display: block;
          padding: 5px 0;
          left: 0;
          top: 42.5px;
          transform: translate(-50%, calc(-50% - 5px));
          max-width: 100px;
          text-align: center;

          &.week {
            max-width: 85px;
          }
        }

        .group-box {
          font-size: 16px;
          font-weight: bold;
          padding: 5px;
          margin-left: 54px;
          width: calc(100% - 54px);
          color: $color_grey_lighter;
          display: flex;

          .counts-wrapper {
            display: flex;
            flex-wrap: wrap;

            .count-entry {
              margin: 15px;
              display: flex;
            }

            .icon-wrapper {
              flex-shrink: 0;
              box-sizing: border-box;
              width: 32px;
              height: 32px;
              display: flex;
              align-items: center;
              padding: 2px;
              border-radius: 50%;
              background: $color_border;
              justify-content: center;

              @each $name, $color in $colors {
                &.#{$name} {
                  &:not(.greyscale) {
                    background: $color;
                  }
                }
              }

              .source-icon {
                max-height: 22px;
                max-width: 22px;
              }
            }

            .count-info {
              margin-left: 8px;
              > span {
                display: block;
              }
            }

            .count {
              color: $color_black;
              font-size: 16px;
              line-height: 16px;

              &.zero-value {
                color: $color_grey;
              }

              .zero-x {
                > span {
                  font-size: 12px;
                  line-height: 12px;
                  font-weight: normal;
                }
              }
            }

            .source-name {
              color: $color_grey;
              font-size: 11px;
              letter-spacing: 1px;
              margin-top: 3px;
              font-weight: 400;
              white-space: nowrap;
            }
          }

          .more-wrapper {
            width: 35px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            flex-shrink: 0;
            padding-right: 10px;

            .group-action-button {
              min-height: auto;
              min-width: auto;
              width: 28px;
              height: 28px;
              border: 1px solid $color_border;
              border-radius: $br_medium;
              box-shadow: $bs_smallest;
              color: $color_grey_lighter;
              padding: 0;
              transition: $transition_default;
              position: relative;

              &:hover {
                opacity: 1;
                border-color: $color_primary;
                color: $color_primary;
              }

              &.loading {
                cursor: wait;

                svg {
                  opacity: 0;
                }

                &:before {
                  margin: -10px 0 0 -10px;
                  width: 16px;
                  height: 16px;
                  position: absolute;
                  left: 50%;
                  top: 50%;
                  content: "";
                  border-radius: 24px;
                  background-clip: padding-box;
                  border-color: rgba($color_primary, 0.25);
                  border-width: 2px;
                  border-style: solid;
                  border-top-color: $color_primary;
                  animation: animation-rotate 975ms cubic-bezier(0.25, 0.35, 0.5, 0.95) infinite;
                }
              }
            }
          }
        }

        &.opened {
          .opened-sticky {
            position: sticky;
            top: 0;
            z-index: 1;
          }
        }
      }

      .load-more-wrapper {
        margin-left: 74px;
        margin-top: 10px;
        width: calc(100% - 94px);
        text-align: center;
      }

      .no-results-found {
        &:before {
          background-color: transparent;
        }

        &.events-not-found {
          margin-left: 74px;
          margin-top: 10px;
          width: calc(100% - 94px);
        }
      }
    }
  }

  .customer-attributes-values-list {
    font-size: 14px;

    .row {
      display: flex;
      justify-content: space-between;
      border-bottom: $border_grey_white_bg;
      padding: 0 5px;
      overflow: hidden;

      &.compound-attr-view {
        display: block;

        .attrname {
          float: left;
        }
      }

      &:last-child {
        border-bottom: 0;
      }

      .attrname {
        flex-basis: 50%;
        max-width: 50%;
        padding-top: 0;
        padding-bottom: 0;
        vertical-align: top;
        padding-right: 20px;
        box-sizing: border-box;

        .name-tags {
          display: flex;
          flex-wrap: wrap;
          min-height: 32px;
          padding-top: 4px;
          padding-bottom: 4px;
          align-items: center;
        }

        .name-wrapper {
          font-weight: bold;

          .name {
            margin-right: 4px;
          }
        }

        .tag {
          margin: 2.5px 5px 2.5px 0;
        }
      }
      .attrvalue {
        word-break: break-word;
        flex-basis: 50%;
        justify-content: flex-end;
        display: flex;
        align-items: center;
        text-align: right;
        padding: 10px 0;

        .value-wrapper {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
        }

        .show-more-attributes {
          margin-top: 10px;
          height: 24px;
          padding: 0 12px;
          font-size: 9px;

          &.loading {
            &:before {
              width: 14px;
              height: 14px;
              margin: -9px 0 0 -9px;
            }
          }
        }
      }

      .attrname,
      .attrvalue {
        &.no-value {
          color: $color_border;
        }
      }
    }
  }

  .pagination {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
}
