@import "scssShared/variables.scss";

.attributeId {
  box-sizing: border-box;
  display: inline-block;
  margin-right: 10px;
  margin-top: 10px;
  max-width: 100%;
  overflow: hidden;
  padding: 4px 8px;

  color: $color_grey;
  cursor: pointer;
  border: $border_grey;
  border-radius: $br_large;
  font-size: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;

  svg {
    width: 1.25em !important;
  }

  :first-child {
    margin-right: 5px;
  }
}
