@import "scssShared/variables.scss";

.setup-attributes {
  .info-message {
    margin: 20px 10px;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
  }

  .setup-attributes-header {
    justify-content: space-between;
  }

  .attributes-table {
    table-layout: fixed;

    .name {
      width: 21%;
    }

    .source-col {
      width: 20%;
    }

    .actions {
      width: 70px;
    }

    .labels-col {
      width: 18%;

      .labels-tooltip-icon {
        margin-left: 3px;
        position: static;
        margin-right: 3px;
      }

      .labels-tooltip {
        font-size: 12px;
        font-weight: 400;
        text-transform: none;
        padding: 8px;
        max-width: 400px;
      }
    }

    .attribute-source,
    .attribute-tags,
    .attribute-examples {
      font-weight: normal;
      font-size: 14px;
    }
    .attribute-name {
      .badge {
        margin-left: 4px;
      }

      .sub-attrs {
        margin: 5px 0 0;
        padding: 0;

        li {
          list-style-type: none;
          font-size: 12px;
          line-height: 15px;
          color: $color_grey_lighter;
          font-weight: normal;
          margin-bottom: 2px;
          position: relative;
          padding-left: 12px;

          &:before {
            content: "";
            display: block;
            width: 8px;
            height: 1px;
            background: $color_border;
            position: absolute;
            left: 0;
            top: 50%;
            margin-top: -2px;
          }

          &:last-child {
            margin-bottom: 0px;
          }
        }
      }
    }
    .attribute-source {
      .image-wrapper {
        width: 32px;
        height: 32px;
        flex-shrink: 0;
        border: 1px solid $color_border;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 80%;
          height: auto;
        }
      }

      .source {
        margin-left: 6px;
      }

      .attribute-source-flex-wrapper {
        display: flex;
        align-items: center;
      }
    }

    .attribute-tags {
      line-height: 30px;
    }

    .attribute-description {
      white-space: pre-line;
    }

    .is-hidden {
      .attribute-name,
      .attribute-source,
      .attribute-tags,
      .attribute-description {
        opacity: 0.3;
      }
    }
  }
  .attribute-label {
    cursor: pointer;
    white-space: normal;
    min-height: 24px;
    height: auto;
    line-height: 12px;
    padding-top: 6px;
    padding-bottom: 6px;
    margin: 0 5px 5px 0;
  }

  .left-margin {
    margin-left: 5px;
  }

  .disabled-button-tooltip {
    font-size: 12px;
    font-weight: normal;
    padding: 10px;
  }

  .attributes-not-found {
    padding-top: 15px;
  }
}
