@import "scssShared/variables.scss";
@import "scssShared/flexbox.scss";

.events-container {
  .paper-header {
    position: relative;

    .search-form-with-select {
      position: absolute;
      left: 50%;
      margin-left: -240px;
    }
  }

  .events-table {
    .source-col {
      width: 21%;
      padding-left: 10px;
      padding-right: 10px;
    }
    .examples-col {
      width: 704px;
    }

    .event-source {
      font-weight: normal;
      font-size: 14px;
      padding-left: 10px;
      padding-right: 10px;
      .event-source-flex-wrapper {
        @include flexAcross();
        align-items: center;
        img {
          width: 35px;
          padding-right: 12px;
        }
        .source-n-count {
          @include flexDown();
          .count {
            font-size: 12px;
            color: $color_grey;
          }
        }
      }
    }

    .attribute-tags {
      line-height: 35px;
    }

    .event-examples {
      position: relative;
      padding: 15px;
      .carousel {
        width: 690px;
        position: relative;

        .carousel-content {
          width: 100%;
          height: 100%;
          position: relative;
          overflow: hidden;
        }

        .carousel-button {
          position: absolute;
          width: 30px;
          height: 30px;
          top: 50%;
          margin-top: -15px;
          background: $color_white;
          border: $border_grey_light_thin;
          border-radius: 50%;
          color: $color_border;
          text-align: center;
          font-size: 15px;
          box-shadow: $bs_smallest;

          &:focus {
            border: $border_grey_light_thin;
            outline: none;
          }

          &:hover {
            box-shadow: $bs_large;
          }

          &.carousel-left {
            left: -15px;
          }

          &.carousel-right {
            right: -15px;
          }
        }

        .event-example {
          width: 100%;
          height: 100%;
          padding: 10px 25px;
          border: $border_grey_light_thin;
          background: #fafafa;
          box-sizing: border-box;
          border-radius: $br_small;
          top: 0px;
          transition: left 0.25s ease-out;
          display: flex;
          flex-direction: column;
          justify-content: center;

          p {
            font-size: 12px;
            margin: 0 0 5px;
          }

          h4 {
            font-size: 16px;
            margin: 0 0 6px;
            word-break: break-word;
            color: $color_grey;
            line-height: 19px;
          }

          h5 {
            font-size: 12px;
            font-weight: 500;
            margin: 10px 0 5px;
            color: $color_grey;
          }

          ul {
            margin: 0;
            padding: 0 0 0 16px;

            li {
              font-size: 12px;
              font-weight: 400;
            }
          }

          a {
            font-size: 12px;
            text-transform: uppercase;
            text-decoration: underline;
            color: $color_grey;
            font-weight: normal;

            &:hover {
              color: $color_grey;
              text-decoration: none;
            }
          }

          .payload {
            color: $color_grey;
            font-weight: normal;
            font-size: 12px;

            .payload-content {
              .entry {
                margin-bottom: 5px;
                width: 100%;
                word-break: break-word;

                &:last-child {
                  margin-bottom: 0;
                }
              }
            }
          }
        }
      }
    }
  }
  .tag {
    margin: 0 0 0 5px;
    cursor: pointer;
  }
}
