@import "scssShared/variables.scss";

.vertical-chart {
  position: relative;
  overflow-y: auto;
  height: calc(100% - 31px);

  .legend {
    padding-top: 5px;
    text-align: center;
    position: sticky;
    background: $color_white;
    top: 0;
    z-index: 1;
    transition: padding-top 0.2s ease-in-out;

    .legend-button {
      background: transparent;
      border: none;
      font-weight: bold;
      font-size: 11px;
      line-height: 16px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      position: relative;
      padding: 0 0 0 20px;
      margin: 0 5px 1px;
      transition: all 0.2s ease-in-out;

      &:before {
        content: "";
        display: block;
        position: absolute;
        width: 16px;
        height: 16px;
        border-radius: 8px;
        box-sizing: border-box;
        border: 2px solid $color_primary;
        background: rgba($color_primary, 0.5);
        left: 0;
      }

      @each $name, $color in $colors {
        &.#{$name} {
          color: $color;

          &:before {
            border-color: $color;
            background: rgba($color, 0.5);
          }
        }
      }

      &.hidden {
        opacity: 0.5;
      }

      &:focus {
        outline: none;
      }
    }

    .legend-toggle-button {
      background: transparent;
      border: none;
      font-size: 11px;
      line-height: 13px;
      letter-spacing: 0.05em;
      text-decoration: underline;
      text-transform: uppercase;
      color: $color_grey;
      font-weight: bold;
      display: table;
      margin: 4px auto 0;

      &:hover {
        text-decoration: none;
      }

      &:focus {
        outline: none;
      }
    }
  }

  .data {
    margin-top: 20px;
    .row {
      width: 100%;
      margin: 10px 0;
      display: flex;
      align-items: center;

      .row-title {
        width: 50%;
        padding-right: 5px;
        font-size: 11px;
        line-height: 13px;
        text-align: right;
        color: $color_grey_lighter;
        flex-shrink: 0;
        transition: width 0.2s ease-in-out;
      }

      .bar {
        height: 22px;
        margin-left: 5px;
        border-radius: 3px;
        font-weight: bold;
        font-size: 11px;
        line-height: 23px;
        position: relative;
        transition: width 0.2s ease-in-out;

        &.has-value {
          color: $color_white;
          text-align: center;
          background: $color_primary;
        }

        &.no-value {
          text-align-last: left;
        }

        span {
          white-space: pre;

          &.next-to {
            position: absolute;
          }
        }

        @each $name, $color in $colors {
          &.#{$name} {
            &.has-value {
              background: $color;
            }

            &.no-value {
              color: $color;
            }

            .next-to {
              color: $color;
            }
          }
        }
      }
    }
  }

  &.expanded {
    .data {
      .row {
        .row-title {
          width: 20%;
        }
      }
    }
  }
}
