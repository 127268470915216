@import "scssShared/variables.scss";

.timeline-filter-form {
  width: 100%;

  .search-input-header {
    height: 85px;
  }

  .timeline-options {
    padding: 0;

    .scrollable {
      max-height: calc(95vh - 130px);
      overflow-y: auto;
      padding: 10px;
    }

    &.fetching {
      cursor: progress;

      button {
        cursor: progress;
      }

      .checkbox-field {
        label {
          cursor: progress;

          .checkbox {
            &:disabled {
              & ~ .checkmark {
                opacity: 1;
              }
            }
          }
        }
      }
    }

    .timeline-options-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 5px 0 10px;
      margin-bottom: 8px;

      .title {
        font-family: $font_family_inter;
        font-weight: bold;
        font-size: 10px;
        text-transform: uppercase;
        letter-spacing: 0.1em;
        color: $color_grey;
      }

      .button {
        &.small {
          letter-spacing: 0.5px;
        }
      }

      .delimiter {
        color: $color_border;
        font-weight: normal;
        margin: 0 3px;
      }
    }

    .form-row {
      padding: 0 40px 15px 10px;
      position: relative;
      border-bottom: $border_grey_white_bg;

      .sourcename {
        font-weight: bold;
        font-size: 15px;
        line-height: 18px;
        color: $color_black;

        &.clickable {
          cursor: pointer;
          transition: $transition_default;
          &:hover {
            opacity: 0.5;
          }
        }

        &.turned-off {
          color: $color_border;

          &:hover {
            opacity: 1;
            color: $color_black;
          }
        }
      }

      &:after {
        clear: both;
        content: "";
        display: block;
      }

      &:last-child {
        margin-bottom: 10px;
        padding-bottom: 0;
        border-bottom: 0;
      }

      .source-icon {
        width: 28px;
        height: 28px;
        position: absolute;
        top: -6px;
        right: 5px;
        text-align: center;
        box-sizing: border-box;
        border-radius: 14px;
        padding: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: $transition_default;

        @each $name, $color in $colors {
          &.#{$name} {
            background: $color;
          }
        }

        &.disabled {
          background-color: $color_border;
        }

        &:hover {
          opacity: 0.5;
        }

        img {
          max-width: 18px;
          max-height: 18px;
        }
      }

      .bold-label {
        font-weight: bold;
        display: table;
        float: left;
      }

      .caret-button {
        min-width: auto;
        min-height: auto;
        width: 14px;
        height: 18px;
        padding: 0;

        margin-left: 5px;
      }

      .event-types {
        margin: 15px 0 0 0;

        &.expanded {
          display: block;
        }

        &.collapsed {
          display: none;
        }

        .sub {
          margin-bottom: 10px;

          &:last-child {
            margin-bottom: 0;
          }

          label {
            font-size: 13px;
            word-break: break-word;
            font-weight: bold;
            padding-left: 20px;
            color: $color_border;
            transition: $transition_default;
            text-transform: none;
            line-height: normal;
            margin-bottom: 0;
            letter-spacing: 0;

            .checkbox {
              &:checked {
                ~ .checkmark {
                  background-color: $color_white;
                  border-color: $color_white;
                }
              }
            }

            .checkmark {
              background-color: $color_white;
              border-color: $color_white;

              .icon-check {
                color: $color_border;

                path {
                  transition: $transition_default;
                }
              }
            }

            &.label-checked {
              color: $color_black;

              .checkmark {
                .icon-check {
                  color: $color_black;
                }
              }
            }
          }
        }
      }

      @each $name, $color in $colors {
        &.#{$name} {
          &:before {
            background-color: $color;
          }
        }
      }
    }
  }
}
