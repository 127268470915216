@import "scssShared/variables.scss";

.admin-segment-analytics {
  padding-bottom: 18px;

  .admin-segment-analytics-header {
    justify-content: space-between;

    .form-action-buttons {
      display: flex;

      .save-create {
        margin-right: 10px;
      }
    }

    .cancel,
    .delete-button,
    .left-button {
      margin-right: 10px;
    }

    .vertical-line {
      display: block;
      width: 1px;
      height: 36px;
      margin: -1px 20px;
      background: $color_border;
    }

    .actions-wrapper {
      display: flex;
      align-items: center;

      .admin-insights-layout-picker {
        margin-left: 20px;
      }
    }
  }
  .admin-segment-analytics-content {
    &.form-content {
      padding: 0;
    }

    &:after {
      clear: both;
      content: "";
      display: table;
    }

    .info-message {
      text-align: center;
      font-size: 18px;
      font-weight: bold;
      margin: 10px 0;
    }

    .analytics-form-section {
      display: flex;
      align-items: stretch;
      justify-content: space-between;

      .analytics-form {
        padding: 30px 20px;
        flex-grow: 1;

        .select-input {
          z-index: 3;
        }

        .filter-attribute-select-wrapper {
          z-index: 4;
          .filter-attribute-select-button {
            height: calc(100% - 16px);
          }
        }

        .compound-attr-form-row {
          position: relative;
          padding-left: 20px;
          display: flex;
          padding-top: 2px;

          &:before {
            position: absolute;
            width: 2px;
            height: 48px;
            display: block;
            content: "";
            background-color: $color_border;
            left: 10px;
            top: 0;
          }

          &:after {
            position: absolute;
            height: 2px;
            width: 10px;
            display: block;
            content: "";
            background-color: $color_border;
            left: 10px;
            top: 46px;
          }

          .left-field,
          .right-field {
            flex-basis: 50%;
          }
          .left-field {
            padding-right: 5px;
            position: relative;

            &:after {
              position: absolute;
              height: 2px;
              width: 10px;
              display: block;
              content: "";
              background-color: $color_border;
              right: -5px;
              top: 44px;
            }
          }
          .right-field {
            padding-left: 5px;
          }
        }

        .date-value {
          label {
            text-transform: uppercase;
            font-weight: bold;
            font-size: 10px;
            line-height: 12px;
            margin-bottom: 6px;
            display: inline-block;
            letter-spacing: 1px;
          }
          .condition-input {
            width: 100%;
            box-sizing: border-box;
          }

          .datepicker-wrapper {
            .react-datepicker-popper {
              top: 5px;
            }
          }
        }

        .attribute-row {
          width: 320px;
          flex-grow: 0 !important;

          label {
            text-transform: uppercase;
            font-weight: bold;
            font-size: 10px;
            line-height: 12px;
            margin-bottom: 6px;
            display: inline-block;
            letter-spacing: 1px;
          }
        }

        .two-fields-count {
          width: 320px;
          flex-grow: 0 !important;
        }

        .tile-display {
          margin-top: 11px !important;

          .tile-display-toggle {
            display: flex;
            align-items: center;

            .toggle-icon {
              font-size: 16px;
              line-height: 16px;
            }
          }

          .fieldset {
            margin: 0;
          }
        }

        .last-row {
          margin-top: 18px;
          margin-bottom: 0;
        }

        .two-fields {
          display: flex;
          justify-content: space-between;

          .form-row {
            flex-grow: 1;
            margin-bottom: 0;

            &:first-child {
              margin-right: 10px;
            }

            &:last-child {
              margin-left: 10px;
            }
          }
        }

        .two-values-fields {
          .two-fields {
            .text-field {
              width: calc(50% - 10px);
            }
          }

          .int-values-fields {
            display: flex;
            justify-content: space-between;
            width: 100%;

            .text-field {
              width: calc(50% - 10px);

              label,
              input {
                width: 100%;
                box-sizing: border-box;
              }
            }
          }
        }

        .whisperer {
          &.text-field {
            &.error {
              .async_select__control {
                border-color: $color_warning_red;
                box-shadow: 0 1px 3px 0px rgba(237, 56, 42, 0.25);
              }
            }
          }
        }

        .number-slider-field {
          display: flex;
          align-items: center;
          label {
            margin: 0 10px 0 0;
          }
        }
      }

      .preview {
        border-left: 1px solid $color_border;
        flex-shrink: 0;
        width: 46.67%;
        background: $color_grey_secondary_menu;
        border-top-right-radius: $br_medium;
        border-bottom-right-radius: $br_medium;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        > .label {
          font-size: 10px;
          font-weight: bold;
          line-height: 12px;
          letter-spacing: 0.15em;
          text-transform: uppercase;
          color: $color_black;
          margin-bottom: 4px;
        }

        .tile {
          width: 235px;
        }
      }
    }

    &.tiles-section {
      margin-left: -2px;
      margin-right: -2px;

      &.two-cols {
        margin-left: -5px;
        margin-right: -5px;
      }
    }
  }

  .insight-form-color-picker {
    width: calc(100% - 40px);
    border: 1px solid $color_border;
    border-top: 0;
    margin: 0 auto;
    background: $color_grey_secondary_menu;
    box-sizing: border-box;
    border-bottom-left-radius: $br_small;
    border-bottom-right-radius: $br_small;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.05), 0px 3px 6px rgba(0, 0, 0, 0.05) inset;
    display: flex;

    .colors-wrapper {
      max-width: 750px;
    }

    .label-like {
      display: block;
      color: $color_grey;
      font-family: $font_family_inter;
      font-size: 11px;
      letter-spacing: 1px;
      text-transform: uppercase;
      margin: 0 0 5px;
      font-weight: bold;
    }

    .colors-box {
      padding-top: 28px;
      margin: 0 15px 23px 15px;
    }

    .buttons {
      margin-top: 51px;

      .button {
        &.medium {
          height: 40px;
        }
      }
    }
  }

  .admin-segment-analytics-content-wrapper {
    .info-message {
      font-size: 15px;
      line-height: 18px;
      text-align: center;
      color: $color_grey_lighter;
      margin: 26px 0 14px;
    }
  }
}

.sortable-tile-wrapper {
  margin: 10px 4px 0 4px;
  float: left;
  width: calc(20% - 8px);
  display: block;

  .tile-with-buttons {
    position: relative;

    .tile-clickable {
      cursor: pointer;
    }

    .action-buttons {
      position: absolute;
      right: 5px;
      top: 5px;
      width: 32px;
      height: 115px;
      box-sizing: border-box;
      background: $color_grey_secondary_menu;
      border: 1px solid $color_border;
      border-radius: $br_small;
      display: flex;
      justify-content: space-around;
      flex-direction: column;

      &.hidden {
        display: none;
      }

      button {
        min-width: 30px;
        min-height: 30px;
        padding: 6px;

        &:hover {
          opacity: 1;
        }

        &.drag-button {
          cursor: grab;
          padding: 0;

          &.moving {
            cursor: wait;
          }

          .icon {
            padding: 6px 10px;
          }

          &:hover {
            color: $color_black;
          }
        }

        &.edit-button {
          &:hover {
            color: $color_yellow;
          }
        }

        &.trash-button {
          &:hover {
            color: $color_red;
          }
        }
      }
    }
  }
}
