@import "scssShared/variables.scss";

.destination-form {
  .form-row {
    display: flex;
    padding: 30px 20px;
    margin: 0;

    &.grey {
      background: $color_catskill-white;
      border-top: 1px solid $color_border;
      border-bottom: 1px solid $color_border;
    }

    &.last {
      margin-bottom: 0;
      border-bottom: 0;

      &.grey {
        border-bottom-left-radius: $br_small;
        border-bottom-right-radius: $br_small;
      }
    }
  }

  label {
    display: block;
  }

  .left-part {
    width: 220px;
    flex-shrink: 0;

    h2 {
      font-size: 21px;
      letter-spacing: -0.025em;
      margin: 0;
      line-height: 21px;
      margin-top: 29px;
    }
  }

  .right-part {
    display: flex;
    flex-grow: 1;

    &.flex-wrap {
      flex-wrap: wrap;
    }
  }

  .destination-id {
    input {
      width: 200px;
      box-sizing: border-box;
    }
  }

  .destination-name {
    margin-left: 10px;

    input {
      width: 280px;
      box-sizing: border-box;
    }
  }

  .destination-description {
    textarea {
      width: 490px;
      box-sizing: border-box;
    }
  }

  .no-mi-api-set {
    margin: 31px 0 0;
    font-weight: bold;
    color: $color_warning_red;
  }

  .label {
    font-weight: bold;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: $color_grey;
    margin: 0 0 8px;
  }

  .exported-attributes,
  .mandatory-attributes-wrapper {
    width: 100%;
  }

  .exported-attribute-picker,
  .exported-attributes-button-add,
  .mandatory-attribute-picker,
  .mandatory-attributes-button-add {
    width: 130px;
    display: inline-block;
  }

  .exported-attributes-button-add,
  .exported-attribute-picker {
    margin-bottom: 20px;
  }

  .exported-sortable,
  .mandatory-attributes {
    display: flex;
    flex-wrap: wrap;
    float: left;
  }

  .mandatory-attributes-wrapper {
    &:after {
      content: "";
      display: table;
      clear: both;
    }

    .attributes {
      width: 100%;

      &.with-operator {
        width: calc(100% - 100px);
        float: right;
      }
    }
  }

  .destination-mi-workspace {
    width: 320px;
  }

  .attribute {
    margin: 0 5px 5px 0;
    white-space: normal;
    display: flex;
    align-items: center;
    padding: 4px 8px;
    line-height: 12px;
    height: auto;
    min-height: 24px;
    position: relative;

    .eye {
      margin-right: 3px;
    }

    &.clickable {
      &:hover {
        .close {
          top: 50%;
          transform: translateY(-7px);
        }
      }
    }

    &.read-only {
      &:hover {
        box-shadow: none;
      }
    }

    .tag-actions {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: rgba($color_white, 0.8);
      border-radius: $br_small;
      opacity: 0;
      transition: $transition_default;

      button {
        color: $color_grey_lighter;
        padding: 2px;
        min-width: 16px;
        min-height: 16px;
        font-size: 13px;

        &:first-child {
          margin-left: 4px;
        }

        &:last-child {
          margin-right: 4px;
        }
      }
    }

    &.exported-attr {
      &.show-tooltip {
        .tag-actions {
          opacity: 1;
        }
      }
    }
  }

  .add-parameter-button {
    transition: $transition_default, margin-top 0s;
    margin-left: 10px;
    &.mt {
      margin-top: 18px;
    }
  }

  .event-form-schema-item-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 5px;

    &.last {
      margin-bottom: 10px;
    }
  }

  .parameter-item-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 5px;

    &.last {
      margin-bottom: 10px;
    }

    .trash {
      margin-left: 4px;
    }
  }

  .parameter-box {
    background: $color_white;
    border: 1px solid $color_border;
    border-radius: $br_small;
    padding: 10px;

    .row {
      display: flex;
    }

    .parameter-key {
      input {
        width: 280px;
        box-sizing: border-box;
      }
    }

    .parameter-name {
      margin-left: 10px;
      input {
        width: 210px;
        box-sizing: border-box;
      }
    }
  }

  .checkboxes {
    margin-top: 15px;
    .checkbox-field {
      margin-right: 30px;

      &.last {
        margin-right: 0;
      }
    }

    label {
      text-transform: uppercase;
      font-weight: 700;
      font-size: 10px;
      line-height: 16px;
      letter-spacing: 1px;
    }
  }

  .and-or-toggle {
    float: left;
    margin-right: 20px;

    .fieldset {
      margin-bottom: 0;
    }
  }
}
