@import "scssShared/variables.scss";

.filter-attribute-select-wrapper {
  position: relative;

  textarea {
    &:disabled {
      background-color: $color_white;
    }
  }

  .source-bg {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    padding: 3px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 7px;
    left: 7px;

    img {
      width: 100%;
      height: auto;
    }

    @each $name, $color in $colors {
      &.#{$name} {
        background: $color;
      }
    }
  }

  &:hover {
    .filter-attribute-select-input:not(:disabled) {
      border: $border_grey;
    }
  }

  .filter-attribute-select-input {
    border: $border_grey_light_thin;
    border-radius: $br_small;
    transition: $transition_default;
    cursor: pointer;
    resize: none;
    overflow-y: hidden;

    &.focus,
    &:focus {
      outline: none;
      border: $border_primary_thin;
      box-shadow: 0 1px 3px 0px rgba(254, 127, 102, 0.25);
    }

    &:disabled {
      cursor: not-allowed;
      background-color: $color_disabled_field_bg;
      color: $color_disabled_field;
    }
  }

  .filter-attribute-select-input,
  .ghost-field {
    box-sizing: border-box;
    width: 100%;
    font-family: $font_family_inter;
    font-size: 15px;
    line-height: 18px;
    padding: 7px 28px 7px 10px;
    color: $color_black;

    &.clearable {
      padding-right: 54px;
    }

    &.tag-size {
      height: 24px;
      font-size: 11px;
      line-height: 14px;
      padding-top: 4px;
      padding-bottom: 4px;
      white-space: nowrap;
    }

    &.medium-size {
      padding-top: 4px;
      padding-bottom: 4px;
      min-height: 44px;
    }

    &.large-size {
      padding-top: 16px;
      padding-bottom: 15px;
    }
  }

  .filter-attribute-select-input.small-size {
    height: 34px;
  }

  .ghost-field {
    border: 1px solid $color_border;
    min-height: 34px;
    opacity: 0.3;
    display: block;
    white-space: pre-wrap;
    word-wrap: break-word;
    visibility: hidden;
    position: absolute;
    top: 0;
  }

  .filter-attribute-select-button {
    box-sizing: border-box;
    position: absolute;
    height: calc(100% - 20px);
    top: 8px;
    right: 4px;
    border: none;
    border-left: $border_grey_light_thin;
    color: $color_grey_lighter;
    background: transparent;
    font-size: 11px;
    padding: 3px 7px;

    &:focus {
      outline: none;
    }

    &:hover {
      color: $color_grey;
    }

    &.click-through {
      pointer-events: none;
    }

    &:disabled {
      cursor: not-allowed;
    }

    &.tag-size {
      top: 4px;
      height: 16px;
      font-size: 10px;
    }

    &.small-size {
      height: 18px;
    }
  }

  .filter-attribute-clear-button {
    box-sizing: border-box;
    position: absolute;
    height: 20px;
    width: 20px;
    right: 28px;
    top: calc(50% - 12px);
    background: transparent;
    color: $color_grey_lighter;
    border: none;
    padding: 0;
    font-size: 14px;

    &:focus {
      outline: none;
    }

    .cross {
      display: block;
      path {
        transition: $transition_default;
        fill: hsl(0, 0%, 80%);
      }
    }

    &:hover {
      .cross {
        path {
          fill: $color_grey;
        }
      }
    }
  }

  .filter-attribute-select-dropdown {
    box-sizing: border-box;
    background: $color_white;
    border-radius: $br_small;
    border: $border_grey_light_thin;
    position: absolute;
    z-index: 2;
    overflow: hidden;
    margin-top: 5px;
    width: 312px;
    font-family: $font_family_inter;
    color: $color_grey_lighter;
    letter-spacing: 0.025em;

    .menu-header {
      position: sticky;
      top: 0;
      background: $color_grey_secondary_menu;
      padding: 15px 10px;
      border-bottom: $border_grey_light_thin;
      word-break: break-word;
      z-index: 1;

      .menu-header-title {
        font-size: 11px;
        color: $color_primary;
        font-weight: bold;
        cursor: pointer;
        display: flex;

        &.filter-by {
          text-transform: uppercase;
          color: $color_grey;
          justify-content: space-between;
          cursor: default;

          .filter-type-picker-wrapper {
            text-transform: uppercase;
            > span {
              margin-left: 10px;
              cursor: pointer;
              transition: $transition_default;

              &:hover {
                color: $color_primary;
              }

              &.active {
                color: $color_primary;
              }
            }
          }
        }

        .chevron-left {
          margin-right: 5px;
        }
      }
    }

    .scrollable {
      overflow-y: auto;
      max-height: calc(90vh - 200px);
    }

    &.attribute-selecting {
      width: 640px;
    }

    .menu-first-lvl {
      margin: 0;
      padding: 15px;
      display: none;

      &.displayed {
        display: block;
      }

      li {
        list-style-type: none;
        margin-bottom: 10px;
        position: relative;
        padding-right: 15px;
        cursor: pointer;
        transition: $transition_default;
        word-break: break-word;

        &:last-child {
          margin-bottom: 0;
        }

        &:hover {
          color: $color_primary;
        }

        .chevron-right {
          position: absolute;
          right: 0;
        }
      }
    }

    .menu-second-lvl {
      position: relative;
      display: none;

      &.displayed {
        display: block;
      }

      .menu-second-lvl-content {
        margin: 0;
        padding: 15px;
        display: flex;
        justify-content: space-between;

        > ul {
          margin: 0;
          padding: 0;
          width: 280px;
          flex-shrink: 0;

          &.first-col {
            width: 295px;
            border-right: 1px solid $color_border;
          }

          > li {
            list-style-type: none;
            margin-bottom: 12px;
            cursor: pointer;
            transition: $transition_default;
            font-size: 11px;
            font-weight: bold;
            letter-spacing: 0.025em;
            line-height: 14px;
            word-break: break-word;

            &:last-child {
              margin-bottom: 0;
            }

            &:hover,
            &.active {
              color: $color_primary;
            }
          }
        }
      }
    }

    .search-results {
      margin: 0;
      padding: 15px;
      display: none;

      &.displayed {
        display: block;

        .no-results {
          list-style-type: none;
          font-size: 11px;
          font-weight: bold;
        }
      }

      .source-name {
        list-style-type: none;
        margin-bottom: 18px;
        color: $color_border;
        word-break: break-word;

        &:last-child {
          margin-bottom: 0;
        }

        .sublist {
          margin-top: 10px;
          padding: 0;
          margin-left: 10px;

          li {
            margin-bottom: 12px;
            list-style-type: none;
            color: $color_grey_lighter;
            word-break: break-word;
            transition: $transition_default;
            cursor: pointer;
            font-size: 11px;
            line-height: 14px;

            &:last-child {
              margin-bottom: 0;
            }

            &:hover {
              color: $color_primary;
            }
          }
        }
      }
    }

    .source-name {
      font-size: 11px;
      font-weight: bold;
    }

    li {
      &.compound {
        .attrname {
          margin-right: 3px;
        }
      }
    }
  }

  .eye {
    margin-right: 3px;
  }

  &.right {
    .filter-attribute-select-dropdown {
      right: 0;
    }
  }

  &.has-source {
    .filter-attribute-select-input,
    .ghost-field {
      padding-left: 32px;
    }
  }

  &.no-source {
    .filter-attribute-select-input,
    .ghost-field {
      padding-left: 14px;
    }
  }

  &.error {
    .filter-attribute-select-input {
      border-color: $color_warning_red;
    }
    &.is-visible {
      .filter-attribute-select-input {
        animation: animation-shake 1s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
      }
    }
  }

  &.submit-rendered {
    .search-button {
      box-shadow: none;
      position: absolute;

      &.large-size {
        width: 44px;
        height: 44px;
        right: 4px;
        top: 4px;
      }
    }

    .filter-attribute-select-button {
      &.large-size {
        right: 52px;
        border-left: 0px;
      }
    }

    &.large-size {
      .filter-attribute-select-input,
      .ghost-field {
        padding-right: 80px;
      }
    }
  }

  li {
    position: relative;
  }
}

.attribute-examples-tooltip {
  .tippy-content {
    font-weight: bold;
  }

  .compound-tooltip-content {
    span {
      display: block;
      margin-bottom: 5px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
