@import "scssShared/variables.scss";

.container {
  padding: 0 !important; // TODO: refactor Modal
  width: 600px !important;
  box-sizing: border-box;
  max-height: calc(100vh - 150px);
  overflow-y: auto;

  :global(.close-button) {
    top: 0 !important;
    right: 0 !important;
  }

  :global(.modal-body) {
    display: block !important;
  }
}

.stretch {
  flex: 1;
}

.hidden {
  visibility: hidden;
}

.header {
  display: flex;
  align-items: center;
  padding: 25px 25px 15px 25px;
  color: $color_grey;
  font-weight: 700;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  border-bottom: solid 1px $color_border;

  .title {
    display: flex;
    align-items: center;
  }

  .infoIcon {
    margin-left: 5px;
    padding: 2px 5px;
    font-size: 14px;

    &:hover {
      color: $color_grey_lighter;
      transition: all 0.1 ease;
    }
  }

  .closeButton {
    justify-self: flex-end;
    font-size: 16px;
    cursor: pointer;
    padding: 5px;
    margin: -5px;
    margin-bottom: -7px; // optically align

    &:hover {
      color: $color_grey_lighter;
      transition: all 0.1 ease;
    }
  }
}

.body {
  background-color: $color_catskill-white;
  padding: 25px;
  border-bottom: solid 1px $color_border;

  :global(.react-select-redux-field__menu) {
    max-height: 105px;
    overflow-y: auto;
  }
}

.userRow {
  display: flex;
  align-items: center;

  &:not(:first-child) {
    margin-top: 10px;
  }

  .userName {
    font-weight: 700;
    margin: 0 15px;
  }

  .authorTogglePlaceholder {
    box-sizing: border-box;
    border-radius: 20px;
    height: 34px;
    border: $border_grey_light_thin;
    background: $color_border;
    box-shadow: $toggle_inset_box_shadow;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    text-transform: uppercase;
    font-weight: 500;
    color: $color_grey_lighter;
    width: 112px;
  }

  & p:global(.fieldset) {
    margin: 0; // TODO: this shouldn't be necessary
  }

  .removeButtonWrapper {
    margin-left: 15px;
  }

  .inviteeSelect {
    width: 320px;
    min-width: unset;
  }
}

.inviteButtonsWrapper {
  margin-top: 15px;
}

.addButton {
  color: $color_primary;
  font-size: 12px;
  font-family: $font_family_neue;
  font-weight: 500;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  padding: 5px;
  border: none;
  outline: none;
  background-color: $color_catskill-white;
  margin-right: 10px;

  &:hover {
    text-decoration: underline;
  }

  &.secondary {
    color: $color_grey;
  }

  .icon {
    margin-right: 5px;
  }
}

.footer {
  display: flex;
  padding: 25px 25px 25px 25px;

  .saveButton.spinner {
    color: $color_white;
    width: 14px;
    height: 14px;
  }
}
