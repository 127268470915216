$modal-gap: 100px;
$modal-height: calc(100vh - 2 * #{$modal-gap});
$max-modal-height: 600px;
$factor: 1.6;

.galleryModal {
  width: unset !important;
  margin: auto;
  padding: 15px !important;

  .galleryModalContent {
    box-sizing: border-box;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
    height: $modal-height;
    max-height: $max-modal-height;
    width: calc(#{$modal-height} * #{$factor});
    max-width: calc(#{$max-modal-height} * #{$factor});
    overflow: auto;
    padding: 5px;

    @media (max-height: 700px) {
      & {
        padding: 0;
        gap: 5px;
      }
    }
  }

  .actionButtons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
  }
}
