@import "scssShared/mixins.scss";

.colorInputWrapper {
  label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    > span {
      @include input-label-styles;
      margin: 0;
    }
  }
}

.colorInputBox {
  height: 34px;
  border: 1px solid $color_border;
  border-radius: $br_large;
  display: flex;
  align-items: center;
  padding: 0 8px;
  min-width: 118px;
  box-sizing: border-box;

  &.disabled {
    background-color: $color_border;
  }
}

.colorInputValue {
  font-size: 14px;
  color: $color_black;
  margin-left: 10px;
  display: block;
  text-transform: uppercase;
}

.colorInputDesignWrapper {
  width: 18px;
  height: 18px;
  border: 1px solid $color_border;
  overflow: hidden;
  border-radius: $br_small;
  margin: 10px 0 0;

  .colorInput {
    margin-left: -7px;
    margin-top: -7px;
    height: 32px;
  }
}
