@import "scssShared/variables.scss";
@import "scssShared/flexbox.scss";

.attributes-container {
  .paper-header {
    position: relative;

    .search-form-with-select {
      position: absolute;
      left: 50%;
      margin-left: -240px;
    }
  }

  .attributes-table {
    table-layout: fixed;

    .labels-info-tooltip {
      margin-left: 5px;
    }

    .name {
      width: 18%;
    }

    .source-col {
      width: 20%;
    }

    .labels-col {
      width: 18%;
    }

    .description-col {
      width: 18%;
    }

    .attribute-source,
    .attribute-tags,
    .attribute-examples {
      font-weight: normal;
      font-size: 14px;
    }
    .attribute-name {
      .badge {
        margin-left: 4px;
      }

      .sub-attrs {
        margin: 5px 0 0;
        padding: 0;

        li {
          list-style-type: none;
          font-size: 12px;
          line-height: 15px;
          color: $color_grey_lighter;
          font-weight: normal;
          margin-bottom: 2px;
          position: relative;
          padding-left: 12px;

          &:before {
            content: "";
            display: block;
            width: 8px;
            height: 1px;
            background: $color_border;
            position: absolute;
            left: 0;
            top: 50%;
            margin-top: -2px;
          }

          &:last-child {
            margin-bottom: 0px;
          }
        }
      }
    }
    .attribute-description {
      white-space: pre-line;
    }
    .attribute-examples {
      padding: 7.5px 0px 7.5px 5px;

      .examples-wrapper {
        display: flex;
        flex-wrap: wrap;
      }

      .attribute-example-value {
        margin: 2.5px 5px 2.5px 0;
        white-space: normal;
        display: flex;
        align-items: center;
        padding: 4px 8px;
        line-height: 12px;
        height: auto;
        min-height: 24px;
        word-break: break-word;
      }
    }
    .attribute-source {
      .attribute-source-flex-wrapper {
        @include flexAcross();
        align-items: center;
        img {
          width: 35px;
          padding-right: 12px;
        }
        .source-n-count {
          @include flexDown();
          .count {
            font-size: 12px;
            color: $color_grey;
          }
        }
      }
    }

    th,
    td {
      padding: 10px 5px;
    }

    .attribute-tags {
      line-height: 35px;
    }
  }
  .attribute-label {
    cursor: pointer;
    white-space: normal;
    min-height: 24px;
    height: auto;
    line-height: 12px;
    padding-top: 6px;
    padding-bottom: 6px;
    margin: 0 5px 5px 0;
  }

  .no-attributes-found {
    padding-top: 15px;
    padding-bottom: 5px;
  }
}
