@import "scssShared/variables.scss";

.detail-source {
  .detail-source-header {
    justify-content: space-between;

    .header-buttons {
      .button {
        margin-left: 10px;
      }
    }
  }

  .detail-source-content {
    padding: 0;
  }
}
