@import "scssShared/variables.scss";
@import "scssShared/flexbox.scss";

.header-container {
  height: 100px;
  margin-bottom: 40px;
  transition: padding-top 0.2s ease-out;

  a.is-active {
    position: relative;
  }

  &.loading-bar-visible {
    padding-top: 46px;
  }

  .logo-wrapper {
    flex-shrink: 0;
  }

  .logo {
    text-decoration: none;
    display: flex;
    align-items: center;

    &:after {
      clear: both;
      content: "";
      display: table;
    }

    > img {
      height: 48px;
      padding: 9px 0;
      float: left;
    }

    .client-logo {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background: $color_white;
      position: absolute;
      padding: 8px;
      box-sizing: border-box;
      top: 17px;
      left: 42px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;

      > img {
        width: 100%;
        height: auto;

        &.dummy {
          width: 80%;
          margin-left: 2px;
        }
      }
    }

    .product-name {
      float: left;
      font-size: 9px;
      font-family: $font_family_inter;
      text-transform: uppercase;
      color: $color_white;
      line-height: 11px;
      max-width: 95px;
      margin: 0 0 0 40px;

      span {
        display: block;

        &.strong {
          font-weight: bold;
        }
      }
    }
  }

  .user-menu {
    img {
      width: 48px;
      height: 48px;
      border-radius: 50%;
    }
  }

  .primary {
    background: linear-gradient(270deg, #fe7f66 0%, #ff5c3c 100%);

    .wrapper {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      max-height: 80px;
    }
  }

  .secondary {
    position: relative;
    height: 34px;
    background-color: $color_grey_secondary_menu;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
  }

  .primary-nav,
  .secondary-nav {
    a,
    span {
      text-transform: uppercase;
      text-decoration: none;
      font-family: $font_family_neue;
    }

    a.flex-link {
      display: inline-flex;
      align-items: center;

      .header-badge {
        background-color: rgba($color_black, 0.8);
        color: $color_white;
        font-size: 8px;
        line-height: 1.1;
        padding: 4px;
        border-radius: $br_small;
        margin-left: 5px;
        white-space: pre-line;
        letter-spacing: 0.05em;
      }
    }
  }

  .primary-nav {
    display: flex;
    justify-content: space-evenly;
    flex: 1;

    a {
      font-size: 13px;
      line-height: 32px;
      letter-spacing: 0.1em;
      margin: 10px 0;
      font-weight: bold;
      position: relative;
      color: $color_white;

      .menu-item {
        display: flex;
        opacity: 0.6;
        transition: $transition_default;
      }

      .inline-icon {
        font-size: 12px;
        width: 30px;
        height: 30px;
        box-sizing: border-box;
        display: block;
        border: 2px solid $color_link_header;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 5px;
      }

      &.is-active,
      &:hover {
        .menu-item {
          opacity: 1;
        }

        .inline-icon {
          border: 2px solid $color_white;
          background: $color_white;
          color: $color_primary;
        }
      }

      &.is-active {
        &::after {
          display: block;
          content: "";
          width: 16px;
          height: 16px;
          background-color: $color_grey_secondary_menu;
          position: absolute;
          transform: translateX(-50%) rotate(45deg);
          z-index: 1;
          pointer-events: none;
          left: 50%;
          top: 41px;
        }
      }

      &[disabled] {
        .menu-item {
          opacity: 0.3;
        }

        .inline-icon {
          border-style: dashed;
        }

        &:hover {
          cursor: not-allowed;

          .inline-icon {
            border: 2px dashed $color_link_header;
            color: $color_white;
            background: transparent;
          }
        }
      }

      &.personalization-menu-item {
        position: relative;

        .new-badge {
          opacity: 1;
          position: absolute;
          color: $color_white;
          background-color: $color_black;
          font-family: $font_family_neue;
          font-weight: 700;
          font-size: 10px;
          text-transform: uppercase;
          line-height: 1;
          padding: 5px 4px 3px 4px;
          border-radius: 6px;
          right: -5px;
          top: -12px;
        }
      }
    }
  }

  .secondary-nav {
    text-align: center;
    font-size: 12px;
    line-height: 14px;
    font-weight: 500;

    .middot {
      display: inline-block;
      position: relative;
      width: 5px;
      height: 12px;
      span {
        color: $color_grey;
        font-size: 16px;
        position: absolute;
        top: 2px;
        left: 0;
        font-family: $font_family_inter;
        margin: 0;
      }
    }

    a,
    .link-like {
      font-size: 12px;
      margin: 0px 12px;
      letter-spacing: 2px;
      color: $color_grey;
      display: inline-block;

      &.is-active {
        color: $color_primary;
      }

      &:hover {
        color: $color_primary;
      }

      &[disabled] {
        opacity: 0.25;
        text-decoration: line-through;
        &:hover {
          color: $color_grey;
          cursor: not-allowed;
        }
      }
    }

    .segment-icon {
      margin-right: 8px;
      font-size: 14px;
    }

    .chevron-icon {
      color: $color_grey;
    }

    .export-name {
      margin: 0px 0px 0px 12px;
    }
  }

  .user-menu-wrapper {
    display: flex;
    align-items: center;

    & > * {
      margin-left: 10px;
    }

    a {
      position: relative;

      .icon {
        display: flex;
        color: $color_white;
        opacity: 0.6;
        font-size: 16px;
        padding: 3px;
        transition: opacity 0.1s;
      }

      &.is-active,
      &:hover {
        .icon {
          opacity: 1;
        }
      }

      &.is-active {
        &::after {
          display: block;
          content: "";
          width: 16px;
          height: 16px;
          background-color: $color_grey_secondary_menu;
          position: absolute;
          transform: translateX(-50%) rotate(45deg);
          z-index: 1;
          pointer-events: none;
          left: 10px;
          top: 36px;
        }
      }
    }

    .gravatar-image {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: 2px solid $color_white;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
      background: $color_white;
      box-sizing: border-box;
      float: left;
    }

    .user-menu-toggle-button {
      height: 40px;
      line-height: 40px;
      box-sizing: border-box;
      font-size: 16px;
      color: $color_white;
    }

    .dropdown-caret {
      margin-left: 5px;
    }

    .dropdown-menu .dropdown-content .user-menu {
      text-align: left;

      .logged-in-as {
        border-bottom: $border_grey_light_thin;
        margin: -15px -15px 10px -15px;
        padding: 10px 15px;

        .logged-in-as-heading {
          font-size: 13px;
          color: $color_grey;
          margin-bottom: 5px;
        }

        .logged-in-as-content {
          .name {
            font-weight: 700;
            font-size: 16px;
          }

          .email {
            color: $color_black_lighter;
            line-height: 1.1;
            font-size: 14px;
          }
        }
      }

      a {
        display: flex;
      }

      a,
      span,
      .icon {
        color: $color_grey_lighter;
        opacity: 1;

        &:hover {
          color: $color_primary;
        }
      }

      .icon {
        width: 20px;
        text-align: center;
        margin-right: 5px;
      }

      .instance-info {
        background: $color_grey_secondary_menu;
        border-top: $border_grey_light_thin;
        margin: 10px -15px -15px;
        padding: 10px;

        span {
          color: $color_grey;
          font-size: 11px;
          line-height: 13px;
          text-transform: uppercase;
          letter-spacing: 0.5px;
          cursor: default;
          &:hover {
            color: $color_grey;
          }

          &.location-value,
          &.version-value,
          &.role-value {
            font-weight: bold;
          }
        }

        .location-info,
        .version-info,
        .role-info {
          border: $border_grey_light_thin;
          background: $color_white;
          width: 100%;
          text-align: center;
          border-radius: $br_small;
          padding: 6px;
          box-sizing: border-box;

          span {
            display: block;
          }
        }

        .location-info,
        .role-info {
          margin-bottom: 10px;
        }

        .version-info {
          display: flex;
          justify-content: center;
          align-items: center;

          .version-value {
            margin-left: 4px;
          }
        }
      }
    }

    .fetching-logs {
      &:hover {
        cursor: wait;
      }
    }
  }
}

.disabledMenuTooltip {
  width: 245px;
  .tippy-content {
    font-weight: bold;
  }
}
