@import "scssShared/variables.scss";

.modal {
  &.expanded-insight-tile {
    width: 720px;
    min-height: 360px;
    padding: 5px 5px 0;
    display: flex;
    flex-direction: column;

    .tile-tooltip {
      font-weight: normal;
      font-size: 12px;
      line-height: 15px;
      max-width: 395px;
      padding: 10px;
      letter-spacing: normal;
      overflow-wrap: break-word;
    }

    .description-icon {
      margin-left: 10px;
    }

    header {
      margin-left: 5px;
      margin-top: 5px;
      min-height: 35px;
      h3 {
        margin: 0;
        text-transform: none;

        .tile-title {
          display: flex;
          align-items: center;

          img {
            width: 28px;
            height: 28px;
            margin-right: 12px;
          }

          .name-wrapper {
            font-size: 14px;
            font-weight: bold;
            line-height: 17px;
            letter-spacing: -0.025em;
            display: flex;
            align-items: center;
          }

          .new {
            margin-left: 6px;
            font-size: 9px;
            text-transform: uppercase;
            font-weight: bold;
            color: $color_primary;
            height: 16px;
            width: 30px;
            letter-spacing: 0.05em;
            border: 1px solid $color_primary;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: $br_small;
            box-sizing: border-box;
          }

          @each $name, $color in $colors {
            &.#{$name} {
              .name-wrapper {
                color: $color;
              }
            }
          }
        }
      }

      .close-button {
        top: -5px;
        right: 0px;
        button {
          width: 40px;
          height: 40px;
          border: 1px solid $color_primary;
          background: rgba($color_primary, 0.25);
          color: $color_primary;
          border-radius: $br_small $br_medium $br_small $br_small;
          padding: 0;
          font-size: 16px;
          line-height: 16px;

          &:hover {
            background: rgba($color_primary, 1);
            color: $color_white;
            opacity: 1;
          }
        }
      }
    }

    .expanded-insight-content {
      min-height: 240px;
      margin: 15px 25px;
      display: flex;
      align-items: stretch;

      .delimiter {
        height: 1px;
        background: #cccccc;
        border: 0;
        width: 460px;
        margin: 4px 0 2px;
      }

      .chart-margin {
        margin-top: 10px;
      }

      .chart-list-item {
        margin: 5px 0px;
      }

      .attribute-percentage-chart-part {
        flex-shrink: 0;
        width: 140px;
        padding-right: 20px;
        border-right: 1px solid $color_border;
        display: flex;
        align-items: center;
      }

      .values-part {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-grow: 1;
        flex-direction: column;

        .no-sufficient-data {
          font-size: 16px;
          line-height: 135%;
          color: $color_black;
          letter-spacing: -0.025em;
          text-align: center;
          margin: 0 15px;
          font-weight: bold;
        }

        .func-name {
          margin: 0 0 5px;
          font-size: 18px;
          line-height: 22px;
          color: $color_black;
          letter-spacing: -0.025em;
        }

        .fs-56 {
          font-size: 56px;
          line-height: 68px;
        }

        .fs-48 {
          font-size: 48px;
          line-height: 58px;
        }

        .fs-40 {
          font-size: 40px;
          line-height: 48px;
        }

        .fs-32 {
          font-size: 32px;
          line-height: 38px;
        }

        .fs-26 {
          font-size: 26px;
          line-height: 31px;
        }

        .fs-22 {
          font-size: 22px;
          line-height: 26px;
        }

        .fs-18 {
          font-size: 18px;
          line-height: 21px;
        }

        .fs-14 {
          font-size: 14px;
          line-height: 17px;
        }

        .shortening {
          color: $color_grey;
          opacity: 0.75;
        }

        .value {
          margin: 0;
          text-align: center;
          font-weight: bold;
          padding: 0 5px;
          box-sizing: border-box;
          letter-spacing: -0.025em;
          color: $color_black;
          font-feature-settings: "ss01" on;
        }

        .unique-values,
        .common-values {
          letter-spacing: -0.025em;
          text-align: center;
          font-weight: bold;
          margin: 1px 0 0;
          padding: 0 5px;
          font-feature-settings: "ss01" on;
        }

        .out-of {
          color: $color_grey;
          letter-spacing: -0.025em;
          font-size: 24px;
          line-height: 29px;
          text-align: center;
          margin: 0;
          font-weight: normal;
        }

        .of-all {
          color: $color_grey;
          letter-spacing: -0.025;
          font-size: 14px;
          line-height: 17px;
          text-align: center;
          margin: 0;
        }
      }
    }
  }

  .attribute-info {
    height: 50px;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: -0.025em;
    text-align: center;
    padding: 0 30px;
    color: $color_grey_lighter;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    p {
      margin: 0;
    }

    .attribute-percentage {
      opacity: 0.6;
    }

    @each $name, $color in $colors {
      .#{$name} {
        color: $color;
        font-weight: bold;
      }
    }

    .black {
      font-weight: bold;
      color: $color_black;
    }
  }

  .modal-body {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
