@import "scssShared/variables.scss";

.form {
  width: 320px;
  padding: 24px 20px;
  box-sizing: border-box;
  background-color: $color_white;
  border-radius: $br_small;
  box-shadow: $bs_large;
}

.formTitle {
  font-size: 28px;
  line-height: 33px;
  margin: 0 0 24px;
  text-align: center;
}

.recaptcha {
  iframe {
    transform: scale(0.925);
    transform-origin: 0 0;
  }
}

.underformLink {
  display: table;
  text-align: center;
  margin: 15px auto 0;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.15em;
}
