@import "scssShared/variables.scss";

.container {
  margin-left: 30px;
  display: flex; // When it's not flex the dnd-kit position detection is off. TODO: ??? investigate
  flex-direction: column;

  &.isTopLevel {
    margin-left: 0;
  }
}

.insertBar {
  height: 4px;
  border-radius: 4px;
  background-color: transparent;
  transition: all 0.15s ease;

  &.top {
    margin: 6px 0 3px 0;
  }

  &.bottom {
    margin: 3px 0 6px 0;
  }

  &.visible {
    background-color: $color_primary;
  }
}
