@import "scssShared/variables.scss";
@import "scssShared/flexbox.scss";

.body {
  @include flexDown();
  align-items: center;
  background-color: $color_white;
  border-radius: 4px;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.1);
}

.title {
  margin: 32px 0 0 0;
}

.invitedPerson {
  margin: 0;
  text-align: center;
}

.form {
  width: 320px;
  padding: 24px 20px 10px 20px;
  margin-bottom: 0;
  box-sizing: border-box;
  background-color: $color_white;
  border-radius: 0;
  box-shadow: none;
}

.repeatPassword {
  margin-bottom: 10px;
}

.submitButton {
  margin-bottom: 32px;
  width: calc(100% - 20px - 20px);
}
