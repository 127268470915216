@import "scssShared/variables.scss";
@import "scssShared/animations.scss";

@mixin color-styles($color) {
  background: $color;
  border: solid 1px $color;
  color: $color_white;
}

button {
  cursor: pointer;
}

.button {
  font-family: $font_family_inter;
  text-transform: uppercase;
  border-radius: $br_small;
  font-weight: 500;
  padding: 6px 12px 5px;
  transition: $transition_default;
  line-height: normal;
  border: 0;

  .icon {
    margin-right: 4px;
  }

  &:focus {
    outline: none;
    box-shadow: $button_white_focus_box_shadow;
  }

  &:hover {
    box-shadow: $bs_large;
  }

  &:disabled {
    opacity: 0.5;

    &:hover {
      box-shadow: none;
      cursor: not-allowed;
    }
  }

  &.full-width {
    width: 100%;
  }

  &.large {
    height: 52px;
    font-size: 13px;
    letter-spacing: 0.15em;
    padding-left: 20px;
    padding-right: 20px;
  }

  &.big,
  &.medium {
    height: 44px;
    font-size: 13px;
    letter-spacing: 0.15em;
  }

  &.small {
    height: 34px;
    font-size: 11px;
    letter-spacing: 0.15em;

    &.loading {
      position: relative;
      cursor: wait;

      &:before {
        width: 18px;
        height: 18px;
        margin: -11px 0 0 -11px;
      }
    }
  }

  &.tag {
    padding: 0 8px;
    height: 24px;

    &.loading {
      position: relative;
      cursor: wait;

      &:before {
        width: 14px;
        height: 14px;
        margin: -9px 0 0 -11px;
      }
    }
  }

  @each $name, $color in $colors {
    &.#{$name} {
      @include color-styles($color);
    }
  }

  &.waiting {
    @include color-styles($color_yellow_waiting);
  }

  &.running {
    @include color-styles($color_blue_running);
  }

  &.red {
    @include color-styles($color_warning_red);
  }

  &.transparent-grey {
    border: none;
    color: $color_grey;
    background: transparent;

    &:hover {
      text-decoration: underline;
      box-shadow: none;
    }
  }

  &.primary {
    background: linear-gradient(
      to right,
      $color_primary_lighter 0%,
      $color_primary 50%,
      $color_primary_darker 100%
    );
    background-size: 200%;
    background-position: -1px 0%;
    color: $color_white;
    border: 1px solid transparent;

    &:hover {
      background-position: 50% 0%;
      box-shadow: $bs_large;
    }

    &:disabled {
      border: $border_grey_light_thin;
      background: $color_border;
      color: $color_white;
      cursor: not-allowed;
      box-shadow: none;
    }

    &:focus {
      outline: none;
      box-shadow: $button_primary_focus_box_shadow;
    }
  }

  &.loading {
    position: relative;
    cursor: wait;

    &:before {
      margin: -13px 0 0 -13px;
      width: 24px;
      height: 24px;
      position: absolute;
      left: 50%;
      top: 50%;
      content: "";
      border-radius: 24px;
      background-clip: padding-box;
      border-color: rgba($color_white, 0.25);
      border-width: 2px;
      border-style: solid;
      border-top-color: $color_white;
      animation: animation-rotate 975ms cubic-bezier(0.25, 0.35, 0.5, 0.95) infinite;
    }

    span {
      color: transparent;
    }
  }

  &.none {
    background: transparent;
    border: none;
    color: $color_grey;
  }

  &.link,
  &.link-primary,
  &.link-red {
    background: transparent;
    color: $color_grey;
    text-decoration: underline;
    border: none;
    padding: 2px;
    box-shadow: none;

    &:hover {
      text-decoration: none;
    }

    &:focus {
      outline: none;
      box-shadow: none;
    }

    &.loading {
      text-decoration: none;

      &:before {
        border-color: rgba($color_primary, 0.25);
        border-top-color: $color_primary;
      }
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &.link-primary {
    color: $color_primary;
  }

  &.link-red {
    color: $color_warning_red;
    text-decoration: none;
  }

  &.white,
  &.white-red {
    background: $color_white;
    border: $border_grey_light_thin;
    color: $color_grey_lighter;

    &:hover {
      color: $color_black;
      border-color: $color_grey;
      box-shadow: $bs_large;
    }

    &:focus {
      outline: none;
      box-shadow: $button_white_focus_box_shadow;
    }

    &:disabled {
      color: $color_border;
      cursor: not-allowed;

      &:hover {
        border: $border_grey_light_thin;
        color: $color_border;
        box-shadow: none;
      }
    }

    &.loading {
      &:before {
        border-color: rgba($color_primary, 0.25);
        border-top-color: $color_primary;
      }
    }

    &.turned-off {
      color: $color_border;

      &:hover {
        color: $color_black;
      }

      &:disabled {
        color: $color_border;
      }
    }

    &.turned-on {
      color: $color_white;
      &:hover {
        opacity: 0.7;
        box-shadow: none;
      }

      &:focus {
        box-shadow: none;
      }

      &:disabled {
        color: $color_white;
        opacity: 1;
      }
    }

    @each $name, $color in $colors {
      &.turned-on-#{$name} {
        background: $color;
        border-color: $color;

        &:disabled {
          border-color: $color;
        }
      }
    }
  }

  &.white-red {
    color: $color_warning_red;
    border-color: rgba($color_warning_red, 0.5);

    &:hover {
      color: $color_warning_red;
      border-color: rgba($color_warning_red, 1);
    }

    &:disabled {
      color: rgba($color_warning_red, 0.3);

      &:hover {
        border-color: rgba($color_warning_red, 0.5);
        color: rgba($color_warning_red, 0.3);
      }
    }
  }

  &.grey {
    background: rgba($color_grey, 0.25);
    color: $color_grey;
    border: 1px solid rgba($color_grey, 0.5);

    &:hover {
      box-shadow: $bs_large;
    }

    &:focus {
      outline: none;
    }

    &:disabled {
      opacity: 0.5;

      &:hover {
        box-shadow: none;
        cursor: not-allowed;
      }
    }
  }
}
