@import "scssShared/variables.scss";

.customer-detail-header {
  .customer-detail-header-row1 {
    display: flex;
  }

  .above-paper-left {
    button.back-button {
      margin-left: -8px;
      margin-top: -8px;
      color: $color_grey_lighter;
      opacity: 1;
      padding: 8px;

      &:hover {
        color: $color_black;
      }
    }
  }

  .above-paper-right {
    & > *:not(:first-child) {
      margin-left: 10px;
    }

    .right-button .icon {
      margin: 0 0 0 4px;
    }
  }

  .basic-info {
    margin-right: 20px;
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
    width: calc(100% - 480px);

    .basic-info-title {
      font-size: 13px;
      margin: 20px 0 0 8px;
      text-transform: uppercase;
      color: $color_grey;
      letter-spacing: 1px;
      font-weight: bold;
    }

    .customer-title-avatar {
      display: flex;
      align-items: center;
      margin-top: -20px;
      margin-left: 5px;

      .customer-avatar {
        width: 80px;
        height: 80px;
        margin-right: 20px;
        border-radius: $br_medium;
        border: $border_grey_light_thin;
        background-color: $color_bg_customer_avatar;
        box-sizing: border-box;
        padding: 12px;
      }

      h2 {
        color: $color_primary;
        margin: 0;
        font-size: 16px;
      }
    }

    .customer-attributes-values-list {
      .row {
        padding: 0 10px;
      }
    }
  }

  .chart-box {
    width: 460px;
    padding: 20px 0px;
    flex-shrink: 0;
    position: relative;

    h3 {
      font-size: 13px;
      color: $color_grey;
      text-transform: uppercase;
      letter-spacing: 1px;
      margin: 0 20px;
      font-weight: bold;
      letter-spacing: 1px;
    }

    .info-icon {
      margin-right: 3px;
    }

    .info-tooltip {
      width: 320px;
    }

    .chart-wrapper {
      width: 440px;
      margin: 35px 10px 0;

      .chart-na {
        text-align: center;
        font-size: 14px;
        font-weight: bold;
        margin: 0;

        a {
          font-size: 14px;
          font-weight: bold;
          color: $color_primary;
          text-transform: none;
        }
      }
    }
  }
}
