@import "scssShared/variables.scss";

.app {
  box-sizing: border-box;
  min-width: 1200px;

  .invalid-browser-message {
    background: #f9b549;

    .wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: $color_black;
      height: 50px;

      .left-part {
        display: flex;
        align-items: center;

        .exclamation-icon {
          font-size: 20px;
          display: block;
          margin-right: 15px;
        }

        p {
          font-size: 14px;
          display: block;

          a {
            text-transform: none;
            color: $color_black;
            font-weight: bold;
            font-size: 14px;
          }
        }
      }

      .right-part {
        display: flex;
        align-items: center;
        .warning-close {
          line-height: normal;
          text-transform: uppercase;
          cursor: pointer;
          letter-spacing: 1.5px;
          font-size: 11px;
          border: 1px solid $color_black;
          border-radius: $br_medium;
          padding: 10px 9px 9px;
          transition: $transition_default;
          text-decoration: none;
          color: $color_black;
          flex-shrink: 0;
          font-weight: 500;

          &:hover {
            color: $color_black;
            background: rgba(255, 255, 255, 0.15);
          }

          &:active,
          &:focus {
            color: $color_black;
            background: rgba(255, 255, 255, 0.25);
          }
        }
      }
    }
  }

  .custom-toastify {
    box-sizing: border-box;
    width: auto;

    &.Toastify__toast-container--top-right {
      top: 80px;
    }

    .toast {
      opacity: 0.85;
      margin-left: auto;
      margin-right: auto;
      width: 380px;
      border-radius: 8px;
      padding: 12px 20px;
      min-height: 60px;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.05);
      font-family: $font_family_inter;
      font-size: 12px;
      line-height: 125%;
      font-weight: 400;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      cursor: default;

      .toast-body {
        flex-basis: 310px;
        box-sizing: border-box;
        word-wrap: break-word;
        display: flex;
        flex-shrink: 0;
        flex-grow: 0;
        align-items: center;
      }

      .toast-text {
        max-width: 310px;

        a {
          text-transform: none;
          color: $color_white;
          font-size: 14px;

          &:hover {
            color: $color_white;
            text-decoration: none;
          }
        }
      }

      .link {
        font-family: $font_family_inter;
        display: table;
        text-transform: uppercase !important;
        cursor: pointer;
        letter-spacing: 0.15em;
        font-size: 10px !important;
        line-height: 10px;
        border-radius: $br_small;
        transition: $transition_default;
        text-decoration: none;
        flex-shrink: 0;
        white-space: pre;
        font-weight: 500;
        padding: 4px 6px;
        margin-top: 4px;
      }

      .buttons {
        text-align: center;
      }

      .toast-icon {
        font-size: 24px;
        margin-right: 16px;
      }

      .toast-close {
        cursor: pointer;
        font-size: 14px;
        padding: 4px;
        margin-right: -4px;

        &:hover {
          opacity: 0.6;
        }
      }

      &.toast-error {
        background: #fdf4f3;
        color: #ed382a;
        border: 1px solid #ed382a;

        .toast-close {
          color: #ed382a;
        }

        .link {
          color: #ed382a;
          border: 1px solid #ed382a;

          &:hover,
          &:active,
          &:focus {
            color: $color_white;
            background: #ed382a;
          }
        }
      }

      &.toast-success {
        background: #f4f9f5;
        color: $color_green;
        border: 1px solid $color_green;

        .toast-close {
          color: $color_green;
        }

        .link {
          color: $color_green;
          border: 1px solid $color_green;

          &:hover,
          &:active,
          &:focus {
            color: $color_white;
            background: $color_green;
          }
        }
      }

      &.toast-info {
        background: $color_white;
        color: $color_black;
        border: 1px solid $color_border;

        .toast-close {
          color: $color_black;
        }

        .link {
          color: $color_primary;
          border: 1px solid $color_primary;

          &:hover,
          &:active,
          &:focus {
            color: $color_white;
            background: $color_primary;
          }
        }
      }

      &.wide {
        width: 440px;

        .toast-body {
          flex-basis: 355px;
        }

        .toast-text {
          max-width: 355px;
        }
      }
    }
  }

  .above-paper {
    position: absolute;
    top: -44px;
    right: 0;
  }

  .above-paper-left {
    position: absolute;
    top: -38px;
    left: 0;
  }

  .above-paper,
  .above-paper-left {
    .back-button {
      font-size: 20px;
      transition: $transition_default;
      margin-left: 20px;
      color: $color_grey;

      &:hover {
        color: $color_black;
      }
    }
  }
}

.toastify-on-top {
  .custom-toastify {
    &.Toastify__toast-container--top-right {
      top: 1em;
    }
  }
}
