@import "scssShared/animations.scss";
@import "scssShared/fonts.scss";
@import "scssShared/form.scss";
@import "scssShared/variables.scss";
@import "scssShared/table.scss";
@import "scssShared/dateTimePicker.scss";
@import "scssShared/chartsCommon.scss";

html {
  color: $color_black;
  font-size: 1em;
  margin: 0;
  height: 100%;
  background-color: $color_grey_site_background;
}

body {
  padding: 0;
  font-family: $font_family_inter;
  font-size: 100%;
  overflow-y: auto;
}

@supports (overflow-y: overlay) and (scrollbar-gutter: auto) {
  body {
    overflow-y: overlay;
    scrollbar-gutter: auto;
  }
}

body,
#root,
.app {
  height: 100%;
  margin: 0;
  background-color: $color_grey_site_background;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font_family_neue;
  font-weight: 700;
  color: $color_black;
}

button,
a {
  &:focus {
    outline: none;
  }
}

a {
  cursor: pointer;
  transition: $transition_default;
  font-size: 11px;
  text-transform: uppercase;
  color: $color_grey_lighter;
  text-decoration: underline;
  outline: none;
  transition: text-decoration-color 0.08s ease;

  &:hover {
    text-decoration-color: transparent;
  }
}

.wrapper {
  width: 1200px;
  margin: 0 auto;
  position: relative;
}

.tippy-box[data-placement^="bottom-start"] {
  .tippy-arrow {
    transform: translate3d(6px, 0px, 0px) !important;
  }
}

.tippy-box {
  .tippy-content {
    font-size: 12px;
    line-height: 14px;
    color: $color_white;
    padding: 10px;
    font-family: $font_family_inter;
    text-transform: none;
    font-weight: normal;
    letter-spacing: normal;

    p {
      margin: 0 0 6px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    a {
      text-transform: none;
      font-size: 12px;
      color: $color_primary;
    }
  }
}
