@import "scssShared/variables.scss";

.setup-identity-stitching {
  .info-message {
    margin: 20px 10px;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
  }

  .setup-identity-stitching-header {
    justify-content: space-between;
  }

  .left-margin {
    margin-left: 5px;
  }

  .setup-stitching-table {
    .actions {
      width: 100px;
    }
    .attribute-rules {
      width: 40%;
    }
  }
}
