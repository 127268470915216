@import "scssShared/variables.scss";

.container {
  display: flex;
}

.indentButton,
.unindentButton {
  outline: none;
  border-radius: 50%;
  border: 1px solid $color_border;
  color: $color_black_lighter;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  padding: 0;

  &:disabled {
    color: $color_very-light-grey;
  }
}

.unindentButton {
  padding-left: 2px; // center optically
}

.toggleWrapper {
  margin: 0 5px;
}
