@import "scssShared/variables.scss";

.container {
  display: flex;
  justify-content: space-around;
  padding: 0;
  margin-top: -10px;

  .userCountItem {
    padding: 10px 20px;
    margin: 10px 0;
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: space-between;
    flex: 1;

    &:not(:first-child) {
      border-left: $border_grey_light_thin;
    }

    .label {
      color: $color_grey;
      text-transform: uppercase;
      font-size: 10px;
      font-weight: 700;
      letter-spacing: 0.1em;
      display: flex;
      align-items: center;

      .info {
        margin-left: 5px;
        font-size: 14px;
        color: $color_grey_lighter;
      }
    }

    .value {
      font-family: $font_family_neue;
      font-size: 24px;
      font-weight: 700;
      height: 29px;
      display: flex;
      align-items: center;

      .spinner {
        width: 38px;
      }
    }
  }
}
