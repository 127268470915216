@import "scssShared/variables.scss";
@import "scssShared/animations.scss";

.promotionBar {
  box-sizing: border-box;
  margin: 0 15px;
  padding: 15px;

  background-color: $color_catskill-white;
  border-bottom: $border_grey_light_thin;
  border-left: $border_grey_light_thin;
  border-right: $border_grey_light_thin;
  border-bottom-left-radius: $br_medium;
  border-bottom-right-radius: $br_medium;
  box-shadow: inset $bs_smallest, $bs_smallest;

  &.hidden {
    cursor: pointer;
    display: flex;
    justify-content: center;

    .icon {
      color: $color_grey;
      font-size: 14px;
    }

    &:hover {
      background: $color_grey_site_background;

      .icon {
        color: $color_grey_lighter;
      }
    }
  }

  .titleWrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    .title {
      color: $color_grey;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 0.05em;
    }

    .button {
      background-color: transparent;
      border: none;
      color: $color_grey;
      font-size: 14px;
      outline: none;
      transition: $transition_short;

      &:hover {
        color: $color_grey_lighter;
      }
    }
  }

  .icons {
    display: flex;
    justify-content: space-between;

    .icon {
      box-sizing: border-box;
      height: 36px;
      padding: 6px;
      width: 60px;

      background-color: $color_disabled_field_bg;
      border: $border_grey_light_thin;
      border-radius: $br_large;
      cursor: pointer;

      &:hover {
        border: $border_grey;
        box-shadow: $bs_smallest;
      }
    }
  }

  .text {
    letter-spacing: -0.015em;

    .anchor {
      color: inherit;
      font-size: inherit;
      text-transform: inherit;
    }
  }
}
