@import "scssShared/variables.scss";

.create-attribute {
  .create-attribute-header {
    justify-content: space-between;

    .header-buttons {
      .button {
        margin-left: 10px;
      }
    }
  }

  .create-attribute-content {
    padding: 0;
  }
}
