@import "scssShared/variables.scss";

.spinner {
  width: 28px;
  height: 28px;
}

.smallSpinner {
  width: 14px;
  height: 14px;

  &::before {
    width: 14px;
    height: 14px;
  }
}

.container {
  display: flex;
  justify-content: center;
}

.content {
  display: flex;
  max-width: 1240px;
  padding: 0 20px;
  box-sizing: border-box;
  flex: 1;
}

.dashboardSection {
  max-width: 450px;
  margin-right: 10px;
  flex: 1;

  & > * {
    margin-bottom: 10px;
  }

  .row {
    display: flex;

    & > *:first-child {
      margin-right: 10px;
    }
  }

  .left {
    flex: 1;
    margin-right: 20px;
  }

  .right {
    text-align: right;
  }

  .label {
    text-transform: uppercase;
    letter-spacing: 0.1em;
    color: $color_grey_lighter;
    font-size: 9px;
    font-weight: 700;
  }

  .mainValue {
    color: $color_black;
    font-size: 24px;
    font-weight: 600;
    text-transform: uppercase;
  }

  .refreshLabel {
    font-size: 11px;
  }

  .refreshValue {
    font-weight: 700;
    color: black;
    font-size: 12px;
  }

  .refreshLabel,
  .refreshValue {
    margin-top: 3px;
  }

  .beforeStitching {
    font-size: 12px;

    .uppercase {
      text-transform: uppercase;
    }
  }

  .dashboardHeaderCard,
  .dashboardCard {
    display: flex;
    align-items: center;
  }

  .dashboardHeaderCard {
    background-color: $color_catskill-white;
    height: 75px;
    color: $color_grey;
    padding: 15px;
    font-size: 14px;

    .left {
      display: flex;
    }

    .avatar {
      margin-right: 10px;
    }

    .name {
      color: $color_primary;
      font-weight: 700;
      font-size: 18px;
    }
  }

  .dashboardCard {
    height: 70px;
  }

  .dashboardHeaderCard {
    .right {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }

  .linkToAnalytics {
    display: block;
    text-decoration: none;
    border-radius: 8px;
    border: solid 1px $color_border;
    background-color: $color_white;
    font-family: $font_family_neue;
    font-size: 11px;
    font-weight: 700;
    color: $color_grey_lighter;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.05);
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.1s ease;

    &:hover {
      color: darken($color_grey_lighter, 10%);
      border-color: darken($color_border, 10%);
    }

    .icon {
      margin-right: 8px;
    }
  }
}

.notificationsSection {
  max-width: 740px;
  flex: 1;
  margin: 0 auto;

  .notificationsHeader {
    position: relative;

    .notificationsTooltip {
      margin-left: 10px;
      color: $color_grey_lighter;
      font-size: 15px;
    }
  }

  .emptyMessage,
  .loadingMore {
    display: flex;
    justify-content: center;
    margin: 10px;
    color: $color_grey_lighter;
  }

  .header {
    display: flex;
    text-transform: uppercase;
    font-size: 10px;
    letter-spacing: 0.1em;
    margin: 15px 5px 5px 5px;

    .sectionHeader {
      flex: 1;
      color: $color_grey_lighter;
    }
  }
}

.markAsReadButton {
  color: $color-primary;
  position: relative;
  text-transform: inherit;
  font-size: inherit;
  letter-spacing: inherit;
  text-decoration: underline;
  outline: none;
  border: none;
  background-color: transparent;
  padding: 0;

  .spinnerWrapper {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: none;
  }

  &.isLoading {
    .text {
      visibility: hidden;
    }

    .spinnerWrapper {
      display: block;
    }
  }
}
