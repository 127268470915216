@import "scssShared/variables.scss";

.segments-list {
  .segments-list-header {
    justify-content: space-between;

    button {
      box-shadow: $bs_smallest;
    }

    .segments-search {
      width: 280px;
    }
  }

  .paper {
    .tag-filter {
      justify-content: space-between;

      .tags-wrapper {
        display: flex;
        align-items: center;
        max-width: 55%;

        .label-tags {
          display: flex;

          .selected-tags {
            flex-shrink: 0;
            margin-top: 2.5px;
            margin-bottom: 2.5px;
          }

          .tags-and-picker {
            display: flex;
            align-items: flex-start;
            flex-wrap: wrap;

            .tag {
              margin-top: 2.5px;
              margin-bottom: 2.5px;
            }
          }
        }

        .tag-picker {
          margin-right: 0;
        }
      }

      .segment-filters {
        display: flex;

        .segment-filters-label {
          font-size: 11px;
          letter-spacing: 1px;
          margin-right: 10px;
          line-height: 34px;
          color: $color_grey;
          text-transform: uppercase;
          font-weight: bold;
        }

        .segment-filter-button {
          margin-right: 5px;

          .button-icon {
            margin-right: 2px;
          }
        }

        .vertical-line {
          width: 1px;
          flex-shrink: 0;
          background: $color_border;
          height: 32px;
          margin-top: 1px;
          margin: 1px 10px 0px 10px;
        }

        .thick-bars,
        .thin-bars {
          padding: 5px;
          cursor: pointer;
          display: block;
          width: 16px;
          height: 14px;
          margin-top: 4px;

          svg {
            path {
              fill: $color_grey;
              transition: $transition_default;
            }
          }

          &.active,
          &:hover {
            svg {
              path {
                fill: $color_black;
              }
            }
          }
        }

        .thick-bars {
          margin-left: 5px;
          margin-right: 5px;
        }
      }
    }
  }

  .segments-loading {
    margin-top: 1px;
  }

  .segments {
    .tags {
      width: 270px;
      padding: 20px 15px 14px;
      .tag {
        margin-right: 5px;
        margin-bottom: 5px;
        cursor: pointer;
      }
    }

    .action-column {
      padding: 18px 0;
      width: 40px;
    }

    .segment-forbidden-tooltip {
      padding: 10px;
      font-size: 13px;
      line-height: 16px;
      font-weight: bold;
    }

    .segment-name {
      font-size: 16px;
      font-weight: bold;
      color: $color_black;

      .segment-name-content-wrapper {
        display: flex;
        align-items: center;

        .scheduling-icon {
          width: 22px;
          height: 20px;
          color: $color_grey;
          border-radius: $br_small;
          border: 1px solid $color_border;
          box-sizing: border-box;
          background: #f9f9f9;
          margin-right: 12px;
          flex-shrink: 0;
          font-size: 11px;
          font-weight: normal;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;

          &:not(.active) {
            &:after {
              content: "";
              width: 16px;
              height: 3px;
              box-sizing: border-box;
              border-top: 1px solid #f9f9f9;
              border-bottom: 1px solid #f9f9f9;
              position: absolute;
              background-color: $color_grey;
              transform: rotate(-45deg);
            }
          }

          &.active {
            background: rgba(254, 127, 102, 0.1);
            border-color: $color_primary;
            color: $color_primary;
          }
        }
      }
    }

    .author {
      padding-right: 50px;
      width: 180px;
    }

    .date {
      width: 175px;
    }

    .export-date {
      width: 135px;
    }

    .link-hover {
      display: inline-block;
      margin: -7px 0;
    }

    &.thin {
      .custom-tbody {
        .custom-td {
          &:not(.overlap) {
            padding: 10px 8px;
          }

          &.segment-name {
            font-size: 13px;
          }

          &.author,
          &.last-export,
          &.modified-at {
            padding: 13px 8px 9px;
            font-size: 12px;
          }

          &.action-column {
            .icon-button {
              font-size: 12px;
            }
          }

          &.tags {
            padding: 10px 15px 7px;
            vertical-align: middle;

            .tag {
              font-size: 7px;
              margin-right: 3px;
              margin-bottom: 3px;
              height: 20px;
              line-height: 20px;
            }
          }

          &.hover-overlap-helper {
            padding: 0;
          }

          &.action-column {
            padding: 8px 0;
          }
        }
      }
    }
  }
  .no-segments-found {
    padding-bottom: 5px;
    padding-top: 15px;
  }

  .modified-at {
    .date-modified-at,
    .modified-by {
      display: block;
    }

    .modified-by {
      font-size: 11px;
    }
  }
}

.scheduling-tooltip .tippy-content {
  max-width: 260px;
  padding: 10px;
  box-sizing: border-box;

  .destination-schedule {
    background: $color_primary;
    display: flex;
    align-items: center;
    border-radius: 4px;
    margin-bottom: 5px;
    padding: 5px;
    min-height: 24px;

    &:last-child {
      margin-bottom: 0;
    }

    @each $name, $color in $colors {
      &.#{$name} {
        background: $color;
      }
    }

    img {
      width: 24px;
      height: auto;
      flex-shrink: 0;
      margin: 0 5px 0 0;
    }

    p {
      margin: 0;
      color: $color_white;
      font-family: $font_family_inter;
      font-size: 11px;
      line-height: 13px;
      font-weight: bold;
    }
  }
}
