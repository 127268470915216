@import "scssShared/variables.scss";

.container {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.filters {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
}

.filterTypeSelect {
  width: 170px;
}

.filterValueSelect {
  flex: 1;
}

.datePicker {
  width: 200px;
}

.applyButton {
  margin-top: 23px;
  margin-left: auto;
}

.message {
  height: 44px;
  display: flex;
  align-items: center;
  flex: 1;
  color: $color_grey_lighter;
  font-size: 14px;

  .infoIcon {
    margin-right: 5px;
  }
}

.pageSpinner {
  margin: 20px;
}

.chartsGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 300px;
  gap: 10px;
}
