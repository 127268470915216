.bannersBox {
  margin-top: 10px;
}

.actionButtonMargin {
  margin-left: 5px;
}

.formHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  box-sizing: border-box;
  margin-bottom: 10px;

  h3 {
    font-size: 21px;
    margin: 0;
    padding-top: 11px;
  }
}

.header {
  display: flex;
  justify-content: space-between;
}

.wrapper {
  width: 1200px;
  margin: 0 auto;
}

.searchField {
  width: 315px;
}

.elementIdTooltip {
  word-break: break-all;
}

.actionsColumn {
  width: 100px;
}

.priorityColumn {
  width: 100px;
}

.toggleColumn {
  width: 100px;
}

.modifiedAtColumn {
  width: 190px;
}

.nameCell {
  word-break: break-word;
}

.modifiedAt,
.modifiedBy {
  display: block;
}

.modifiedBy {
  font-size: 11px;
}
