@import "scssShared/variables.scss";
@import "scssShared/mixins.scss";

.scheduler-wrapper {
  position: relative;

  .scheduler-button {
    padding: 3px 10px 3px 3px;

    &:focus,
    &:hover {
      box-shadow: none;
    }

    > span {
      display: flex;
      align-items: center;

      .icon {
        width: 26px;
        height: 26px;
        border-radius: $br_small;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 2px;

        &.has-schedule {
          background-color: $color_primary;
          color: $color_white;
          margin-right: 6px;
        }

        .days-count {
          width: 11px;
          height: 11px;
          font-size: 7px;
          background: $color_white;
          color: $color_primary;
          text-transform: uppercase;
          font-weight: bold;
          position: absolute;
          right: 2px;
          bottom: 2px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          box-sizing: border-box;
          padding-left: 2px;
          font-family: $font_family_inter;
        }
      }

      .text {
        margin-top: 1px;
      }

      .clock-icon {
        font-size: 16px;
      }
    }

    &.schedule-exists {
      border-color: $color_primary;
      background: rgba($color_primary, 0.1);
      color: $color_primary;
    }
  }

  .scheduling-modal {
    position: absolute;
    z-index: 1;
    width: 560px;
    background: $color_white;
    border: $border_grey_light_thin;
    box-shadow: $bs_smallest;
    border-radius: $br_medium;
    right: 0;
    top: 46px;
    padding: 20px;
    box-sizing: border-box;

    &:after {
      content: "";
      display: block;
      width: 14px;
      height: 14px;
      transform: rotate(45deg);
      position: absolute;
      border-left: $border_grey_light_thin;
      border-top: $border_grey_light_thin;
      right: 13px;
      top: -8px;
      background: $color_white;
    }

    .header {
      display: flex;
      justify-content: space-between;

      h3 {
        font-size: 13px;
        line-height: 16px;
        letter-spacing: 0.1em;
        color: $color_grey;
      }

      .close-button {
        padding: 2px;
        min-width: 22px;
        min-height: 22px;
        margin-top: -5px;
        margin-right: -5px;
      }
    }

    .settings {
      margin-top: 18px;
      display: flex;
      justify-content: space-between;

      label {
        display: block;
        font-family: $font_family_inter;
        font-weight: bold;
        font-size: 11px;
        line-height: 13px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: $color_grey;
        margin-bottom: 2px;
      }

      .day-picker {
        text-align: left;

        .button {
          margin-right: 5px;
          height: 54px;
          font-size: 11px;
          font-family: $font_family_inter;
          letter-spacing: 0.15em;
          text-transform: uppercase;
          padding-left: 8px;
          padding-right: 8px;

          &.white {
            color: $color_black;
          }
        }
      }

      .delimiter {
        width: 1px;
        height: 42px;
        background: $color_link_water;
        margin-top: 21px;
      }

      .time-picker {
        text-align: left;
        font-size: 13px;
        color: $color_black;

        .control-wrapper {
          position: relative;
          display: inline-block;

          .value-up,
          .value-down {
            position: absolute;
            width: 38px;
            left: 1px;
            display: block;
            text-align: center;
            font-size: 10px;
            cursor: pointer;
            padding: 2px 0;
            color: $color_border;
            transition: $transition_default;
            border: 0;
            background: transparent;

            &:hover {
              color: $color_black;

              & ~ input {
                border: 1px solid $color_grey;
              }
            }

            &:focus {
              outline: none;
            }
          }

          .value-up {
            top: 0;
          }

          .value-down {
            bottom: 0;
          }
        }

        .time-input {
          width: 40px;
          height: 54px;
          padding: 0 5px;
          box-sizing: border-box;
          text-align: center;
          font-size: 13px;
          font-weight: bold;
        }

        @include placeholder {
          color: $color_black;
          opacity: 0.5;
          font-weight: 400;
        }
      }
    }

    .footer-delimiter {
      width: 530px;
      height: 1px;
      border: 0;
      background: $color_link_water;
      margin: 20px 0 10px -5px;
    }

    .footer {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .info-message {
        font-size: 15px;
        color: $color_grey_lighter;
        line-height: 135%;
        font-weight: normal;
        text-align: left;

        strong {
          color: $color_black;
        }
      }

      .action-buttons {
        flex-shrink: 0;
        width: 130px;

        .clear {
          margin-right: 10px;
        }
      }
    }
  }
}
