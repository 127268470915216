@import "scssShared/variables.scss";
@import "scssShared/flexbox.scss";

.password-field-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .text-field {
    flex-grow: 1;
    margin-right: 5px;
  }

  .strength-icon-wrapper {
    width: 44px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &.has-label {
      margin-top: 23px;
    }
  }

  .strength-icon {
    width: 100%;
    height: 44px;
    padding: 4px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;

    img {
      width: 100%;
    }

    &.weak {
      background-color: $color_warning_red;
    }

    &.mild {
      background-color: #f9b549;
    }

    &.strong {
      background-color: #739943;
    }
  }

  .label {
    font-size: 8px;
    line-height: 10px;
    margin-top: 5px;
    font-weight: bold;
    text-transform: uppercase;

    &.weak {
      color: $color_warning_red;
    }
    &.mild {
      color: #f9b549;
    }
    &.strong {
      color: #739943;
    }
  }
}
