@import "scssShared/variables.scss";
@import "scssShared/animations.scss";

.aceEditorContainer {
  .aceEditorWrapperInner {
    position: relative;

    .expandButton {
      position: absolute;
      right: 0;
      top: 0;
      background: #ffffff;
      border: $border_grey_light_thin;
      border-radius: 8px;
      padding: 0;
      margin-right: 4px;
      margin-top: 4px;
    }
  }

  .textareaExpandModal {
    text-align: left;

    p {
      margin: 0 0 10px;
      line-height: 25px;
      font-size: 17px;

      &:last-of-type {
        margin: 0 0 20px;
      }
    }

    ul {
      margin: 5px 0 0;
    }

    .modalButtons {
      display: flex;
      justify-content: space-between;
      margin-top: 25px;

      .cancelButton {
        margin-left: -12px;
      }
    }
  }
}
