@import "scssShared/variables.scss";

.attribute-form {
  .form-row {
    display: flex;
    padding: 30px 20px;
    margin: 0;

    &.grey {
      background: $color_catskill-white;
      border-top: 1px solid $color_border;
      border-bottom: 1px solid $color_border;
    }

    &.last {
      margin-bottom: 0;
    }
  }

  .left-part {
    width: 220px;
    flex-shrink: 0;

    h2 {
      font-size: 21px;
      letter-spacing: -0.025em;
      margin: 0;
      line-height: 21px;
      margin-top: 29px;
    }

    .docs {
      font-size: 12px;
      line-height: 15px;
      color: $color_grey_lighter;
      font-weight: normal;
      margin: 24px 0 0;

      a {
        text-transform: none;
        font-size: 12px;
        color: $color_primary;
      }
    }

    &.wider {
      width: 390px;
      padding-right: 30px;
      box-sizing: border-box;
    }
  }

  label {
    display: block;
  }

  .right-part {
    display: flex;
    flex-grow: 1;

    &.wrap {
      flex-wrap: wrap;
    }
  }

  .attribute-id {
    width: 200px;

    input {
      width: 100%;
      box-sizing: border-box;
    }
  }

  .attribute-name {
    margin-left: 10px;

    input {
      width: 280px;
      box-sizing: border-box;
    }
  }

  .attribute-description {
    flex-shrink: 0;
    textarea {
      width: 490px;
      box-sizing: border-box;
    }
  }

  .source-id {
    width: 240px;
    margin-left: 10px;
  }

  .attribute-data-type {
    width: 320px;
    z-index: 2;
  }

  .attribute-def-wrapper {
    flex-grow: 1;

    .definition-type-switch {
      margin-bottom: 20px;
    }
  }

  .attribute-def-row {
    display: flex;
    gap: 10px;

    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }

  .attribute-def-sources,
  .attribute-def-event-types {
    width: 100%;
  }

  .attribute-def-versions {
    flex: 1;
  }

  .attribute-def-calc-type {
    width: 180px;
  }

  .attribute-def-weight {
    width: 140px;
  }

  .code-editor-field {
    flex: 1;
  }

  .title-with-toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      margin-top: 22px;
    }

    .toggle-switch-wrapper {
      margin-top: 24px;
    }
  }

  .definition-title {
    display: flex;
    align-items: center;
    margin-top: 22px;

    h2 {
      margin-right: 15px;
      margin-top: 0;
    }
  }

  .compound-fields {
    width: 100%;

    .dimensions-content {
      margin-top: 10px;

      .dimension-item {
        display: flex;
        align-items: flex-start;
        margin-bottom: 10px;

        .icon-button {
          margin-top: 28px;
          margin-left: 5px;
        }
      }

      .dimension-id {
        width: 200px;

        input {
          width: 100%;
          box-sizing: border-box;
        }
      }

      .dimension-name {
        margin-left: 10px;
        input {
          width: 240px;
          box-sizing: border-box;
        }
      }

      .dimension-data-type {
        width: 275px;
        margin-left: 10px;
      }
    }
  }

  .right-part .labels {
    margin-left: 25px;

    .label-like {
      margin: 0 0 11px;
      display: block;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 10px;
      line-height: 12px;
      letter-spacing: 1px;
    }

    .list {
      display: flex;
      flex-grow: 1;
      flex-wrap: wrap;

      .tag-picker {
        margin-right: 0;
      }

      .tag {
        margin: 2.5px 5px 2.5px 0;
      }
    }
  }
}
