.actionsColumn {
  width: 140px;
}

.deletedByColumn {
  width: 180px;
}

.typeColumn {
  width: 400px;
}

.deletedAt,
.deletedBy {
  display: block;
}

.deletedBy {
  font-size: 11px;
}
