@import "scssShared/variables.scss";

.smart-segment-tile {
  background: $color_white;
  border: 1px solid $color_border;
  height: 235px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: $br_medium;
  box-shadow: 0px 3px 6px rgba($color_border, 0.05);

  img {
    height: 140px;
    width: auto;
  }

  h4 {
    font-family: $font_family_neue;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    margin: 12px 0 0;
    color: $color_border;
  }

  p {
    margin: 2px 0 0;
    color: $color_border;
    font-weight: bold;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    letter-spacing: 0.2em;
    text-transform: uppercase;
  }

  @each $name, $color in $colors {
    &.#{$name} {
      border-color: $color;
      box-shadow: 0px 3px 6px rgba($color, 0.05);

      h4,
      p {
        color: $color;
      }

      &.link {
        &:hover {
          background: rgba($color, 0.05);
          box-shadow: 0px 3px 10px rgba($color, 0.08);
        }
      }
    }
  }

  &.link {
    text-decoration: none;
    transition: $transition_default;
  }
}
