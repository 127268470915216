@import "scssShared/variables.scss";

.node-popper {
  max-width: 400px;
  box-sizing: border-box;
  padding: 20px;
  background: $color_white;
  border: 1px solid $color_border;
  border-radius: $br_large;
  margin-left: -28px;

  &[x-placement="bottom-start"] {
    margin-top: 15px;

    &:after {
      content: " ";
      width: 16px;
      height: 16px;
      display: block;
      position: absolute;
      top: -9px;
      left: 20px;
      transform: rotate(45deg);
      border-top: 1px solid $color_border;
      border-left: 1px solid $color_border;
      border-top-left-radius: $br_small;
      background-color: $color_white;
    }
  }

  &[x-placement="top-start"] {
    margin-bottom: 15px;

    &:after {
      content: " ";
      width: 16px;
      height: 16px;
      display: block;
      position: absolute;
      bottom: -9px;
      left: 20px;
      transform: rotate(45deg);
      border-bottom: 1px solid $color_border;
      border-right: 1px solid $color_border;
      border-bottom-right-radius: $br_small;
      background-color: $color_white;
    }
  }

  p {
    margin: 0;

    &.label {
      font-weight: bold;
      font-size: 11px;
      line-height: 13px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: $color_grey;
      margin-bottom: 8px;
    }

    &.value {
      font-weight: bold;
      font-size: 15px;
      line-height: 18px;
      font-feature-settings: "salt" on;
      color: $color_black;
      word-wrap: break-word;
    }
  }

  .second-row {
    margin-top: 15px;
    table-layout: fixed;
    td {
      vertical-align: top;
      word-wrap: break-word;
    }
  }

  .datetime,
  .source-name,
  .not-available-source,
  .event-name {
    color: $color_grey_lighter;
    font-size: 14px;
    line-height: 17px;
    font-feature-settings: "salt" on;
  }

  .not-available-source {
    margin-top: 6px;
  }

  .source-td {
    padding-right: 5px;

    .label {
      margin-bottom: 2px;
    }
  }
  .timestamp-td {
    padding-left: 5px;
    white-space: pre;
  }

  .identifier-source {
    display: flex;
    align-items: center;

    .logo-wrapper {
      margin-right: 8px;
      width: 24px;
      height: 24px;
      border: 1px solid $color_border;
      display: flex;
      border-radius: 50%;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;

      img {
        width: 80%;
        height: auto;
      }
    }
  }
}

.identity-graph {
  &.loading {
    position: relative;
    cursor: wait;

    &:before {
      margin: -32px 0 0 -32px;
      width: 64px;
      height: 64px;
      position: absolute;
      left: 50%;
      top: 50%;
      content: "";
      border-radius: 64px;
      background-clip: padding-box;
      border-color: rgba($color_primary, 0.25);
      border-width: 5px;
      border-style: solid;
      border-top-color: $color_primary;
      animation: animation-rotate 975ms cubic-bezier(0.25, 0.35, 0.5, 0.95) infinite;
    }
  }
}

.identity-graph-wrapper {
  position: relative;

  .action-buttons {
    position: absolute;
    right: 15px;
    bottom: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    button {
      font-size: 40px;
      padding: 0px;
      margin: 5px 0;
    }

    .zoom-out-button,
    .zoom-in-button {
      button {
        font-size: 24px;
      }
    }
  }

  .error-message {
    width: 100%;
    font-size: 14px;
    line-height: 135%;
    color: $color_black;
    letter-spacing: -0.025em;
    text-align: center;
    font-weight: bold;
    padding: 0 35px;
    position: absolute;
    box-sizing: border-box;
    top: 50%;
    transform: translateY(-50%);
  }
}
