@import "scssShared/variables.scss";

.container {
  flex: 1;
  align-items: center;
  margin-top: 10px;
  display: flex;
}

.refreshIcon {
  margin-right: 8px;
}

.segmentedNumberWrapper {
  font-size: 14px;
  color: $color_grey;
  flex: 1;
}

.segmentedNumberInlineWrapper {
  display: flex;
  align-items: center;
  min-height: 19px; // to prevent jumping around during loading

  & *:not(:first-child) {
    margin-left: 5px;
  }
}

.segmentedNumber,
.percentage {
  font-size: 16px;
  font-weight: 700;
  color: $color_black;
}

.dataRefreshed {
  font-size: 10px;
  color: $color_grey;
  display: flex;
  min-height: 12px; // to prevent jumping around during loading
}

.percentageWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.percentage {
  line-height: 1;
  position: relative;

  .percentageArcWrapper {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    pointer-events: none;

    svg {
      width: 70px;
      height: 35px;
    }
  }
}

.percentageSubtitle {
  font-size: 10px;
  line-height: 1;
  margin-top: 2px;
}

.inlineLoadingIndicator {
  width: 16px;
  height: 16px;
  padding: 0;

  &::before {
    width: 12px;
    height: 12px;
    top: 0;
    left: 0;
    margin: 0;
  }
}
