@import "scssShared/variables.scss";

.not-found {
  display: flex;
  justify-content: center;
  align-items: center;

  .left-side {
    flex-shrink: 0;
    width: 275px;
    margin: 110px 0;
  }

  .right-side {
    color: $color_black;
    margin-left: 70px;

    h1 {
      font-size: 32px;
      line-height: 38px;
      max-width: 480px;
      margin: 0 0 15px;
    }

    h2 {
      font-size: 18px;
      line-height: 21px;
      margin-bottom: 0;
      font-weight: normal;
    }
  }
}
