@import "scssShared/variables.scss";
@import "scssShared/flexbox.scss";

.user-settings {
  .above-paper-left {
    top: -40px !important;

    button {
      &:hover {
        opacity: 1;
      }
    }
  }

  .user-settings-header {
    @include flexAcross();
    justify-content: space-between;

    .header-content {
      display: flex;
      align-items: center;

      .email-notif-toggle-label {
        color: $color_black_lighter;
        font-size: 15px;
        margin-right: 10px;
      }

      .action-buttons {
        margin-left: 35px;

        button:not(:first-child) {
          margin-left: 10px;
        }
      }
    }
  }

  .user-settings-content {
    padding: 30px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .avatar {
      margin-right: 30px;
      flex-shrink: 0;
      .avatar-wrapper {
        width: 96px;
        height: 96px;

        .inicials {
          font-size: 28px;
        }
      }
    }

    .password-change-form {
      flex-grow: 1;
      display: flex;
      height: 96px;

      .password-field-wrapper {
        width: 300px;
        margin-right: 80px;
        margin-top: 10px;
      }

      .repeat-pwd-field {
        width: 250px;
        margin-top: 10px;
        .password-wrapper,
        input {
          width: 100%;
          box-sizing: border-box;
        }
      }
    }

    .user-info {
      flex-grow: 1;
      display: flex;

      .box {
        flex-basis: 33%;
        box-sizing: border-box;
        padding-right: 25px;

        .role-label {
          font-weight: bold;
          color: $color_grey;
          font-size: 11px;
          line-height: 13px;
          letter-spacing: 0.1em;
          display: block;
          text-transform: uppercase;
          margin-bottom: 6px;
        }

        .role-name {
          font-weight: bold;
          font-size: 15px;
          line-height: 18px;
          color: $color_black;
          padding: 13px 0;
          display: inline-block;
        }
      }
    }

    .automated-toggle-wrapper {
      height: 44px;
      display: flex;
      align-items: center;
    }
  }

  .role-features-section {
    width: calc(100% - 40px);
    margin: 0 auto;
    background: $color_catskill-white;
    color: $color_grey;
    border: 1px solid $color_border;
    border-top: 0;
    box-shadow: $bs_smallest;
    border-radius: 0 0 $br_small $br_small;
    box-sizing: border-box;
    padding: 10px;

    h4 {
      font-size: 10px;
      line-height: 12px;
      font-weight: bold;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      margin: 0;
      width: 120px;
      flex-shrink: 0;
      color: $color_grey;
    }

    .delimiter {
      width: 100%;
      height: 1px;
      background: $color_border;
      margin: 10px 0;
    }

    .row {
      padding: 10px;
      display: flex;

      .permissions {
        flex-grow: 1;
        display: flex;

        .col {
          flex-basis: 50%;
          p {
            margin: 0;
            font-size: 10px;
            line-height: 12px;
            text-transform: uppercase;
            letter-spacing: 0.15em;
            font-weight: bold;
            margin-bottom: 12px;

            .icon {
              margin-right: 2px;
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }

      &.allowed {
        color: #739943;
      }
    }
  }
}
