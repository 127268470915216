@import "scssShared/variables.scss";

.setup-events {
  .info-message {
    margin: 20px 10px;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
  }

  .setup-events-header {
    justify-content: space-between;

    .events-search-form {
      width: 810px;
    }
  }

  .source-icon {
    width: 32px;
    height: 32px;
    border: 1px solid $color_border;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    img {
      width: 80%;
      height: auto;
    }
  }

  .source-name {
    font-size: 14px;
    font-weight: bold;
    color: $color_grey_lighter;
    margin-left: 7px;
  }

  .source-cell-content {
    display: flex;
    align-items: center;
  }

  .left-margin {
    margin-left: 5px;
  }

  .setup-events-table {
    .date-added {
      width: 8.5%;
    }
    .source {
      width: 30%;
    }
    .actions {
      width: 70px;
    }

    .is-hidden {
      .name,
      .source-cell-content,
      .created {
        opacity: 0.3;
      }
    }
  }

  .disabled-button-tooltip {
    font-size: 12px;
    font-weight: normal;
    padding: 10px;
  }
}
