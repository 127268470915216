@import "scssShared/variables.scss";
@import "scssShared/animations.scss";

.container {
  position: relative;
  z-index: 2;

  .button {
    border: $border_grey_light_thin;
    background-color: $color_white;
    font-size: 15px;
    font-weight: 400;
    text-transform: none;
    letter-spacing: 0;
    box-shadow: none;
    padding: 0 10px;
    width: 210px;

    &:disabled {
      background-color: $color_disabled_field_bg;
      color: $color_disabled_field;
      opacity: 1;
    }

    &:hover:not(:disabled) {
      border: solid 1px $color_grey;
    }

    .buttonContent {
      display: flex;
      align-items: center;
    }

    .text {
      flex: 1;
      text-align: left;
    }

    .placeholder {
      color: $color_grey_lighter;
    }

    .caretWrapper {
      margin-left: 10px;
      padding-left: 7px;
      border-left: $border_grey_light_thin;
      color: $color_grey_lighter;
      font-size: 11px;
      height: 18px;
      display: flex;
      align-items: center;
    }

    &.error {
      border-color: $color_warning_red;
      animation: animation-shake 1s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    }
  }

  .dropdown {
    background: $color_white;
    border-radius: $br_small;
    border: $border_grey_light_thin;
    position: absolute;
    z-index: 1;
    overflow: hidden;
    margin-top: 5px;
    min-width: 280px;
    width: auto;
    padding: 10px 0;

    .dropdownRow {
      cursor: pointer;
      transition: $transition_default;
      font-size: 11px;
      font-weight: bold;
      letter-spacing: 1px;
      font-family: $font_family_inter;
      text-transform: uppercase;
      color: $color_grey_lighter;
      padding: 5px 15px;

      &:hover,
      &.active {
        color: $color_primary;
      }
    }

    .titleRow {
      @extend .dropdownRow;

      color: $color_primary;
      cursor: initial;
    }

    .dimensionDropdownRow {
      @extend .dropdownRow;

      margin-left: 15px;
    }

    .divider {
      border-top: solid 1px $color_border;
      margin: 10px 0;
    }
  }
}
