@import "scssShared/variables.scss";

.helpButton {
  font-size: 24px;
  color: $color_grey;
  border: 1px solid $color_border;
  width: 52px;
  height: 52px;
  display: block;
  position: absolute;
  bottom: 15px;
  left: 15px;
  background: $color_white;
  border-radius: 26px;

  &:focus {
    border: 1px solid $color_border;
    outline: none;
  }
}

.helpBox {
  position: absolute;
  bottom: 15px;
  left: 77px;
  color: $color_grey;
  border: 1px solid $color_border;
  padding: 20px 15px;
  border-radius: $br_large;
  background: $color_white;

  p {
    margin: 0 0 5px 0;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
