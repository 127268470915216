@import "scssShared/variables.scss";

.destination-attributes {
  .tag {
    margin: 2.5px 5px 2.5px 0;
    cursor: pointer;
    white-space: normal;
    line-height: 12px;
    height: auto;
    min-height: 24px;
    padding: 4px 8px;

    .eye-icon {
      margin-right: 3px;
    }
  }

  .destination-attributes-modal {
    .scrollable {
      max-height: calc(95vh - 100px);
    }

    .tag {
      cursor: default;

      &:hover {
        box-shadow: none;
      }
    }

    header {
      h3 {
        color: $color_black;
        font-size: 16px;
        text-transform: none;
      }
    }

    h4 {
      margin: 0;
      text-transform: uppercase;
      font-family: $font_family_inter;
      color: $color_grey;
      font-size: 11px;
      line-height: 13px;
      letter-spacing: 0.1em;
      margin-bottom: 5px;
      margin-top: 10px;

      &.mandatory-title {
        margin-top: 18px;
      }
    }
  }

  .operator-vis {
    width: 80px;
    height: 24px;
    border: 1px solid $color_border;
    border-radius: 12px;
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 3px;
    margin: 2.5px 10px 2.5px 0;

    > span {
      width: 37px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      color: $color_grey_lighter;
      height: 18px;
      border-radius: 9px;

      &.checked {
        color: $color_white;
        background: $color_grey;
      }
    }
  }
}
