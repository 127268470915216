@import "scssShared/variables.scss";

.single-input-form {
  label {
    font-weight: bold;
    color: $color_grey;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.1em;
    display: block;
  }

  .value-wrapper {
    margin-left: -15px;
    position: relative;
    display: inline-block;
    transition: $transition_default;

    .ghost {
      font-weight: bold;
      font-size: 15px;
      line-height: 18px;
      color: $color_black;
      padding: 13px 31px 13px 15px;
      pointer-events: none;
      display: inline-block;
    }

    input {
      display: none;
      font-size: 15px;
      font-weight: 700;
      transition: $transition_default;
      box-sizing: border-box;
      padding-right: 31px;
      width: 100%;
      margin: 0;
    }

    .control-button-tooltip {
      position: absolute;
      right: -16px;
      top: 6px;
    }

    .edit-action-button {
      width: 32px;
      height: 32px;
      background: $color_border;
      border-radius: 50%;
      border: none;
      outline: none;
      color: $color_white;
      transition: $transition_default;

      &.edit {
        background: $color_primary;
      }

      &:focus {
        border: none;
        outline: none;
      }

      &:hover {
        background: $color_primary;
      }
    }

    &.edit {
      .ghost {
        position: fixed;
        top: 0;
        left: 0;
        opacity: 0;
      }

      input {
        display: block;
        font-weight: 400;
      }
    }
  }
}
