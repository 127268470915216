@import "scssShared/variables.scss";

.container {
  position: relative;
  display: flex;
  justify-content: center;
  text-align: initial;
}

.unreadBadge {
  position: absolute;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  border: solid 1px $color-white;
  background-color: $color_warning_red;
  left: 50%;
  top: 50%;
  pointer-events: none;

  &::after {
    position: absolute;
    height: 5px;
    width: 5px;
    border-radius: 50%;
    background-color: $color-white;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    content: " ";
  }
}

.button {
  font-size: 16px;
  color: $color_white;
  background-color: transparent;
  outline: none;
  border: none;
  opacity: 0.6;
  transition: opacity 0.1s;
  padding: 3px;
  box-shadow: none;

  .icon {
    margin: 0;
  }

  &:hover,
  &.isOpen {
    opacity: 1;
  }
}

.dropdown {
  $x-offset: 130px;
  z-index: 11;
  position: absolute;
  background-color: $color-white;
  width: 400px;
  right: -$x-offset;
  top: calc(100% + 15px);
  padding: 5px;
  border-radius: 8px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  border: $border_grey_light_thin;

  &::before {
    position: absolute;
    content: " ";
    width: 0;
    height: 0;
    border-left: solid 5px $color_white;
    border-top: solid 5px $color_white;
    border-right: solid 5px transparent;
    border-bottom: solid 5px transparent;
    transform: rotate(45deg) translateX(-50%);
    right: $x-offset;
    top: 0;
    pointer-events: none;
    cursor: default;
  }

  .dropdownHeader {
    display: flex;
    align-items: center;
    height: 35px;
    font-size: 10px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    margin: 0 10px;

    .title {
      flex: 1;
    }

    .dashboardLink {
      color: $color-primary;
    }
  }

  .dropdownBody {
    background-color: $color_grey_site_background;
    padding: 10px;
    max-height: 400px;
    color: $color_grey_lighter;
    overflow-y: auto;
    border-radius: 3px;

    .emptyMessage {
      display: flex;
      justify-content: center;
      margin: 10px;
    }

    .dateGroup {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }

      .dateGroupHeader {
        display: flex;
        justify-content: center;
        font-size: 10px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
      }
    }

    .loadingMore {
      display: flex;
      justify-content: center;
      font-size: 12px;
    }
  }
}
