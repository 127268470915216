@import "scssShared/variables.scss";

.datepicker-wrapper {
  display: flex;
  align-items: center;

  &.custom {
    position: relative;
  }

  .time-tooltip-wrapper {
    margin-left: 5px;
    color: $color_grey;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .react-datepicker-popper {
    position: absolute;
    top: 100%;
    margin-top: 10px;
    width: 305px;
    box-sizing: border-box;
    border: $border_grey_light_thin;
    border-radius: $br_small;
    background-color: $color_white;
    box-shadow: $bs_small;

    .custom-header {
      display: flex;
      align-items: stretch;
      align-content: stretch;
      border-bottom: $border_grey_light_thin;

      button {
        height: 48px;
        margin: 0;
        width: 50%;
        box-sizing: border-box;
        padding: 0;
        border: 0;

        &:first-child {
          border-radius: 0;
          border-top-left-radius: $br_small;
        }

        &:last-child {
          border-radius: 0;
          border-top-right-radius: $br_small;
        }
      }
    }

    .time-input-wrapper {
      display: flex;
      align-items: center;
      padding: 10px;
      border-bottom: $border_grey_light_thin;
      background-color: $color_catskill_white;

      .time-input-label {
        flex: 1;
        text-transform: uppercase;
        color: $color_grey;
        font-size: 13px;
        font-weight: 700;
      }

      .time-input {
        width: 20px;
      }

      .time-input-info {
        color: $color_grey;
        font-size: 14px;
        margin-left: 10px;
      }
    }

    .calendar-dropdown {
      width: 303px;
      box-sizing: border-box;
      border-radius: 0;
      box-shadow: none;
      border: none;

      .react-datepicker__triangle {
        display: none;
      }
    }

    .calendar-buttons-wrapper {
      border-top: $border_grey_light_thin;
      padding: 10px;
      display: flex;
      justify-content: space-between;
    }
  }

  &.between {
    > input {
      width: 110px !important;
    }
  }

  .natural-language {
    width: 305px;
    box-sizing: border-box;
    background: $color_white;
    border: $border_grey_light_thin;
    border-bottom-left-radius: $br_small;
    border-bottom-right-radius: $br_small;
    padding: 15px 10px;

    input {
      width: 100%;
      box-sizing: border-box;
      padding-right: 45px;
      height: 44px;
    }

    .help-box {
      margin: 5px 10px 0;

      p {
        &.help-message {
          font-size: 12px;
          line-height: 14px;
          color: $color_grey;
          margin-bottom: 0;

          a {
            text-transform: inherit;
            font-size: inherit;
          }
        }
      }
    }

    .nat-lang-form {
      position: relative;
    }

    .nat-lang-submit-button {
      width: 34px;
      height: 34px;
      padding: 0;
      position: absolute;
      right: 5px;
      top: 5px;
    }
  }
}
