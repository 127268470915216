@import "scssShared/variables.scss";
@import "scssShared/mixins.scss";

.header {
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 7;
  margin-top: -95px;
  margin-bottom: 20px;
  width: 1200px;
  margin-left: -21px;

  .bannerNameInput {
    display: flex;
    white-space: nowrap;
    align-items: center;

    label {
      font-family: $font_family_neue;
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: $color_grey;
      width: 70px;
      display: block;
      margin: 0;
    }

    input {
      width: 250px;
    }
  }

  .buttons {
    display: flex;

    .disabledButtonToggleWrapper {
      margin-right: 10px;
      display: flex;
      align-items: center;

      label {
        @include input-label-styles;
        display: block;
        margin: 0 5px 0 0;
      }
    }
  }
}

.wrapper {
  width: 1200px;
  margin: 95px auto 0;
}

.content {
  padding: 20px;

  &.triggerSettings {
    position: relative;
    z-index: 1;
  }

  .bannerContent {
    margin-top: 5px;

    .imageUrlField {
      width: 100%;
      margin-top: 20px;
    }

    &.imageContent {
      display: block;

      .imageUploadRow {
        margin-top: 20px;
      }

      .imageUploadRowContent {
        width: 100%;
      }

      .imageUploadDescription {
        color: $color_grey_lighter;
        font-size: 12px;
        margin: 5px 0 0;
      }
    }

    &.htmlContent {
      display: flex;
    }
  }

  .minimizedHtmlField {
    margin-top: 20px;
  }
}

.bannerContent {
  display: flex;
  padding: 0;
  justify-content: space-between;
}

.leftPart {
  padding: 20px;
  width: 803px;
}

.rightPart {
  background: $color_catskill-white;
  border-left: 1px solid $color_border;
  width: 355px;
  box-sizing: border-box;
  border-top-right-radius: $br_medium;
  border-bottom-right-radius: $br_medium;
  padding: 20px 15px;

  h4 {
    color: $color_grey_lighter;
    font-size: 11px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    margin: 0 0 4px;
  }

  .labelLike {
    font-size: 10px;
    font-weight: bold;
    text-transform: uppercase;
  }

  .rightPartInnerBox {
    background-color: $color_white;
    border: 1px solid $color_border;
    border-radius: $br_medium;
    padding: 15px;
  }

  .minimizedFirstToggleWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    > label {
      @include input-label-styles;
      margin: 0 10px 0 0;
    }
  }
  .tabContent {
    padding: 15px;
    input {
      margin-bottom: 5px;
    }
  }
}

.nextBox {
  margin-top: 10px;
}

.bannerBox {
  position: relative;
  z-index: 0;
}

.minimizedSettingsContent {
  margin-top: 12px;
}

.paperInnerHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  h3 {
    margin: 0 0 15px;
  }

  .titleWithToggle {
    display: flex;
    align-items: center;

    h3 {
      margin: 0;
    }
  }

  .minimizedToggleWrapper {
    margin-left: 20px;
  }
}

.settingsBoxSelect {
  font-size: 0;
  position: relative;
  z-index: 5;
}

.buttonMargin {
  margin-right: 10px;
}

.conditionBuilderWrapper {
  padding-bottom: 25px;
  margin-bottom: 25px;
  border-bottom: 1px solid $color_border;
  position: relative;
  z-index: 6;

  .conditionsHeader.empty {
    margin-bottom: 10px;
  }
}

.bannerToggleBox {
  background: $color_white;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;

  p {
    margin: 0;
  }
}

.fieldWithTooltip {
  position: relative;
  font-size: 0;
  width: 100%;
  .tooltip {
    position: absolute;
    right: 0;
    top: 0;
  }
}

.htmlBannerProperties {
  width: 100%;
  margin-left: 30px;
}

.bannerWidthField {
  margin-top: 20px;
  width: 100%;
}

.bannerMinimizedWidthField {
  width: 100%;
  margin-top: 20px;
}

.closeButtonToggleWrapper {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  > label {
    @include input-label-styles;
  }
}

.greyBox {
  padding: 10px;
  background: $color_catskill-white;
  border: 1px solid $color_border;
  border-radius: $br_medium;

  .greyBoxTitle {
    font-size: 16px;
    line-height: 19px;
    margin: 0;
    color: $color_black;
    margin-bottom: 18px;
  }
}

.frequencyCapPerUserBox {
  margin-right: 10px;
  flex-basis: 58%;
}

.priorityBox {
  width: 100%;
  display: flex;
  .greyBoxTitle {
    line-height: 42px;
    margin-bottom: 0;
  }

  .tooltip {
    margin-top: 16px;
    margin-left: 4px;
    margin-right: 12px;
  }

  .priorityFieldWrapper {
    width: 100%;

    .prioritySelect {
      width: 70px;
    }
  }
}

.displayConditionBox {
  flex-basis: 28%;
  margin-left: 10px;

  .displayConditionContent {
    display: flex;
    align-items: flex-end;

    > span {
      margin: 0 10px 6px;
    }
  }

  .displayConditionSelect {
    min-width: 124px;
    width: 100%;
    font-size: 0;

    &.scrollValue {
      max-width: 124px;
    }
  }

  .scrollPercentageField {
    position: relative;

    &:after {
      content: "%";
      display: flex;
      align-items: center;
      box-sizing: border-box;
      padding-left: 2px;
      width: 30px;
      height: 40px;
      background-color: $color_white;
      color: $color_black;
      position: absolute;
      right: 2px;
      top: 2px;
    }
  }
}

.freqPosPrioRow {
  display: flex;
}

.frequencyCapFieldsRow {
  display: flex;
  align-items: flex-end;
  > span {
    font-size: 16px;
    line-height: 18px;
    color: $color_black;
    display: block;
    margin: 0 10px 12px;
  }
}

.frequencyCapCountField {
  width: 25.5%;
}
.frequencyCapWindowSizeField {
  width: 140px;
}
.frequencyCapUserCountFieldWrapper {
  width: 25.5%;
}
.frequencyCapWindowTypeSelect {
  z-index: 2;
}

.tooltipTotalCap {
  width: 240px;
}

.tabs {
  border: 1px solid $color_border;
  border-radius: $br_medium;
  background: $color_white;
  overflow: hidden;
  text-align: center;
  padding-top: 12px;
  padding-bottom: 12px;
  color: $color_grey_lighter;
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase;

  &.imageBannerTabs {
    .middleButton {
      &:before,
      &:after {
        opacity: 0;
      }
    }
  }
}

.tabContent {
  border: 1px solid $color_border;
  border-radius: $br_medium;
  background: $color_white;
  overflow: hidden;
  padding: 12px;

  & > *:not(:last-child) {
    margin-bottom: 12px;
  }
}

.minimizedHideValuesInputWrapper {
  margin-top: 20px;
  position: relative;

  .minimizedHideValueInput {
    input {
      padding-right: 85px;
    }
  }

  .minimizedHideValueButtonAdd {
    position: absolute;
    right: 5px;
    top: 23px;
  }

  .minimizedHideValues {
    > span,
    > div {
      margin: 5px 5px 0 0;
    }
  }
}

.positionMarginsFields {
  display: flex;
  gap: 10px;
  margin-top: 20px;

  .positionMarginField {
    input {
      width: 140px;
      padding-top: 7px;
      padding-bottom: 7px;
    }
  }
}

.minimizedBannerPaper {
  margin-bottom: 10px;
}

.bannerSettingsContent {
  margin-top: 12px;
}
