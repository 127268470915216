@import "scssShared/variables.scss";

.expandWrapper {
  width: 595px; // Not sure what else to do here

  .container {
    position: fixed;
    z-index: 4;
    transition: all 0.2s ease-out;
  }

  .overlay {
    position: fixed;
    z-index: 3;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba($color_black, 0.5);
    transition: all 0.2s ease-out;
    opacity: 1;
  }

  &.collapsed {
    .container {
      position: unset;
      z-index: unset;
      transition: all 0.2s ease-in;
      height: 100%;
    }

    .overlay {
      display: none;
    }
  }

  &.expandStart {
    .overlay {
      opacity: 0;
    }
  }

  &.expanding,
  &.expanded {
    .overlay {
      transition: all 0.2s ease-in;
    }
  }

  &.collapseStart {
    .overlay {
      opacity: 0;
    }
  }
}

.header {
  min-height: 56px;
  padding: 10px 15px;
  gap: 10px;

  .title {
    font-weight: 700;
    color: $color_grey_lighter;
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    flex: 1;
  }

  .interactions {
    font-size: 11px;
    color: $color_grey_lighter;

    .interactionsValue {
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: 0.1em;
    }
  }

  .subcategoryPicker {
    width: 210px;
  }
}

// TODO: This is copied from analytics page; improve IconButton component and then delete this
.expandButton button {
  width: 28px;
  height: 28px;
  border: $border_grey_light_thin;
  color: $color_grey_lighter !important;
  border-radius: $br_medium;
  padding: 0;
  min-width: 28px;
  min-height: 28px;
  margin-left: 5px;

  &:hover {
    opacity: 1;
    color: $color_black;
    border-color: $color_grey;
    box-shadow: $bs_large;
  }

  &:focus {
    outline: none;
    box-shadow: $button_white_focus_box_shadow;
  }
}

.chartWrapper {
  height: calc(100% - 56px);
}
