@import "scssShared/variables.scss";
@import "scssShared/flexbox.scss";

.export-log-page {
  .export-details {
    @include flexAcross();
    justify-content: space-between;
    .export-timeline {
      width: calc(50% - 10px);
      .timeline-header {
        .navigation-block {
          @include flexAcross();
          align-items: center;
        }
      }
      .segment-export-timeline {
        .timeline-table {
          .head.timeline-status {
            padding-left: 44px;
          }
          td {
            font-size: 14px;
            font-weight: normal;
            padding-top: 14px;
            padding-bottom: 14px;
          }
        }
        .align-right {
          text-align: right;
        }
      }
    }
    .attributes {
      width: calc(50% - 10px);

      .no-conditions-message {
        text-align: center;
        font-size: 14px;
        color: $color_grey_lighter;
        margin: 10px;
      }

      .conditions-description-wrapper {
        font-weight: bold;
        text-transform: uppercase;
        text-align: center;
        color: $color_primary;
        line-height: 22px;
        font-size: 18px;
        letter-spacing: 1px;
        width: 100%;
        background: $color_grey_secondary_menu;
        border: 1px solid $color_border;
        border-radius: $br_medium;
        margin-bottom: 5px;

        .conditions-description {
          vertical-align: middle;
          margin: 11px 0 9px;
          font-family: $font_family_neue;

          .not {
            margin-right: 3px;
            font-size: 12px;
          }

          .operator {
            color: $color_grey;
            vertical-align: middle;
            font-size: 8px;
            font-family: $font_family_inter;
          }
        }
      }

      .attribute-table {
        th,
        td {
          font-family: $font_family_inter;
          &:last-child {
            text-align: end;
          }
        }
        th {
          font-size: 11px;
          text-transform: uppercase;
          color: $color_grey;
          letter-spacing: 0.1em;
        }
        tr {
          border-bottom: none;
        }
        td {
          font-size: 14px;
          color: $color_grey_lighter;
          vertical-align: baseline;
          font-weight: normal;
          &.attribute-name {
            font-size: 16px;
            line-height: 140%;
            color: $color_black;
            font-weight: bold;
            text-align: left;

            > span {
              color: $color_primary;
              margin-right: 8px;
            }
          }

          &.sub-attribute-name {
            font-size: 14px;
            line-height: 115%;
            font-weight: bold;
            color: $color_black;
            padding-left: 27px;
            position: relative;

            &:not(.no-sub-attribute) {
              &:before {
                width: 8px;
                height: 1px;
                top: 18px;
                left: 15px;
                position: absolute;
                background: $color_border;
                content: "";
                display: block;
              }
            }
          }

          &.operation {
            padding: 12px 0;

            &.sub-filter-operation {
              padding: 12px 0 12px 15px;
            }
            .operation-container {
              position: relative;
              .dashed-line {
                border: 0.5px dashed $color_link_water;
              }
              .operation-lbl {
                position: absolute;
                border-radius: 20px;
                font-size: 11px;
                left: 15px;
                top: -8px;
                padding: 3px 7px 2px 7px;
                color: $color_white;
                background-color: $color_border;
                text-transform: uppercase;
              }
            }

            &.sub-operation {
              padding: 7px 0;
            }
          }
        }
        .attribute-name {
          padding-left: 15px;
          width: 40%;

          &.no-attribute {
            padding: 0;
          }
        }
        .attribute-operation,
        .sub-attribute-operation {
          width: 30%;
          padding-left: 15px;
        }
        .attribute-value,
        .sub-attribute-value {
          padding-right: 15px;
        }
      }
    }
  }
  .export-logs {
    margin-top: 20px;
    .logs-table {
      table-layout: fixed;
      width: 100%;

      .logs-cell {
        white-space: pre-wrap;
        font-family: monospace;
        font-weight: normal;
        vertical-align: top;
        overflow-wrap: break-word;

        &.log-status {
          font-size: 16px;
          line-height: 18px;
        }
        &.time {
          font-size: 14px;
          line-height: 16px;
        }
        &.message {
          font-size: 13px;
          line-height: 15px;
          overflow-x: hidden;

          a {
            font-size: 13px;
            font-weight: normal;
            color: $color_grey;
            text-decoration: underline;

            &:hover {
              text-decoration: none;
            }
          }
        }
        &.error {
          color: $color_warning_red;
        }
      }

      .logs-head,
      .logs-cell {
        &.log-status {
          width: 80px;
        }
        &.time {
          width: 180px;
        }
      }
    }
  }
}

.dashed-line {
  border: 1px dashed #000;
  margin: 0;
  width: 100%;
}
