.confirmModal {
  text-align: left;

  p {
    margin: 0 0 10px;
    line-height: 25px;
    font-size: 17px;

    &:last-of-type {
      margin: 0 0 20px;
    }

    strong {
      word-break: break-word;
    }
  }

  ul {
    margin: 5px 0 0;
  }

  .actionButtons {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .cancelButton {
      margin-left: -11px;
    }
  }
}
