@import "scssShared/variables.scss";
@import "scssShared/animations.scss";
@import "scssShared/mixins.scss";

.wrapper {
  position: relative;
}

.input {
  @include input-styles;
  @include placeholder {
    opacity: 1;
  }

  box-sizing: border-box;
  cursor: pointer;
  min-height: 44px;
  overflow-y: hidden;
  padding: 4px 28px 4px 14px;
  resize: none;
  width: 100%;

  &.error {
    @include input-error-animation;
  }

  &.open {
    @include placeholder {
      opacity: 0.5;
    }
  }
}

.button {
  box-sizing: border-box;
  position: absolute;
  height: 24px;
  top: 10px;
  right: 4px;
  border: none;
  border-left: $border_grey_light_thin;
  color: $color_grey_lighter;
  background: transparent;
  font-size: 11px;
  padding: 3px 7px;

  &:focus {
    outline: none;
  }

  &:hover {
    color: $color_grey;
  }

  &:disabled {
    cursor: not-allowed;
  }
}

.icon {
  pointer-events: none;
}

.label {
  @include input-label-styles;
}

.errorMessage {
  @include input-error-message-styles;
}
