@import "scssShared/variables.scss";

.attribute-filter-form {
  width: 100%;
  margin-bottom: 20px;

  .identity-graph-tooltip {
    svg {
      font-size: 13px;
    }

    .tooltip {
      pointer-events: auto !important;
    }
  }

  .attribute-options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 75px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    &.identity {
      background-color: $color_grey_secondary_menu;
    }

    > h3 {
      font-size: 13px;
      font-family: $font_family_inter;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: $color_grey;
      margin: 0 10px;
    }

    .sources-layout-wrapper {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .sources {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        align-items: center;

        .single-source {
          margin: 10px 20px 10px 0;
          min-height: 30px;
          display: flex;
          align-items: center;

          label {
            position: relative;
            font-family: $font_family_inter;
            font-size: 12px;
            line-height: 15px;
            color: $color_black;
            font-weight: bold;
            padding: 0 0 0 40px;
            cursor: pointer;
            transition: $transition_default;

            .checkbox {
              position: absolute;
              opacity: 0;
              cursor: pointer;
              height: 0;
              width: 0;
            }

            .source-icon {
              width: 32px;
              height: 32px;
              flex-shrink: 0;
              box-sizing: border-box;
              padding: 7px;
              border-radius: 50%;
              position: absolute;
              left: 0;
              top: 50%;
              margin-top: -16px;
              transition: $transition_default;

              img {
                width: 18px;
                height: 18px;
              }

              @each $name, $color in $colors {
                &.#{$name} {
                  background: $color;
                }
              }
            }

            &.disabled {
              cursor: wait;
            }

            &.unchecked {
              color: $color_border;

              .source-icon {
                background-color: $color_border;
              }
            }

            &:hover {
              opacity: 0.5;
            }
          }
        }

        .event-types {
          position: absolute;
          background: $color_white;
          border: 1px solid $color_border;
          border-radius: $br_small;
          box-shadow: $bs_smallest;
          padding: 15px;
          width: 220px;
          z-index: 2;
          right: 5px;

          .checkbox-field {
            margin-bottom: 6px;

            &:last-child {
              margin-bottom: 0px;
            }

            label {
              font-size: 13px;
              font-weight: bold;
              line-height: 16px;
              color: $color_border;
              padding-left: 20px;
              transition: color 0.15s ease-out;

              &.label-checked {
                color: $color_black;
              }

              .checkmark {
                border-color: transparent;
                width: 16px;
                height: 16px;
                padding-top: 0px;

                .icon-check {
                  color: $color_border;
                  transition: color 0.15s ease-out;
                }
              }

              .checkbox {
                &:checked {
                  ~ .checkmark {
                    background-color: transparent;
                    border-color: transparent;

                    .icon-check {
                      color: $color_black;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .layout-picker {
        border-left: 1px solid $color_border;
        height: 36px;
        box-sizing: border-box;
        padding: 1px 2px 1px 12px;
        transition: all 250ms ease-in-out;
        width: 83px;
        flex-shrink: 0;
        display: flex;
        flex-wrap: nowrap;

        .layout-button {
          margin: 0;
          padding: 4px;
          svg {
            path {
              transition: $transition_default;
            }
          }

          &:hover {
            opacity: 1;

            svg {
              path {
                fill: $color_black;
              }
            }
          }

          &.active {
            svg {
              path {
                fill: $color_black;
              }
            }
          }
        }

        &.hidden {
          width: 0;
          padding-left: 0;
          padding-right: 0;
          border-color: transparent;
          opacity: 0;
        }
      }
    }
  }

  .search-field-wraper {
    width: 380px;
    position: absolute;
    left: 50%;
    transform: translateX(-190px);
  }
}

.filter-graph-identifiers {
  margin-top: -1px;
  margin-left: 10px;
  margin-right: 10px;
  box-sizing: border-box;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  width: calc(100% - 20px);
  display: flex;
  justify-content: flex-start;
  padding: 5px 20px;

  .label {
    font-size: 11px;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 0.1em;
    color: $color_grey;
    flex-shrink: 0;
    white-space: pre;
    margin: 10px 10px 0 0;
  }

  .identifier-button {
    background: transparent;
    border: 0;
    color: var(--color);
    text-transform: uppercase;
    font-weight: bold;
    white-space: pre;
    letter-spacing: 0.05;
    font-size: 11px;
    line-height: 13px;
    position: relative;
    padding-left: 20px;
    padding: 5px 5px 5px 20px;
    margin: 5px 5px 5px 0;
    transition: $transition_default;

    &:focus {
      outline: none;
      border: 0;
    }

    &:before {
      position: absolute;
      top: 3px;
      left: 0;
      content: "";
      display: block;
      box-sizing: border-box;
      width: 16px;
      height: 16px;
      border: 2px solid var(--color);
      background-color: var(--background-color);
      border-radius: 8px;
      transition: $transition_default;
    }

    &.not-active {
      opacity: 0.3;
    }
  }
}
