@import "scssShared/variables.scss";

.checkbox-field {
  position: relative;

  label {
    display: block;
    position: relative;
    padding-left: 26px;
    cursor: pointer;
    user-select: none;
    font-size: 15px;
    padding-top: 1px;

    &.disabled {
      cursor: not-allowed;
    }

    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 18px;
      width: 18px;
      background-color: $color_white;
      border: $border_grey_light_thin;
      border-radius: $br_small;
      transition: $transition_default;
      text-align: center;
      font-size: 10px;
      line-height: 16px;
      box-sizing: border-box;

      .icon-check {
        color: $color_white;
      }
    }

    &:hover {
      .checkmark {
        border: $border_grey;
      }
    }

    .checkbox {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;

      &:checked {
        & ~ .checkmark {
          background-color: $color_primary;
          border-color: $color_primary;
        }
      }

      &:disabled {
        & ~ .checkmark {
          opacity: 0.5;
        }
      }
    }
  }
}
