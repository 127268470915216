@import "scssShared/variables.scss";
@import "scssShared/animations.scss";

.identity-stitching-form {
  .ace-editor-wrapper-with-margin {
    margin-left: 10px;
  }
  .form-row {
    display: flex;
    padding: 30px 20px;
    margin: 0;
  }

  .left-part {
    width: 220px;
    flex-shrink: 0;

    h2 {
      font-size: 21px;
      letter-spacing: -0.025em;
      margin: 0;
      line-height: 21px;
      margin-top: 29px;
    }
  }

  label {
    display: block;
  }

  .right-part {
    display: flex;
    flex-grow: 1;
  }

  .attribute-id {
    width: 285px;
  }

  .events-right-content {
    margin-left: 10px;

    .event-item-wrapper {
      display: flex;
      margin-bottom: 5px;
      align-items: center;

      .box {
        border: 1px solid $color_border;
        padding: 10px;
        border-radius: $br_small;
        display: flex;
        margin-right: 5px;
      }

      .event-id {
        width: 275px;
      }
    }
  }

  .add-rule-button {
    margin-top: 5px;
    margin-left: 10px;
  }

  .filter-attribute-select-wrapper {
    .filter-attribute-select-dropdown {
      z-index: 5;
    }
  }
}
