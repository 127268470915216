@import "scssShared/variables.scss";

.container {
  margin-top: 10px;
}

.message {
  font-size: 14px;
  color: $color_grey_lighter;
}

.customerLink {
  text-transform: none;
  font-size: 14px;
  line-height: 2;
}

.customersListFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;

  .pageNumber {
    font-size: 14px;
    color: $color_grey_lighter;
  }
}
