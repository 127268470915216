@import "scssShared/variables.scss";

.itemTypeChip {
  text-transform: uppercase;
  color: $color_grey_lighter;
  border: 1px solid $color_grey_lighter;
  border-radius: 10px;
  font-weight: 700;
  font-size: 9px;
  display: inline-block;
  line-height: 18px;
  height: 20px;
  box-sizing: border-box;
  padding: 0 8px;
  letter-spacing: 0.025em;
  background-color: $color_white;
}

.icon {
  margin-right: 5px;
}
