@import "scssShared/variables.scss";

.tag-picker {
  position: relative;
  width: fit-content;
  margin-right: 230px;
  height: 24px;

  .add-button {
    background: $color_grey_secondary_menu;

    > span {
      display: flex;
      align-items: center;
    }

    .plus {
      font-size: 14px;
      margin-right: -2px;
    }
    .add-label {
      line-height: 20px;
      margin-left: 8px;
      margin-top: 1px;
    }
  }

  .pick-area {
    position: absolute;
    top: 30px;
    left: 0;
    border: $border_grey_light_thin;
    padding: 7.5px;
    width: max-content;
    max-width: 260px;
    background: $color_white;
    z-index: 2;
    border-radius: $br_small;
    box-shadow: $bs_smallest;
    display: inline-block;

    &.right {
      left: auto;
      right: 4px;
    }

    .tag {
      margin: 2.5px;
      cursor: pointer;
    }

    .warning-red {
      white-space: pre;
      font-size: 14px;
      color: $color_warning_red;
      margin: 2.5px;
    }

    &.active {
      .tag {
        opacity: 0.5;

        &.is-hovering {
          opacity: 1;
        }
      }
    }
  }
}
