@import "scssShared/variables.scss";

.event-groups-counts-chart-wrapper {
  position: relative;
  padding: 20px 15px 10px;
  margin-left: 20px;
  width: calc(100% - 20px);
  margin-bottom: 10px;

  h3 {
    font-size: 13px;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: $color_grey;
    margin: 0 0 10px;
  }

  .chart-wrapper {
    height: 108px;
    position: relative;

    > p {
      position: absolute;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      width: 100%;
      top: 50%;
      margin-top: -22px;
    }
  }
}
