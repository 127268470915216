@import "scssShared/variables.scss";

.container {
  padding: 10px;
  background-color: $color_catskill-white;
  border-radius: 8px;
  border: 1px solid $color_border;
  transition: all 0.1 ease;

  &.negation {
    background-color: #e8eaec;
  }

  &.error {
    border-color: $color_warning_red;
    border-style: dashed;
    background-color: rgba($color_warning_red, 0.05);
  }
}

.hiddenAttributeWarning {
  color: darken($color_yellow, 25%);
  border: 1px solid darken($color_yellow, 25%);
  background-color: rgba($color_yellow, 0.1);
  padding: 8px;
  border-radius: $br_medium;
  font-size: 14px;
  margin-bottom: 10px;

  .warningIcon {
    margin-right: 5px;
  }
}

.main {
  display: flex;
  align-items: flex-start;

  & > *:not(:first-child) {
    margin-left: 10px;
  }
}

.verticalAlignmentWrapper {
  height: 34px;
  display: flex;
  align-items: center;
}

.dragHandle {
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $color_grey;
  border-radius: 8px;
  cursor: grabbing;
  transition: all 0.1s ease;
  flex: 0 0 26px;
  margin-right: -5px; // hack

  &:hover {
    background-color: rgba($color_grey, 0.25);
    color: $color_grey_lighter;
  }
}

.symbol {
  font-family: $font_family_inter;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 14px;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $color_royal-blue;
  background-color: rgba($color_royal-blue, 0.1);
  border: 1px solid $color_royal-blue;
  border-radius: 8px;
  flex: 0 0 24px;
  cursor: default;
}

.inputs {
  flex: 1;
  min-width: 0;
}

.topRow {
  display: flex;
  align-items: flex-start;

  & > *:not(:first-child) {
    margin-left: 10px;
  }
}

.attributePicker {
  flex: 1;
}

.inlineLoadingIndicator {
  width: 16px;
  height: 16px;
  padding: 0;

  &::before {
    width: 12px;
    height: 12px;
    top: 0;
    left: 0;
    margin: 0;
  }
}

.bottomRow {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 22px;
}

.segmentedWrapper {
  display: flex;
  font-size: 10px;
  font-weight: 700;
  color: $color_grey;
  align-items: flex-end;

  & > *:not(:first-child) {
    margin-left: 4px;
  }

  .segmentedCount {
    color: $color_grey_lighter;
  }

  .segmentedPercentage {
    font-weight: 400;
  }
}

.errorMessage {
  color: $color_warning_red;
  font-size: 13px;
  margin-top: 5px;
}
