@import "scssShared/variables.scss";

.number-slider-field {
  .horizontal-slider {
    width: 100%;
    height: 20px;
    box-sizing: border-box;

    .slider-track {
      top: 8px;
      height: 4px;
      background: $color_primary;
      border-radius: 3px;

      &.slider-track-1 {
        background: $color_border;
        top: 9px;
        height: 2px;
      }
    }

    .slider-thumb {
      top: 0;
      width: 20px;
      height: 20px;
      cursor: pointer;
      text-align: center;
      color: $color_white;
      box-sizing: border-box;
      font-size: 12px;
      line-height: 12px;
      font-weight: bold;
      background: $color_primary;
      border-radius: $br_small;
      box-sizing: border-box;
      padding: 4px 0 2px;

      &:focus {
        outline: none;
      }
    }

    .slider-mark {
      width: 14px;
      height: 14px;
      box-sizing: border-box;
      border: 1px solid $color_primary;
      background: $color_white;
      position: absolute;
      cursor: pointer;
      vertical-align: middle;
      top: 3px;
      color: $color_primary;
      font-size: 10px;
      font-weight: bold;
      text-align: center;
      line-height: 10px;
      border-radius: $br_small;
      padding-top: 1px;
      margin: 0 3px;

      &.grey {
        color: $color_border;
        border-color: $color_border;
      }
    }
  }
}
