@import "scssShared/variables.scss";

.icon-button {
  font-size: 16px;
  padding: 4px;
  border: 0;
  background: transparent;
  transition: $transition_default;
  cursor: pointer;
  opacity: 1;

  &:focus {
    box-shadow: none;
    outline: none;
  }

  &:hover {
    opacity: 0.5;
  }

  &:active {
    opacity: 1;
  }

  &.primary {
    color: $color_primary;

    &.with-background {
      border: 1px solid rgba($color_primary, 0.33);
      background: rgba($color_primary, 0.1);

      > svg {
        color: $color_primary;
      }

      &:hover {
        border: 1px solid rgba($color_primary, 0.5);
        background: rgba($color_primary, 0.15);
      }
    }
  }

  &.red {
    color: $color_warning_red;

    &.with-background {
      border: 1px solid rgba($color_warning_red, 0.33);
      background: rgba($color_warning_red, 0.1);

      > svg {
        color: $color_warning_red;
      }

      &:hover {
        border: 1px solid rgba($color_warning_red, 0.5);
        background: rgba($color_warning_red, 0.15);
      }
    }
  }

  &.grey {
    color: $color_grey;

    &.with-background {
      border: 1px solid rgba($color_grey, 0.33);
      background: rgba($color_grey, 0.1);

      > svg {
        color: $color_grey;
      }

      &:hover {
        border: 1px solid rgba($color_grey, 0.5);
        background: rgba($color_grey, 0.15);
      }
    }
  }

  &.black {
    color: $color_black;
    &.with-background {
      border: 1px solid rgba($color_black, 0.33);
      background: rgba($color_black, 0.1);

      > svg {
        color: $color_black;
      }

      &:hover {
        border: 1px solid rgba($color_black, 0.5);
        background: rgba($color_black, 0.15);
      }
    }
  }

  &.green {
    color: $color_green;

    &.with-background {
      border: 1px solid rgba($color_green, 0.33);
      background: rgba($color_green, 0.1);

      > svg {
        color: $color_green;
      }

      &:hover {
        border: 1px solid rgba($color_green, 0.5);
        background: rgba($color_green, 0.15);
      }
    }
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
    color: $color_grey;
  }

  &.faded {
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }

    &:disabled {
      opacity: 0.5;
    }
  }

  &.size-tag {
    box-sizing: border-box;
    width: 24px;
    height: 24px;
    font-size: 12px;
    line-height: 12px;
    padding: 0;
  }

  &.with-background {
    border-radius: $br_large;
    opacity: 1;

    > svg {
      opacity: 0.75;
      transition: $transition_default;
    }

    &:hover {
      opacity: 1;

      > svg {
        opacity: 1;
      }
    }

    &:disabled {
      background: rgba($color_very-light-grey, 0.1);
      border: 1px solid rgba($color_very-light-grey, 0.33);

      > svg {
        color: $color_very-light-grey;
        opacity: 0.6;
      }

      &:hover {
        cursor: not-allowed;
        background: rgba($color_very-light-grey, 0.1);
        border: 1px solid rgba($color_very-light-grey, 0.33);

        > svg {
          opacity: 0.6;
        }
      }
    }
  }
}

a {
  &.icon-button {
    &.with-background {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
