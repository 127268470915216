@import "scssShared/variables.scss";

.insight-horizontal-chart {
  position: relative;
  width: 440px;

  &.small {
    width: 240px;
  }

  .attribute-line {
    z-index: 1;
    width: 100%;
    height: 4px;
    background-color: #cccccc;
    position: absolute;
    bottom: 0;

    .percentage-line {
      height: 4px;
      position: absolute;
      bottom: 0;
      left: 0;

      &:before,
      &:after {
        content: " ";
        display: block;
        width: 100%;
        height: 4px;
        background-color: $color_white;
        position: absolute;
      }
    }
  }

  .flex {
    &:after {
      clear: both;
      display: table;
      content: "";
    }
  }

  .label {
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: -0.025em;
    opacity: 0;

    &.above {
      opacity: 1;
    }

    &.absolute {
      opacity: 1;
      position: absolute;
      top: 2px;
      left: 0;
    }
  }

  .horizontal-chart-tooltip {
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    max-width: 395px;
    padding: 10px;
    letter-spacing: normal;
    overflow-wrap: break-word;
  }

  .value-info {
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: -0.025em;
    text-align: right;
    float: right;
    color: $color_grey_lighter;
    margin: 2px 0 4px 4px;
  }

  .bar {
    height: 20px;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    z-index: 2;
    position: relative;
    bottom: 0;
    left: 0;
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;

    .bar-value {
      color: $color_white;
      font-size: 10px;
      line-height: 12px;
      letter-spacing: -0.025em;
      font-weight: 500;

      &.next-to-bar {
        position: absolute;
        top: 3px;
        right: -3px;
        transform: translateX(100%);
        color: $color_black;
      }
    }
  }

  @each $name, $color in $colors {
    &.#{$name} {
      .percentage-line {
        &:after {
          background: rgba($color, 0.6);
        }
      }

      .bar {
        background: $color;
      }
    }
  }
}
