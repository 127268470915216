@import "scssShared/variables.scss";

.create-event {
  .create-event-header {
    position: sticky;
    top: 0;
    justify-content: space-between;
    z-index: 1;

    .header-buttons {
      .button {
        margin-left: 10px;
      }
    }
  }

  .create-event-content {
    padding: 0;
  }
}
