@import "scssShared/variables.scss";
@import "scssShared/flexbox.scss";

.sources-destinations {
  .connected-sources-container,
  .connected-destinations-container {
    .header {
      min-height: 0;
      padding: 25px;
    }

    .sources-table,
    .destinations-table {
      .date-added {
        width: 10.5%;
      }

      .engagement-viz {
        width: 12%;
        text-align: right;
      }

      .diagnostic-dashboard-viz {
        width: 14%;
        text-align: right;
      }

      .service-name {
        width: 22%;
      }

      .service-id {
        width: 40px;
      }

      .icon {
        width: 55px;
        box-sizing: border-box;
        padding-right: 0;
        padding-left: 0;
        img {
          width: 35px;
          padding: 0 10px;
          margin-top: 3px;
        }
      }
      .description,
      .created {
        font-weight: initial;
        color: $color_grey_lighter;
      }

      .description {
        white-space: pre-line;
      }
    }
  }
  .connected-sources-container {
    .header {
      .name,
      .created {
        width: 20%;
      }
    }
  }
  .mi-workspace-tooltip,
  .diagnostic-dashboard-tooltip {
    max-width: 300px;
    font-size: 13px;
    font-weight: normal;
    text-transform: none;
    text-align: left;
    letter-spacing: 0;
  }
  .connected-destinations-container {
    .destinations-table {
      .service-name {
        width: 22%;
      }

      .exported-attributes-col {
        width: 20%;
      }

      .mandatory-attributes-col {
        width: 20%;
      }

      .date-added {
        width: 10%;
      }

      .mi-workspace {
        width: 14%;
      }

      .parameters {
        width: 16%;
      }
    }

    .attribute-container {
      .no-attributes {
        font-weight: 400;
        color: $color_grey_lighter;
      }

      .attributes {
        .filter-attribute-select-wrapper {
          .filter-attribute-select-dropdown {
            z-index: 3;
          }
        }

        .mantatory-attributes-operator {
          margin-bottom: 3px;
          display: flex;
          align-items: center;
          > span {
            margin-right: 5px;
            font-size: 11px;
            letter-spacing: 0.05em;
            color: $color_grey;
            text-transform: uppercase;
          }
        }

        @include flowAcross();
        .attribute {
          margin: 2.5px 5px 2.5px 0;
          white-space: normal;
          display: flex;
          align-items: center;
          padding: 4px 8px;
          line-height: 12px;
          height: auto;
          min-height: 24px;
          position: relative;

          &.clickable {
            &:hover {
              .close {
                top: 50%;
                transform: translateY(-7px);
              }
            }
          }

          &.read-only {
            &:hover {
              box-shadow: none;
            }
          }

          .tag-actions {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: rgba($color_white, 0.8);
            border-radius: $br_small;
            opacity: 0;
            transition: $transition_default;

            button {
              color: $color_grey_lighter;
              padding: 2px;
              min-width: 16px;
              min-height: 16px;
              font-size: 13px;

              &:first-child {
                margin-left: 4px;
              }

              &:last-child {
                margin-right: 4px;
              }
            }
          }

          &.exported-attr {
            &.show-tooltip {
              .tag-actions {
                opacity: 1;
              }
            }
          }
        }
        .filter-attribute-select-wrapper {
          margin: 2.5px 0;
          height: 24px;
        }
        .add-data {
          margin-top: 2.5px;
          .button {
            background: $color_grey_secondary_menu;

            > span {
              display: flex;
              align-items: center;
            }

            &:hover {
              color: $color_black;
            }
          }
          .plus {
            font-size: 14px;
            margin-right: -2px;
            float: left;
          }
          .add-label {
            line-height: 20px;
            margin-left: 8px;
            margin-top: 1px;
          }
        }
      }
    }
  }
  .connected-sources-container {
    margin-bottom: 30px;
  }
}
