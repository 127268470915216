@import "scssShared/variables.scss";

.name-form {
  position: relative;
  width: 320px;

  .link-button {
    position: absolute;
    right: 14px;
    bottom: 13px;
    background: transparent;
    border: 0;
    font-size: 11px;
    line-height: 13px;
    color: $color_grey_lighter;
    text-decoration: underline;
    text-transform: uppercase;
    font-weight: bold;
    padding: 4px;
    display: block;
    height: 20px;

    &:focus {
      box-shadow: none;
      outline: none;
    }

    &:hover {
      text-decoration: none;
    }

    &.edit-mode {
      color: $color_primary;
    }
  }

  .close-icon-button {
    padding: 0;
    border: 0;
    background: transparent;
    color: $color_primary;
    transition: $transition_default;
    position: absolute;
    bottom: 13px;
    left: -9px;
    height: 19px;
    width: 19px;
    margin: 0;
    border: 1px solid $color_primary;
    border-radius: 50%;
    background: #f9e0dd;
    font-size: 13px;

    &:focus {
      box-shadow: none;
      outline: none;
    }
  }

  .tooltip-trigger {
    width: calc(100% - 75px);
    height: 44px;
    position: absolute;
    bottom: 0px;
    left: 15px;
    box-sizing: border-box;

    &.edit {
      pointer-events: none;
    }
  }

  .tooltip {
    padding: 10px;
  }

  .name {
    padding: 2px 60px 2px 15px;
    position: absolute;
    min-height: 44px;
    width: 100%;
    box-sizing: border-box;
    bottom: 0;
    transform: translateY(calc(-22px + 50%));
    display: flex;
    align-items: center;
    color: $color_black;
    font-family: $font_family_neue;
    font-weight: 500;
    letter-spacing: normal;
    background: rgba($color_grey, 0.2);
    border: 1px solid $color_border;
    border-radius: $br_small;
    word-break: break-word;

    &.edit-mode {
      display: none;
    }

    &.fs-15 {
      font-size: 15px;
      line-height: 18px;
    }

    &.fs-14 {
      font-size: 14px;
      line-height: 17px;
    }

    &.placeholder {
      color: $color_grey;
      font-weight: 400;
    }
  }

  .text-field {
    &.error {
      input {
        border-color: $color_warning_red;
      }
    }

    label {
      font-size: 11px;
      line-height: 15px;
      font-weight: bold;
      font-family: $font_family_inter;
      letter-spacing: 1px;
      color: $color_grey;
      display: block;
      text-transform: uppercase;
    }

    input {
      visibility: hidden;
      width: 100%;
      box-sizing: border-box;
      color: $color_black;
      font-family: $font_family_neue;
      font-weight: 500;
      padding: 0 60px 0 15px;
      height: 44px;
      background: rgba($color_grey, 0.2);
      transition: none;

      &:disabled {
        color: $color_black;
        -webkit-text-fill-color: $color_black;
        opacity: 1;
      }
    }

    &.edit-mode {
      input {
        visibility: visible;
        background: #f9e0dd;
        border-color: $color_primary;
        box-shadow: 0 1px 3px 0px rgba(254, 127, 102, 0.25);
      }
    }

    &.fs-15 {
      input {
        font-size: 15px;
      }
    }

    &.fs-14 {
      input {
        font-size: 14px;
      }
    }
  }

  &:hover {
    .text-field {
      &:not(.edit-mode) {
        input {
          background: rgba($color_grey_lighter, 0.2);
          border-color: $color_grey;
        }
      }
    }
  }
}

.name-not-editable {
  width: 100%;
  word-wrap: break-word;

  h2 {
    color: $color_black;
    margin: 0;
    font-weight: 500;
  }

  &.fs-15 {
    h2 {
      font-size: 15px;
      line-height: 18px;
    }
  }

  &.fs-14 {
    h2 {
      font-size: 14px;
      line-height: 17px;
    }
  }

  .read-only-sign {
    white-space: pre;
    color: $color_grey_lighter;
    height: 24px;
    border: $border_grey_light_thin;
    border-radius: $br_small;
    box-sizing: border-box;
    text-transform: uppercase;
    font-size: 9px;
    letter-spacing: 0.5px;
    display: inline-block;
    line-height: 24px;
    padding: 0 8px;
    vertical-align: middle;

    .eye {
      margin-bottom: 1px;
    }
  }
}
