@import "scssShared/variables.scss";

.container {
  margin-top: 10px;
  margin-bottom: 10px;
  color: $color_grey_lighter;

  &:last-child {
    margin-bottom: 0;
  }

  * {
    box-sizing: border-box;
  }

  a.highlightedText {
    text-decoration: none;
    text-transform: none;
    font-size: inherit;

    &:hover {
      text-decoration: underline;
    }
  }
}

.main {
  display: flex;
  align-items: center;
  border: solid 1px $color_border;
  border-radius: 8px;
  padding: 10px;
  background-color: $color_white;
}

.unreadNotificationBadge {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: $color_warning_red;
  flex-shrink: 0;
  margin-right: 6px;
}

.categoryIcon {
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  border-radius: 50%;
  color: $color_white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}

.body {
  font-size: 14px;
  margin: 0 10px;
  flex: 1;
  line-height: 1.25;

  .secondaryText {
    font-size: 12px;
    margin-top: 2px;
  }

  .highlightedText {
    font-weight: 700;
    color: $color_black;
  }
}

.timestamp {
  font-size: 12px;
  margin-right: 5px;
}

.expandButtonWrapper {
  .expandButton {
    font-size: 16px;
    outline: none;
    border: none;
    background-color: transparent;
    padding: 0 5px;
    visibility: hidden;
    color: $color_grey_lighter;
  }
}

.expansion {
  font-size: 12px;
  line-height: 1.5;
  width: calc(100% - 20px);
  border: solid 1px $color_border;
  border-top: none;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: $color_catskill-white;
  padding: 10px;
  margin: auto;

  .highlightedText {
    text-transform: initial;
    font-size: 12px;
    font-weight: 700;
    color: $color_black;
  }
}

.container.isDropdown {
  .main {
    .categoryIcon {
      width: 24px;
      height: 24px;
      font-size: 10px;
    }

    .expandButtonWrapper {
      .expandButton {
        font-size: 14px;
      }
    }
  }
}

.container.read {
  .main {
    background-color: $color_catskill-white;

    .unreadNotificationBadge {
      background-color: transparent;
    }
  }
}

.container.hasDetails {
  .main {
    .expandButtonWrapper {
      .expandButton {
        visibility: visible;
      }
    }
  }
}

.container.highImportance:not(.read) {
  .main {
    border-color: $color_warning_red;

    .body {
      .highlightedText {
        color: $color_warning_red;
      }
    }
  }
}

.container.highImportance {
  .main {
    .categoryIcon {
      background-color: $color_warning_red;
    }
  }
}

.container.mediumImportance:not(.read) {
  .main {
    border-color: $color_yellow_waiting;

    .body {
      .highlightedText {
        color: $color_yellow_waiting;
      }
    }
  }
}

.container.mediumImportance {
  .main {
    .categoryIcon {
      background-color: $color_yellow_waiting;
    }
  }
}

.container.lowImportance:not(.read) {
  .main {
    border-color: $color_blue_running;

    .body {
      .highlightedText {
        color: $color_blue_running;
      }
    }
  }
}

.container.lowImportance {
  .main {
    .categoryIcon {
      background-color: $color_blue_running;
    }
  }
}
