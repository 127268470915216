@import "scssShared/variables.scss";

.dragAndDrop {
  position: relative;
  border: 1px dashed $color_very-light-grey;
  background: #fafafa;
  padding: 10px;
  border-radius: $br_large;
}

.dragging {
  &:after {
    color: $color_grey;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    width: 100%;
    height: 100%;
    background: rgba($color_very-light-grey, 0.75);
    content: "drop here";
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 0;
  }
}
