@import "scssShared/variables.scss";
@import "scssShared/mixins.scss";

.box {
  background-color: $color_white;
  border: 1px solid $color_border;
  border-radius: $br_medium;
  padding: 15px;
}

.closeButtonToggleWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;

  label {
    @include input-label-styles;
  }
}

.colors {
  div {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
