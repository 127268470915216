.trashFilterForm {
  margin-left: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.itemTypeSelect {
  width: 280px;
}

.searchFieldWrapper {
  width: 240px;
  box-sizing: border-box;
}
