@font-face {
  font-family: "Neue Hans Kendrick";
  src: url("../fonts/NeueHansKendrick-Regular.woff2") format("woff2"),
    url("../fonts/NeueHansKendrick-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Neue Hans Kendrick";
  src: url("../fonts/NeueHansKendrick-Bold.woff2") format("woff2"),
    url("../fonts/NeueHansKendrick-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Neue Hans Kendrick";
  src: url("../fonts/NeueHansKendrick-Medium.woff2") format("woff2"),
    url("../fonts/NeueHansKendrick-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Neue Hans Kendrick";
  src: url("../fonts/NeueHansKendrick-Light.woff2") format("woff2"),
    url("../fonts/NeueHansKendrick-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Inter UI";
  src: url("../fonts/Inter-UI-Regular.woff2") format("woff2"),
    url("../fonts/Inter-UI-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Inter UI";
  src: url("../fonts/Inter-UI-Bold.woff2") format("woff2"),
    url("../fonts/Inter-UI-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Inter UI";
  src: url("../fonts/Inter-UI-Medium.woff2") format("woff2"),
    url("../fonts/Inter-UI-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}
