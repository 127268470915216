@import "scssShared/variables.scss";
.dropdown-menu {
  position: relative;
  box-sizing: border-box;
  .dropdown-toggle-button {
    border: 0;
    font-size: 16px;
    color: $color_black;
    background: transparent;

    &:focus {
      outline: 0;
    }
  }

  .dropdown-content {
    border: $border_grey_light_thin;
    padding: 15px;
    position: absolute;
    z-index: 100;
    background: $color_white;
    border-radius: $br_large;
    margin-top: 10px;
    box-sizing: border-box;
    min-width: 240px;
    font-size: 16px;
    line-height: 19px;
    color: $color_black;
    overflow: hidden;
    box-shadow: $bs_large;

    .dropdown-list {
      margin: 0;
      padding: 0;
      box-sizing: border-box;

      li {
        display: inline-block;
        width: calc(100% - 10px);
        white-space: nowrap;
        margin: 7px 0px;

        &:first-child {
          margin-top: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    a,
    span {
      font-size: 16px;
      line-height: 19px;
      text-decoration: none;
      color: $color_black;
      text-transform: none;
      cursor: pointer;

      &:hover {
        color: $color_primary;
      }

      &.active {
        color: $color_primary;
      }
    }

    &.right {
      right: -10px;
    }
  }
}
