@import "scssShared/variables.scss";

.container {
  box-sizing: border-box;
  width: 1240px;
  margin: 0 auto;
  padding: 0 20px;
  position: relative;
}

.viewSwitchWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  transition: $transition_short;
  position: relative;
}

.viewSwitch {
  margin: 0 5px;
}

.line {
  height: 1px;
  background-color: $color_border;
  flex: 1;
}

.topBar {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
}

.backLinkWrapper {
  flex: 1;
  transition: $transition_short;

  .link {
    text-transform: none;
    font-size: 14px;
    text-decoration: none;
    color: $color_grey;

    &:hover {
      color: $color_grey_lighter;
    }

    .icon {
      margin-right: 10px;
    }
  }
}

.buttonsWrapper {
  transition: $transition_short;

  .button {
    margin-left: 10px;
  }
}

.mainContent {
  display: flex;
}

.leftPanel {
  width: 330px;
  margin-right: 10px;
  transition: $transition_short;
  position: relative;
}

.card {
  background-color: $color_catskill-white;
  margin-bottom: 10px;
  padding: 15px;
}

.title {
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: $color_grey;
  margin-right: 15px;
  align-self: center;
}

.flexWrapper {
  display: flex;
  align-items: center;
}

.stretch {
  flex: 1;
}

.nameWrapper {
  display: flex;
  align-items: center;
  min-height: 34px;

  .name {
    font-weight: 700;
    word-break: break-word;
  }

  .segmentId {
    color: $color_grey;
    white-space: nowrap;
    font-size: 13px;
    margin-left: 15px;
  }
}

.usersWrapper {
  display: flex;
  align-items: center;
  border-top: $border_grey_light_thin;
  padding-top: 15px;
  margin-top: 15px;
}

.tagsCard {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @extend .card;

  .tagPickerWrapper {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -5px -5px 0;

    // TODO: refactor
    :global(.tag),
    .tagPicker {
      margin: 0 5px 5px 0px;
    }
  }
}

.notesCard {
  @extend .card;
  padding: 0;

  .notesCardHeader {
    padding: 15px;
    display: flex;
  }
}

.openButton {
  border: none;
  background-color: transparent;
  outline: none;
  padding: 10px;
  margin: -10px;
  color: $color_grey;
  font-size: 14px;
  transition: all 0.1s ease;

  &:hover {
    color: $color_grey_lighter;
  }
}

.viewWrapper {
  width: 860px;
  transition: $transition_short;
  position: relative;
}

.conditionsOverviewWrapper {
  position: sticky;
  top: 0;
  z-index: 4;
}

.conditionBuilderWrapper {
  padding: 10px 10px 20px 10px;
  margin-top: 10px;
}

.noAccessMessage {
  padding: 25px;
  color: $color_grey_lighter;
  text-align: center;
}

.insightsHeader {
  position: relative;

  .insightsSearchWrapper {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
  }
}
