@import "scssShared/variables.scss";

.singleCondition {
  margin: 10px 0;
}

.emptyMessage {
  color: $color_grey_lighter;
  text-align: center;
  margin-top: 10px;
}

.addButton.noConditions {
  margin-top: 10px;
}
