@import "scssShared/variables.scss";

.authenticated-layout {
  min-height: 100vh;
  position: relative;

  .content-wrap {
    padding-bottom: 140px;

    &.no-padding {
      padding-bottom: 0px;
    }
  }
}
