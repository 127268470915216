@import "scssShared/variables.scss";

.progress-bar {
  position: relative;

  .labels {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 140px;
    height: 140px;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    > span {
      display: block;
      text-align: center;
      letter-spacing: -0.025em;

      @each $name, $color in $colors {
        &.#{$name} {
          color: rgba($color, 0.6);
        }
      }

      &.grey {
        color: $color_grey;
      }

      &.single-label {
        font-size: 18px;
        line-height: 22px;

        &.known {
          width: 66px;
        }

        &.unknown {
          width: 86px;
        }
      }

      &.double-label {
        font-size: 12px;
        line-height: 15px;

        &.known {
          width: 46px;
        }

        &.unknown {
          width: 56px;
          margin-top: 4px;
        }
      }
    }
  }
}
