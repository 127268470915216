@import "scssShared/variables.scss";
@import "scssShared/flexbox.scss";

.status-element {
  @include flexAcross();
  align-items: center;
  line-height: 18px;
  font-size: 16px;

  span {
    display: block;
  }

  &.left {
    text-align: left;
    float: left;
  }

  &.right {
    text-align: right;
    float: right;
  }

  &.canceled {
    color: $color_grey_darker;
    .right-icon,
    .left-icon {
      background-color: $color_grey_darker;
    }
  }

  &.error {
    color: $color_warning_red;
    .right-icon,
    .left-icon {
      background-color: $color_warning_red;
    }
  }

  &.finished {
    color: $color_green;
    .right-icon,
    .left-icon {
      background-color: $color_green;
    }
  }

  &.waiting,
  &.warning {
    color: $color_yellow_waiting;
    .right-icon,
    .left-icon {
      background-color: $color_yellow_waiting;
    }
  }

  &.running {
    color: $color_blue_running;
    .right-icon,
    .left-icon {
      background-color: $color_blue_running;

      svg {
        margin-left: 2px;
      }
    }
  }

  .col {
    &.left-icon {
      margin-right: 8px;
      width: 28px;
      height: 28px;
      color: $color_white;
      text-align: center;
      border-radius: 14px;
      font-size: 14px;
      padding-top: 5px;
      box-sizing: border-box;
    }
  }

  .status-text {
    font-weight: bold;
  }
}
