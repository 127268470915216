@import "scssShared/variables.scss";

.avatar-wrapper {
  position: relative;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  .avatar-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  .inicials {
    color: $color_primary;
    position: relative;
    font-size: 11px;
    font-weight: 500;
  }

  .disabled-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $color_white;
    border-radius: 50%;
    opacity: 0.5;
  }

  &.disabled-look {
    img {
      filter: grayscale(100%);
    }

    .inicials {
      color: $color_grey;
    }
  }
}
