@import "scssShared/variables.scss";

.logout-page {
  background-color: $color_white;
  width: 100%;
  height: 100%;

  .left-part {
    width: 57%;
    float: left;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 40px;
    box-sizing: border-box;

    h1 {
      margin: 10px 0 0;
      font-size: 32px;
      line-height: 38px;

      strong {
        display: block;
        color: $color_primary;
      }
    }

    p {
      margin: 10px 0 0;
      font-size: 18px;
      line-height: 22px;
      color: $color_grey_lighter;

      a {
        color: $color_primary;
        font-size: 18px;
        text-transform: none;
        font-weight: bold;
      }
    }

    .login-button {
      margin: 25px 0 0;
      width: 150px;
    }
  }

  .right-part {
    width: 43%;
    float: right;
    height: 100%;
    display: flex;
    align-items: center;
    max-width: 620px;

    .team-avatars-wrapper {
      height: 100%;
      max-height: 720px;
      width: 100%;
      position: relative;
      background-image: url("../../images/meiro-uni-symbol.svg");
      background-repeat: no-repeat;
      background-position: right center;
      background-size: 64% auto;

      .member {
        width: 64px;
        height: 64px;
        border-radius: 50%;
        position: absolute;
        overflow: hidden;
        transition: opacity 0.5s ease-in-out;

        &.visible {
          opacity: 1;
        }

        &.hidden {
          opacity: 0;
        }

        img {
          width: 100%;
          height: 100%;
        }

        &.member-1 {
          top: 3.33%;
          right: 54.19%;
        }

        &.member-2 {
          top: 9.3%;
          right: 72.9%;
        }

        &.member-3 {
          top: 20.55%;
          right: 66.29%;
        }

        &.member-4 {
          top: 32.64%;
          right: 80.64%;
        }

        &.member-5 {
          top: 45.13%;
          right: 71.45%;
        }

        &.member-6 {
          top: 55.69%;
          right: 85%;
        }

        &.member-7 {
          top: 63.88%;
          right: 66.29%;
        }

        &.member-8 {
          top: 78.47%;
          right: 74.67%;
        }

        &.member-9 {
          top: 87.36%;
          right: 45.48%;
        }
      }
    }
  }

  &:after {
    clear: both;
    content: "";
    display: block;
  }
}
