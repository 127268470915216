@import "scssShared/variables.scss";

.notes {
  position: relative;

  .note-count-badge {
    position: absolute;
    top: -35px;
    left: 70px;
    background-color: $color_primary;
    color: $color_white;
    font-weight: 700;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
  }

  .notes-box {
    box-sizing: border-box;
    background: $color_white;
    right: 0;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    max-height: 400px;
    border-top: $border_grey_light_thin;
    box-shadow: $bs_large;
    overflow: hidden;
    transition: $transition_default;

    .scroll {
      overflow-y: auto;
      overflow-x: hidden;
      max-height: 360px;
    }

    .note-excerpt {
      border-bottom: $border_grey_light_thin;
      padding: 10px 15px;
      cursor: pointer;
      transition: $transition_default;
      color: $color_grey_lighter;
      position: relative;
      font-size: 14px;
      line-height: 22px;

      &:nth-child(2n) {
        background: $color_grey_secondary_menu;
      }

      &:last-of-type {
        border-bottom: 0;

        .delete-column {
          border-bottom: 0;
        }
      }

      &:hover {
        background: $color_grey_site_background;
        transform: translateX(35px);

        h4 {
          color: $color_primary;
        }

        &.is-deleting {
          cursor: not-allowed;
        }
      }

      h4 {
        font-size: 21px;
        margin: 0 0 6px;
        transition: $transition_default;
      }

      p {
        margin: 0;

        &.author-date {
          font-size: 12px;
          margin: 0 0 6px;

          span {
            font-weight: bold;
          }
        }
      }

      .delete-column {
        position: absolute;
        height: 100%;
        top: 0;
        left: -35px;
        border-right: $border_grey_light_thin;
        border-bottom: $border_grey_light_thin;
        background: $color_grey_site_background;

        .center {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .delete-note {
          padding: 10px;
        }
      }
    }

    .add-note {
      margin: 0;
      border: 0;
      width: 100%;
      height: 40px;
      background: $color_grey_secondary_menu;
      color: $color_grey_lighter;
      font-size: 11px;
      text-transform: uppercase;
      font-weight: bold;
      letter-spacing: 1px;
      transition: $transition_default;
      border-top: $border_grey_light_thin;

      &:hover {
        background: $color_grey_site_background;
      }

      &:focus {
        outline: none;
        box-shadow: none;
      }

      &:disabled {
        cursor: not-allowed;
        background: $color_white;
        opacity: 0.5;
      }

      &.no-border-top {
        border-top: 0;
      }
    }

    &.single-view {
      height: 400px;
    }
  }
}
