@import "scssShared/variables.scss";

.detail-event {
  .detail-event-header {
    position: sticky;
    top: 0;
    justify-content: space-between;
    z-index: 1;

    .header-buttons {
      .button {
        margin-left: 10px;
      }
    }

    .disabled-button-tooltip {
      font-size: 12px;
      font-weight: normal;
      padding: 10px;
    }
  }

  .detail-event-content {
    padding: 0;
  }
}
