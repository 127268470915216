@import "scssShared/variables.scss";

.info-tooltip {
  font-size: 11px;
  .tooltip {
    padding: 10px;
    font-weight: normal;
    font-size: 13px;
    line-height: 15px;
    text-transform: none;
    color: white;
    letter-spacing: normal;
    max-width: 350px;
    pointer-events: auto !important;

    &:hover {
      visibility: visible !important;
      opacity: 1 !important;
    }

    a {
      font-size: 13px;
      text-decoration: underline;
      color: $color_white;
      text-transform: none;

      &:hover {
        color: $color_white;
        text-decoration: none;
      }
    }
  }
}
