@import "scssShared/variables.scss";
@import "scssShared/flexbox.scss";

.event-box {
  font-size: 16px;
  font-weight: bold;
  padding: 15px 15px 0 15px;
  margin-left: 74px;
  margin-top: 18px;
  width: calc(100% - 94px);
  color: $color_grey_lighter;
  position: relative;

  &.bottom-pad {
    padding: 15px;
  }

  .event-source-icon {
    box-sizing: border-box;
    width: 26px;
    height: 26px;
    position: absolute;
    padding: 2px;
    top: -8px;
    left: -8px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      max-width: 18px;
      max-height: 18px;
    }
  }

  .event-content {
    &.collapse {
      max-height: 110px;
      overflow: hidden;
      position: relative;
    }
  }

  .header {
    margin-bottom: 10px;

    &:only-child {
      margin-bottom: 0;
    }

    &:after {
      content: "";
      display: block;
      clear: both;
    }
  }

  @each $name, $color in $colors {
    &.#{$name} {
      border-top: 3px solid $color;

      .event-source-icon {
        background: $color;
      }
    }
  }

  p {
    font-size: 13px;
    margin: 0 0 5px;
    font-weight: 500;

    &.datetime {
      text-align: right;
      font-weight: normal;
      margin: 0;
      width: 170px;
      float: right;
      font-size: 16px;
      font-size: 13px;
      font-weight: 500;
      color: $color_border;
    }
  }

  h4 {
    font-size: 16px;
    margin: 0;
    width: calc(100% - 170px);
    float: left;
    color: $color_black;
    word-break: break-word;
  }

  h5 {
    font-size: 13px;
    font-weight: 500;
    margin: 10px 0 5px;
  }

  ul {
    margin: 0;
    padding: 0 0 0 16px;

    li {
      font-size: 13px;
      font-weight: 400;
    }
  }

  .payload {
    .payload-content {
      .entry {
        margin-bottom: 5px;
        width: 100%;
        word-break: break-word;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .payload-overlay {
      width: 100%;
      position: absolute;
      bottom: 0;
      pointer-events: none;

      &.collapse {
        background: linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0) 30%,
          rgba(255, 255, 255, 1) 100%
        );
      }

      &.big {
        height: 60%;
      }

      &.small {
        height: 30%;
      }
    }
  }

  .show-more-less-btn {
    @include flexAcross();
    justify-content: center;
    margin-bottom: 5px;

    &.expanded {
      margin-top: 5px;
    }

    .button {
      height: 20px;
    }
  }
}
