@import "scssShared/variables.scss";

.nameFieldRow {
  margin-bottom: 15px;
}

.fileFieldDescription {
  color: $color_grey_lighter;
  font-size: 12px;
  line-height: 15px;
  margin: 5px 0 0;
}

.modalButtons {
  display: flex;
  justify-content: space-between;
  margin-top: 25px;

  .cancelButton {
    margin-left: -12px;
  }
}

.fileField {
  width: 360px;
}
