@import "scssShared/variables.scss";

.loading-indicator {
  width: 100%;
  height: 28px;
  position: relative;
  cursor: wait;
  padding: 5px 0;

  &:before {
    width: 24px;
    height: 24px;
    margin: -14px 0 0 -14px;
    position: absolute;
    top: 50%;
    left: 50%;
    content: "";
    border-radius: 50%;
    background-clip: padding-box;
    border-color: rgba($color_primary, 0.25);
    border-width: 2px;
    border-style: solid;
    border-top-color: $color_primary;
    animation: animation-rotate 975ms cubic-bezier(0.25, 0.35, 0.5, 0.95) infinite;
  }
}
