@import "scssShared/variables.scss";
@import "scssShared/mixins.scss";

.dropCsvFile {
  .label {
    @include input-label-styles;
  }

  .droppableContent {
    padding: 15px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .folderImg {
      width: 96px;
      height: auto;
      margin-right: 20px;
    }
  }
}
