@import "scssShared/variables.scss";

.galleryItem {
  box-sizing: border-box;

  border: $border_grey_light_thin;
  border-radius: $br_large;
  cursor: pointer;
  position: relative;

  .checkIcon {
    color: $color_white;
    border: 1px $color_white solid;
    position: absolute;
    top: 0;
    right: 0;
    margin: 4px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    text-align: center;
    background-color: $color_primary;
    padding: 4px;
  }

  .previewText {
    padding: 10px;

    .name {
      font-size: 14px;
      margin: 0 0 5px;
      color: $color_grey_lighter;
      letter-spacing: -0.015em;
    }

    .description {
      font-style: italic;
      font-size: 12px;
      line-height: 135%;
      color: $color_grey_lighter;
      margin: 0;
    }

    @media (max-height: 800px) {
      & {
        .name {
          font-size: 13px;
        }

        .description {
          font-size: 11px;
        }
      }
    }

    @media (max-height: 750px) {
      & {
        .name {
          font-size: 12px;
        }

        .description {
          font-size: 10px;
        }
      }
    }

    @media (max-height: 700px) {
      & {
        .name {
          font-size: 11px;
        }

        .description {
          font-size: 9px;
        }
      }
    }

    @media (max-height: 650px) {
      & {
        padding: 7px;

        .name {
          font-size: 10px;
          margin-bottom: 3px;
        }

        .description {
          font-size: 8px;
        }
      }
    }
  }

  .previewImageWrapper {
    width: 100%;
    border-top-left-radius: $br_large;
    border-top-right-radius: $br_large;
    border-bottom: $border_grey_light_thin;
    background-color: $color_grey_fa;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 100%;
      max-height: 100%;
      border-top-left-radius: $br_large;
      border-top-right-radius: $br_large;
    }
  }

  &:hover {
    border: $border_grey;
  }

  &.isSelected {
    border: 1px $color_primary solid;
    outline: 2px $color_primary solid;

    .name {
      color: $color_primary;
    }
  }
}
